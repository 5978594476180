import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'

function MenuLayout() {
  return (
    <>
    <div style={{width:"35%"}}>
    <div className='my-radio-content-starts-here'>
        <div className='setting-45'>
        <MyRadioMain RadioLink1={"/radio/menu"} RadioLink2={"/radio/menu/design"}/>
        <Outlet />
    </div>
    </div>
    </div>
    </>
  )
}

export default MenuLayout