import { createContext,useState } from 'react'

export const HtmlTextContext = createContext();


export const HtmlTextProvider = ({children}) =>{

    const [textValue,setTextValue] = useState("Hello world......");
    return(
        <HtmlTextContext.Provider value={{textValue,setTextValue}}>
            {children}
        </HtmlTextContext.Provider>
    )
}