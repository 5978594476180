import React, { useContext } from 'react'; 
import { FaPlay } from "react-icons/fa"; 
import { BiSolidDislike } from "react-icons/bi"; 
import { BiSolidLike } from "react-icons/bi"; 
import { MdEmail } from "react-icons/md"; 
import { FaShareAlt } from "react-icons/fa"; 
import { GiNightSleep } from "react-icons/gi"; 
import BgImage from '../images/phone-image.png'
import { AiOutlineLike } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import { CiPlay1 } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { BiShareAlt } from "react-icons/bi";
import { BsMoonStars } from "react-icons/bs";
import { MyMobileContext } from './Context/MyMobileContext';

function MyRadioPhoneScreen() {
    const { allOptions,previews,MyBorderRef,MyLikeRef,myRadioBorderColor,myRadioBackgroundColor,myRadioTextColor,myBorderRadious,designChange,myGeneralBTNColor,BTNType,bodySize,textAlignSetting,textAndfont,dark} = useContext(MyMobileContext); 
    
    const isborder = designChange.showCoverBorder;
    
    return ( 
    <div className='My-radio-main-phone-div'> 
    <div id='My-radio-phone-side-display-bg' ref={MyBorderRef} style={previews?{width:bodySize=="with margin"?"85%":"90%",margin:bodySize=="with margin"?"10px 6%":"10px 3%" ,backgroundImage:`URL(${previews})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center",borderRadius:`${myBorderRadious}`,border:isborder?`5px solid ${myRadioBorderColor}`:"none"}:{width:bodySize=="with margin"?"85%":"90%",margin:bodySize=="with margin"?"10px 6%":"10px 3%" , backgroundImage: `URL(${BgImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center",borderRadius:`${myBorderRadious}`,border:isborder?`5px solid ${myRadioBorderColor}`:"none" }} > 
        <div className='My-radio-phone-side-display-play-button' style={BTNType?{backgroundColor:`${myGeneralBTNColor}`}:{color:`${myGeneralBTNColor}`,backgroundColor:"#fff"}}>{dark?<CiPlay1 style={{fontSize:"22px"}} />:<FaPlay />}</div> </div> 
        <div id="My-radio-Other-like-dislike-buttons" ref={MyLikeRef} style={{padding:bodySize=="with margin"?"0px 25px":"0px",backgroundColor:myRadioBackgroundColor,color:"black"}}> 
            <div style={{width:"80%"}}>
            <h3 style={{fontSize:`${textAndfont}`,fontWeight:"600",lineHeight:"32px",textAlign:"center",marginBottom:"5px",color:myRadioTextColor}}>Open Season... Playing</h3>
            <p style={{fontSize:`${textAndfont}`,fontWeight:"500",lineHeight:"27px",marginBottom:"5px",textAlign:"center",color:myRadioTextColor}}>Jason Salvat</p>
            </div>
            <div style={{display:"flex",width:"71%",display:"flex",justifyContent:"space-around"}}>
            {allOptions.VoteOption && (dark?<AiOutlineLike style={{color:myRadioTextColor}} id="like-763"/>:<BiSolidLike style={{color:myRadioTextColor}} id="like-763" />)}
            {allOptions.VoteOption && (dark? <BiDislike style={{color:myRadioTextColor}} id="dislike-436" />:<BiSolidDislike style={{color:myRadioTextColor}} id="dislike-436" />)}
            </div>
            </div> 
            <div className='my-radio-other-share-mail-etc-buttons'> {(allOptions.CallOption || allOptions.EmailOption || allOptions.SMSOption)  && (dark?<MdOutlineEmail />:<MdEmail />)}  
            {allOptions.ShareOption && (dark?<BiShareAlt />:<FaShareAlt />)} {allOptions.SleepOption && (dark?<BsMoonStars />:<GiNightSleep style={{ backgroundColor: "#cac7c770", borderRadius: "50%", padding: "2px" }} />)} 
            </div> 
            </div> );
}

export default MyRadioPhoneScreen