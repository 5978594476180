import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function AudioShoutOutDesign() {
  let { setMobileValue,myAudioShoutOutDesignColorId,setMyAudioShoutOutDesignColorId,myAudioShoutOutDesignColor,setMyAudioShoutOutDesignColor,displayForSinglePhoneScreen } = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="iuy5o8")                
            setMobileValue(value.text)
             },[])
  const textColors = [
    { id: "5g8q3ay7j8i912", classIs: "text-colors-yellow", color: "#ff9150" },
    { id: "5g8q3ay7j8i923", classIs: "text-colors-green", color: "#ff4757" },
    { id: "5g8q3ay7j8i945", classIs: "text-colors-lightgreen", color: "#2ed573" },
    { id: "5g8q3ay7j8i934", classIs: "text-colors-skyblue", color: "#1e90ff" },
    { id: "5g8q3ay7j8i956", classIs: "text-colors-lightblue", color: "#ffc0cb" },
    // { classIs: "text-colors-blue", color: "#504DCE" },
    { id: "5g8q3ay7j8i967", classIs: "text-colors-pink", color: "#CB4DCE" },
    { id: "5g8q3ay7j8i978", classIs: "text-colors-black", color: "#000000" },
    // { id: "5g8q3ay7j8i989", classIs: "text-colors-white", color: "none" },
  ]

  const myAudioShoutOutButtonColor = (id, color) => {

    setMyAudioShoutOutDesignColorId(id);
    setMyAudioShoutOutDesignColor(color);
  }
  return (
    <>
      <div className='text-clr'>
        <p style={{ color: "#fff" }}>Text Color</p>
        <div className='text-colors'>
          <div className='text-hexa-colors'>
            <input className={"iuewg779963892jdhu" === myAudioShoutOutDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myAudioShoutOutButtonColor("rt547ru785rt3454", (e.target.value)); setMyAudioShoutOutDesignColorId("iuewg779963892jdhu"); }} />

            {textColors.map((item) => (<div key={item.id} className={item.id === myAudioShoutOutDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myAudioShoutOutButtonColor(item.id, item.color) }}></div>))}
          </div>
          <div className='Hexa-text'>
            <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myAudioShoutOutDesignColor} onChange={(e) => { setMyAudioShoutOutDesignColor(`#${e.target.value.replace('#', '')}`); setMyAudioShoutOutDesignColorId("iuewg779963892jdhu"); }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AudioShoutOutDesign