import React, { useContext, useState, useEffect } from 'react'
import BgImage from '../images/phone-image.png'
import MockIphone1 from '../images/MockIphone1.png'
import MockIphone2 from '../images/MockIphone2.png'
import fontAlignRightImg from '../images/format_align_right.png'
import fontAlignLeftImg from '../images/format_align_left.png'
import fontAlignJustifyImg from '../images/format_align_justify.png'
import fontAlignCenterImg from '../images/format_align_center.png'
import libraryMusic from '../images/library_music.png'
import playIcon from '../images/play icon.png'
import { FileUploader } from "react-drag-drop-files";
import { BsArrowDownUp } from "react-icons/bs";
import { FaArrowDown } from "react-icons/fa";
import { FiArrowDownRight } from "react-icons/fi";
import { FaArrowRight } from "react-icons/fa";
import { FiArrowDownLeft } from "react-icons/fi";
import { MyMobileContext } from './Context/MyMobileContext'
import Rectangle60 from '../images/Rectangle60.png'
import Rectangle61 from '../images/Rectangle61.png'
// import vector1 from '../images/vector1.png'
// import vector2 from '../images/vector2.png'
// import vector3 from '../images/vector3.png'
// import vector4 from '../images/vector4.png'
import Vector1 from '../images/Vector1.png'
import Vector2 from '../images/Vector2.png'
import Vector3 from '../images/Vector3.png'
import Vector4 from '../images/Vector4.png'
import { AiOutlineStop } from "react-icons/ai";
import rectangle from '../images/smallRectangle.png'



function GeneralSettings() {

    const { GeneralTextRef, MyBGColorChgRef, previewss, setPreviewss, dark, setDark, myTextColor, setMytextColor, myLanguage, setMyLanguage, MyBGColor, setMyBGColor, MyBGGradientColor, setMyBGGradientColor, selectedColorTextId, setSelectedColorTextId, bgOpacity, setBGOpacity, bgGradientOpacity, setBGgradientOpacity, BGGradientColorId, setBgGradientColorId, BGColorId, setBgColorId, value, setValue, blur, setBlur, blurCover, setBlurCover, font, setFont, backgroundType, setBackgroundType, BGColorTo, setBGColorTo, BGColorDirection, setBGColorDirection, BGBIColorDirection, setBGBIColorDirection, textAndfont, setTextAndFont, buttonActive, setButtonActive, ShadowActive, setShadowActive,textAlignSetting, setTextAlignSetting,MyGeneralButtonColorId, setMyGeneralButtonColorId,myGeneralBTNColor,setMyGeneralBTNColor,myGeneralButtonOpacity,setMyGeneralButtonOpacity,BTNType,setBTNType,bodySize,setBodySize } = useContext(MyMobileContext)


    

    const MyContent = (<><div className='phone-screen-player'>
        <div><img src={libraryMusic} alt="Library-music-icon" /></div>
        <div className='phone-screen-player-button'><img src={playIcon} alt="music-play-icon" /></div>
    </div>
        <div className='phone-screen-last-div'></div></>)

    const handleChange = (e) => {
        setValue(e.target.value);
    }

    const yourHex = (hex, opacity) => { 
        const expandedHex = hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex; 
        const hasAlpha = expandedHex.length === 9; 
         const bigint = parseInt(expandedHex.slice(1), 16); 
         const r = hasAlpha ? (bigint >> 24) & 255 : (bigint >> 16) & 255; 
         const g = hasAlpha ? (bigint >> 16) & 255 : (bigint >> 8) & 255; 
         const b = hasAlpha ? (bigint >> 8) & 255 : bigint & 255; 
         const a = hasAlpha ? (bigint & 255) / 255 : 1; 
          const finalAlpha = Math.round((opacity / 100) * 255); 
           const toHex = (value) => { 
               const hex = value.toString(16); 
               return hex.length === 1 ? '0' + hex : hex; }; 
               return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(finalAlpha)}`.toUpperCase(); 
           };

const myGeneralsettingButtonColor = (id,color) =>{
        
    setMyGeneralButtonColorId(id);
    const rgbaColor = yourHex(color, myGeneralButtonOpacity); 
    console.log(rgbaColor);
    
    setMyGeneralBTNColor(rgbaColor);
      }

    const myTextColorss = [
        { id: "fdht6236eur133", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "fdht6236eur122", classIs: "text-colors-green", color: "#ff4757" },
        { id: "fdht6236eur111", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "fdht6236eur155", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "fdht6236eur166", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "fdht6236eur177", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "fdht6236eur188", classIs: "text-colors-black", color: "#000000" },
        // { id: "fdht6236eur199", classIs: "text-colors-white", color: "none" },
    ]

    const bgColorss = [
        { id: "vsutf7y6wegwi67t7e231", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "vsutf7y6wegwi67t7e54", classIs: "text-colors-green", color: "#ff4757" },
        { id: "vsutf7y6wegwi67t7e76", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "vsutf7y6wegwi67t7e87", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "vsutf7y6wegwi67t7e90", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "vsutf7y6wegwi67t7e78", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "vsutf7y6wegwi67t7e879", classIs: "text-colors-black", color: "#000000" },
        // { id: "vsutf7y6wegwi67t7e564", classIs: "text-colors-white", color: "none" },
    ]
    const bgColorss2 = [
        { id: "vuyewit63477348934", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "uyewit634773489dffh", classIs: "text-colors-green", color: "#ff4757" },
        { id: "uyewit634773489rhr6", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "uyewit63477348965", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "uyewit634773489865", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "uyewit634773489yut", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "vuyewit63477348978uy", classIs: "text-colors-black", color: "#000000" },
        // { id: "vuyewit634773489yt8", classIs: "text-colors-white", color: "none" },
    ]
    const ButtonColor = [
        { id: "vgjyqwtun67671", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "uygjyqwtun67672", classIs: "text-colors-green", color: "#ff4757" },
        { id: "uygjyqwtun67673", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "gjyqwtun67674", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "ugjyqwtun67675", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "ugjyqwtun676766", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "vuygjyqwtun67677", classIs: "text-colors-black", color: "#000000" },
        // { id: "vugjyqwtun67678", classIs: "text-colors-white", color: "none" },
    ]

    const textColors = [
        { classIs: "text-colors-white", color: "white" },
        { classIs: "text-colors-red", color: "#EF5050" },
        { classIs: "text-colors-yellow", color: "#FFB257" },
        { classIs: "text-colors-green", color: "#A9B42A" },
        { classIs: "text-colors-lightgreen", color: "#4DCE71" },
        { classIs: "text-colors-skyblue", color: "#4DCEC6" },
        { classIs: "text-colors-lightblue", color: "#4D98CE" },
        { classIs: "text-colors-blue", color: "#504DCE" },
        { classIs: "text-colors-pink", color: "#CB4DCE" },
        { classIs: "text-colors-black", color: "black" },
    ]
    const hexToRgba = (hex, opacity) => {
        const expandedHex = hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex; const bigint = parseInt(expandedHex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `rgba(${r},${g},${b},${opacity / 100})`;
    };

    const [urls, setUrls] = useState();
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [file, setFile] = useState(null);
    const handleImageChange = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewss(reader.result);
        }; if (file) { reader.readAsDataURL(file); }

    };

    useEffect(() => {
        if (MyBGColorChgRef) {
            MyBGColorChgRef.current.lang = myLanguage;
        }
    }, [myLanguage])

    useEffect(() => {
        if (value == 0) {
            // MyBGColorChgRef.current.style.fontSize = "14px"
            setTextAndFont("14px")
        } else if (value == 20) {
            // MyBGColorChgRef.current.style.fontSize = "15.2206px"
            setTextAndFont("15.2206px")
        } else if (value == 40) {
            // MyBGColorChgRef.current.style.fontSize = "18.2647px"
            setTextAndFont("18.2647px")
        } else if (value == 60) {
            // MyBGColorChgRef.current.style.fontSize = "20.2941px"
            setTextAndFont("20.2941px")
        } else if (value == 80) {
            // MyBGColorChgRef.current.style.fontSize = "22.3235px"
            setTextAndFont("22.3235px")
        } else if (value == 100) {
            // MyBGColorChgRef.current.style.fontSize = "24.3529px"
            setTextAndFont("24.3529px")
        }
    }, [value])

    const handleFont = (event) => {
        setFont(event.target.value);
        MyBGColorChgRef.current.style.fontFamily = font;
        console.log('Selected font:', event.target.value);
    }


    useEffect(() => {
        if (GeneralTextRef.current) {
            const rgbaColor = hexToRgba(myTextColor, "100");
            GeneralTextRef.current.style.color = rgbaColor;
        }
    }, [myTextColor]);

    useEffect(() => {
        if (backgroundType === "color") {
            if (BGColorTo === "gradient") {
                const rgbaColor1 = hexToRgba(MyBGColor, bgOpacity);
                const rgbaColor2 = hexToRgba(MyBGGradientColor, bgGradientOpacity);
                if (rgbaColor1 && rgbaColor1) {
                    MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${rgbaColor2} )`;
                } else if (!rgbaColor2) {
                    MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${MyBGGradientColor} )`;
                }
                MyBGColorChgRef.current.style.background = `linear-gradient( ${MyBGColor},${MyBGGradientColor} )`;

                if (MyBGColorChgRef.current) {
                    
                    
                    // const rgbaColor1 = hexToRgba(MyBGColor, bgOpacity);
                    // const rgbaColor2 = hexToRgba(MyBGGradientColor, bgGradientOpacity);
                    // if(!rgbaColor1&&!rgbaColor1){
                    //     MyBGColorChgRef.current.style.background = `linear-gradient( ${MyBGColor},${MyBGGradientColor} )`;
                    // }else if(!rgbaColor2){
                    //     MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${MyBGGradientColor} )`;
                    // }
                    // MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${rgbaColor2} )`;
                    if (BGColorDirection == "down") {
                        // console.log(BGColorDirection, "down");
                        MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1}, ${rgbaColor2})`;
                    } else if (BGColorDirection == "downRight") {
                        // console.log(BGColorDirection, "downRight");
                        MyBGColorChgRef.current.style.background = `linear-gradient(2.03384rad, ${rgbaColor1}, ${rgbaColor2})`;
                    } else if (BGColorDirection == "right") {
                        // console.log(BGColorDirection);
                        MyBGColorChgRef.current.style.background = `linear-gradient(1.5708rad, ${rgbaColor1}, ${rgbaColor2})`;
                    } else if (BGColorDirection == "downLeft") {
                        // console.log(BGColorDirection);
                        MyBGColorChgRef.current.style.background = `linear-gradient(4.24934rad, ${rgbaColor1}, ${rgbaColor2})`;
                    }


                }
            } else if (BGColorTo === "bicolor") {
                const rgbaColor1 = hexToRgba(MyBGColor, bgOpacity);
                const rgbaColor2 = hexToRgba(MyBGGradientColor, bgGradientOpacity);
                if (rgbaColor1 && rgbaColor1) {
                    MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${rgbaColor2} )`;
                } else if (!rgbaColor2) {
                    MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${MyBGGradientColor} )`;
                }
                MyBGColorChgRef.current.style.background = `linear-gradient( ${MyBGColor},${MyBGGradientColor} )`;
                if (MyBGColorChgRef.current) {
                    if (BGBIColorDirection == "down") {
                        // console.log(BGBIColorDirection, "down");
                        MyBGColorChgRef.current.style.background = `linear-gradient( ${rgbaColor1},${rgbaColor1} 50%,${rgbaColor2} 50%,${rgbaColor2} 100% )`;
                    } else if (BGBIColorDirection == "downRight") {
                        // console.log(BGBIColorDirection, "downRight");
                        MyBGColorChgRef.current.style.background = `linear-gradient(2.03384rad, ${rgbaColor1},${rgbaColor1} 50%,${rgbaColor2} 50%,${rgbaColor2} 100% )`;
                    } else if (BGBIColorDirection == "right") {
                        // console.log(BGBIColorDirection);
                        MyBGColorChgRef.current.style.background = `linear-gradient(1.5708rad, ${rgbaColor1},${rgbaColor1} 50%,${rgbaColor2} 50%,${rgbaColor2} 100% )`;
                    } else if (BGBIColorDirection == "downLeft") {
                        // console.log(BGBIColorDirection);
                        MyBGColorChgRef.current.style.background = `linear-gradient(4.24934rad, ${rgbaColor1},${rgbaColor1} 50%,${rgbaColor2} 50%,${rgbaColor2} 100% )`;
                    }
                }
            }

            else if (BGColorTo == "solid") {
                if (MyBGColorChgRef.current) {
                    const rgbaColor = hexToRgba(MyBGColor, bgOpacity);
                    MyBGColorChgRef.current.style.background = rgbaColor;
                }
            }
        } else if (backgroundType === "image") {
            if (previewss) {
                MyBGColorChgRef.current.style.backgroundImage = `url(${previewss})`
                if (blur) {
                    // MyBGColorChgRef.current.style.filter = "blur(20px)"
                    let innerDiv = MyBGColorChgRef.current.children[0];
                    // console.log(innerDiv);
                    innerDiv.style.backdropFilter = "blur(5px)"
                    innerDiv.style.borderRadius = "20px"
                } else if (!blur) {
                    let innerDiv = MyBGColorChgRef.current.children[0];
                    // console.log(innerDiv);
                    innerDiv.style.backdropFilter = "blur(0px)"
                    innerDiv.style.borderRadius = "20px"
                }
            }
        } else if (backgroundType === "cover") {
            MyBGColorChgRef.current.style.backgroundImage = `url(${BgImage})`
            if (blurCover) {
                // MyBGColorChgRef.current.style.filter = "blur(20px)"                    
                let innerDiv = MyBGColorChgRef.current.children[0];
                // console.log(innerDiv);
                innerDiv.style.backdropFilter = "blur(5px)"
                innerDiv.style.borderRadius = "20px"
            } else if (!blurCover) {
                let innerDiv = MyBGColorChgRef.current.children[0];
                // console.log(innerDiv);
                innerDiv.style.backdropFilter = "none"
                innerDiv.style.borderRadius = "20px"
            }
        }

    }, [bgOpacity, MyBGColor, bgGradientOpacity, MyBGGradientColor, BGBIColorDirection, blur, blurCover, previewss, backgroundType, BGColorTo,BGColorDirection]);

    const changeTextColor = (id, color) => {
        setMytextColor(color);
        setSelectedColorTextId(id);
    }
    const myBGColors = (id, color) => {
        setMyBGColor(color);
        setBgColorId(id);
    }
    const myBGGradientColors = (id, color) => {
        setMyBGGradientColor(color);
        setBgGradientColorId(id);
    }


    return (
        <>
            <div style={{ width: "35%" }}>
                <div className='setting'>
                    <div className='setting-45'>
                        <div className='Apps'>
                            <p>App Language</p>
                            <div className='App-language'>
                                <select name="allLanguages" value={myLanguage} onChange={(e) => {
                                    setMyLanguage(e.target.value);
                                }}>
                                    <option value="af">Afrikaans</option>
                                    <option value="sq">Albanian</option>
                                    <option value="ar">Arabic</option>
                                    <option value="hy">Armenian</option>
                                    <option value="bn">Bengali</option>
                                    <option value="bg">Bulgarian</option>
                                    <option value="ca">Catalan</option>
                                    <option value="zh-CN">Chinese (Simplified)</option>
                                    <option value="zh-TW">Chinese (Traditional)</option>
                                    <option value="hr">Croatian</option>
                                    <option value="cs">Czech</option>
                                    <option value="da">Danish</option>
                                    <option value="nl">Dutch</option>
                                    <option value="en">English</option>
                                    <option value="et">Estonian</option>
                                    <option value="fi">Finnish</option>
                                    <option value="fr">French</option>
                                    <option value="ka">Georgian</option>
                                    <option value="de">German</option>
                                    <option value="el">Greek</option>
                                    <option value="he">Hebrew</option>
                                    <option value="hi">Hindi</option>
                                    <option value="hu">Hungarian</option>
                                    <option value="is">Icelandic</option>
                                    <option value="id">Indonesian</option>
                                    <option value="ga">Irish</option>
                                    <option value="it">Italian</option>
                                    <option value="ja">Japanese</option>
                                    <option value="ko">Korean</option>
                                    <option value="lv">Latvian</option>
                                </select>
                            </div>
                        </div>
                        <div className='header'>
                            <p>Header</p>
                            <div className='headers'>
                                <div className='text-clr'>
                                    <p>Text Color</p>
                                    <div className='text-colors'>
                                        <div className='text-hexa-colors'>
                                            <input className={"jdqi89669967" === selectedColorTextId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { changeTextColor("jdqi89669967", (e.target.value)); setSelectedColorTextId("jdqi89669967"); }} />

                                            {myTextColorss.map((item) => (<div key={item.id} className={item.id === selectedColorTextId ? "border-for-active" : item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { changeTextColor(item.id, item.color) }} ></div>))}
                                        </div>
                                        <div className='Hexa-text'>
                                            <label htmlFor="Hexa-text-here">Hex</label>
                                            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myTextColor} onChange={(e) => { setMytextColor(e.target.value); setSelectedColorTextId("jdqi89669967") }} />
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className='bg-color-img-text'>
                                    <div>
                                        <p>Background</p>
                                        <div className='bg-buttons-3types'>
                                            <button className={backgroundType === "cover" ? "activeBGButton" : "NotActiveBGButton"} onClick={() => setBackgroundType("cover")}>Cover</button>
                                            <button className={backgroundType === "image" ? "activeBGButton" : "NotActiveBGButton"} onClick={() => setBackgroundType("image")}>Image</button>
                                            <button className={backgroundType === "color" ? "activeBGButton" : "NotActiveBGButton"} onClick={() => setBackgroundType("color")}>Color</button>
                                        </div>
                                    </div>
                                    {backgroundType === "color" && <div className='bg-styles'>
                                        <div className='bg-styles24'>
                                            <p>Style</p>
                                            <div className='text-hexa-colors'>
                                                <div className={BGColorTo === "solid" ? "border-for-active" : "text-colors-white"} style={{ background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGColorTo("solid") }}></div>
                                                <div className={BGColorTo === "gradient" ? "border-for-active" : "text-colors-white"} style={{ background: "linear-gradient(to bottom, #d26e6eff, #715f83)", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGColorTo("gradient") }}></div>
                                                <div className={BGColorTo === "bicolor" ? "border-for-active" : "text-colors-white"} style={{ backgroundImage: "linear-gradient(to bottom right,rgba(255,145,80,.5019607843) 50%,red 50%)", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGColorTo("bicolor") }}></div>
                                            </div>
                                        </div>
                                        {BGColorTo === "solid" && <div>
                                            <div className='bg-opacity25'>
                                                <label htmlFor="opacitys">Opacity</label>
                                                <input type="number" id="My-Radio-Border-color-opacitys" value={bgOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e) => { setBGOpacity(e.target.value) }} />

                                                <label htmlFor="Hexa-code">Hex</label>
                                                <input type="text" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={MyBGColor} onChange={(e) => setMyBGColor(`#${e.target.value.replace('#', '')}`)} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />
                                            </div>
                                            <div className='text-hexa-colors'>
                                                <input className={"vsdhgf623578hfru7i" === BGColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBGColors("vsdhgf623578hfru7i", (e.target.value)); setBgColorId("vsdhgf623578hfru7i"); }} />

                                                {bgColorss.map((item) => (<div key={item.id} className={item.id === BGColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBGColors(item.id, item.color) }}></div>))}
                                            </div>
                                        </div>}
                                        {BGColorTo === "gradient" && <div>
                                            <div>
                                                <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Color 1</p>
                                                <div className='bg-opacity25'>
                                                    <label htmlFor="opacitys">Opacity</label>
                                                    <input type="number" id="My-Radio-Border-color-opacitys" value={bgOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e) => { setBGOpacity(e.target.value) }} />

                                                    <label htmlFor="Hexa-code">Hex</label>
                                                    <input type="text" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={MyBGColor} onChange={(e) => setMyBGColor(`#${e.target.value.replace('#', '')}`)} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />
                                                </div>
                                                <div className='text-hexa-colors'>
                                                    <input className={"vsdhgf623578hfru7i" === BGColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBGColors("vsdhgf623578hfru7i", (e.target.value)); setBgColorId("vsdhgf623578hfru7i"); }} />

                                                    {bgColorss.map((item) => (<div key={item.id} className={item.id === BGColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBGColors(item.id, item.color) }}></div>))}
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <hr style={{ width: "100%", margin: "0px" }} />
                                                {/* <BsArrowDownUp onClick={() => handleSwap} style={{ fontSize: "55px", padding: "0px 20px" }} /> <hr style={{ width: "100%" }} /> */}
                                            </div>
                                            <div>
                                                <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Color 2</p>
                                                <div className='bg-opacity25'>
                                                    <label htmlFor="opacitys">Opacity</label>
                                                    <input type="number" id="My-Radio-Border-color-opacitys" value={bgGradientOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e) => { setBGgradientOpacity(e.target.value) }} />

                                                    <label htmlFor="Hexa-code">Hex</label>
                                                    <input type="text" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={MyBGGradientColor} onChange={(e) => setMyBGGradientColor(`#${e.target.value.replace('#', '')}`)} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />
                                                </div>
                                                <div className='text-hexa-colors'>
                                                    <input className={"uyi7875y9900564fgy" === BGGradientColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBGGradientColors("uyi7875y9900564fgy", (e.target.value)); setBgGradientColorId("uyi7875y9900564fgy"); }} />

                                                    {bgColorss2.map((item) => (<div key={item.id} className={item.id === BGGradientColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBGGradientColors(item.id, item.color) }}></div>))}
                                                </div>
                                            </div>
                                            <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Direction</p>
                                            <div style={{ display: "flex" }}>
                                                <div className={BGColorDirection === "down" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => setBGColorDirection("down")}><FaArrowDown /></div>
                                                <div className={BGColorDirection === "downRight" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => setBGColorDirection("downRight")}><FiArrowDownRight /></div>
                                                <div className={BGColorDirection === "right" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => setBGColorDirection("right")}><FaArrowRight /></div>
                                                <div className={BGColorDirection === "downLeft" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => setBGColorDirection("downLeft")}><FiArrowDownLeft /></div>
                                            </div>
                                        </div>}
                                        {BGColorTo === "bicolor" && <div>
                                            <div>
                                                <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Color 1</p>
                                                <div className='bg-opacity25'>
                                                    <label htmlFor="opacitys">Opacity</label>
                                                    <input type="number" id="My-Radio-Border-color-opacitys" value={bgOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e) => { setBGOpacity(e.target.value) }} />

                                                    <label htmlFor="Hexa-code">Hex</label>
                                                    <input type="text" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={MyBGColor} onChange={(e) => setMyBGColor(`#${e.target.value.replace('#', '')}`)} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />
                                                </div>
                                                <div className='text-hexa-colors'>
                                                    <input className={"vsdhgf623578hfru7i" === BGColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBGColors("vsdhgf623578hfru7i", (e.target.value)); setBgColorId("vsdhgf623578hfru7i"); }} />

                                                    {bgColorss.map((item) => (<div key={item.id} className={item.id === BGColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBGColors(item.id, item.color) }}></div>))}
                                                </div>
                                            </div>
                                            <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <hr style={{ width: "100%", margin: "0px" }} />
                                                {/* <BsArrowDownUp onClick={() => handleSwap2} style={{ fontSize: "55px", padding: "0px 20px" }} /> <hr style={{ width: "100%" }} /> */}
                                            </div>
                                            <div>
                                                <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Color 2</p>
                                                <div className='bg-opacity25'>
                                                    <label htmlFor="opacitys">Opacity</label>
                                                    <input type="number" id="My-Radio-Border-color-opacitys" value={bgGradientOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e) => { setBGgradientOpacity(e.target.value) }} />

                                                    <label htmlFor="Hexa-code">Hex</label>
                                                    <input type="text" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={MyBGGradientColor} onChange={(e) => setMyBGGradientColor(`#${e.target.value.replace('#', '')}`)} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />
                                                </div>
                                                <div className='text-hexa-colors'>
                                                    <input className={"uyi7875y9900564fgy" === BGGradientColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBGGradientColors("uyi7875y9900564fgy", (e.target.value)); setBgGradientColorId("uyi7875y9900564fgy"); }} />

                                                    {bgColorss2.map((item) => (<div key={item.id} className={item.id === BGGradientColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBGGradientColors(item.id, item.color) }}></div>))}
                                                </div>
                                            </div>
                                            <p style={{ fontSize: "16px", lineHeight: "19px", fontWeight: "400" }}>Direction</p>
                                            <div style={{ display: "flex" }}>
                                                <div className={BGBIColorDirection === "down" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGBIColorDirection("down") }}><FaArrowDown /></div>
                                                <div className={BGBIColorDirection === "downRight" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGBIColorDirection("downRight") }}><FiArrowDownRight /></div>
                                                <div className={BGBIColorDirection === "right" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGBIColorDirection("right") }}><FaArrowRight /></div>
                                                <div className={BGBIColorDirection === "downLeft" ? "border-for-active" : "text-colors-white"} style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "23px", background: "#715f83", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { setBGBIColorDirection("downLeft") }}><FiArrowDownLeft /></div>
                                            </div>
                                        </div>}
                                    </div>}

                                    {backgroundType === "image" &&
                                        <div className='bg-styles'><div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label htmlFor="display-default-image">Blur</label>
                                            <label className="switch">
                                                <input type="checkbox" name='display-default-image' id='display-default-image' checked={blur} onChange={(e) => {
                                                    let isChecked = e.target.checked; setBlur(isChecked)
                                                }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                            <p className='bg-style-para-content-size'>The background blur allows you to add a visual effect to your image to highlight the main content.</p>

                                            {!previewss && <FileUploader handleChange={handleImageChange} name="file" types={fileTypes} />}
                                            {/* {previewss && <img src={previewss} alt={urls} style={{ margin: "5px 0px", height: "200px" }} />} */}
                                            {previewss && <div style={{width:"240px",height:"240px"}} className='my-radio-stream-logo-image-visible'><img className='menu-design-menu-image' src={previewss} alt={urls}  style={{width:"inherit",height:"inherit"}} /> <button className='btn-mnu-chg' onClick={()=> setPreviewss(null)
                        }>Change image</button></div>}
                                            <div className='my-radio-design-default-drag-text'>
                                                <p>Recomended size <br /> Max Size</p>
                                                <p>1242 x 2208px <br /> 10Mo</p>
                                            </div>

                                        </div>}

                                    {backgroundType === "cover" && <div className='bg-styles'>
                                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <label htmlFor="display-default-cover">Blur</label>
                                            <label className="switch">
                                                <input type="checkbox" name='display-default-cover' id='display-default-cover' checked={blurCover} onChange={(e) => {
                                                    let isChecked = e.target.checked; setBlurCover(isChecked); console.log("clicked", blurCover);
                                                }} />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <p className='bg-style-para-content-size'>The background blur allows you to add a visual effect to your image to highlight the main content.</p>
                                        <p className='bg-style-para-content-size'>The cover of the current track will be displayed as a background image.</p>
                                    </div>}

                                </div>
                                <hr />
                                <div className='Typography'>
                                    <p>Typography</p>
                                    <select name="font-familyes" id="font-familyes" onChange={handleFont} value={font}>
                                        <option value="Montserrat">Monospace</option>
                                        <option value="cursive">cursive</option>
                                        <option value="sans-serif">sans-serif</option>
                                        <option value="serif">serif</option>
                                    </select>
                                    <label className='label-opacity25-text-size' htmlFor="text-size">Text size</label>
                                    <div className='label-opacity25-text-size-range'>

                                        <span style={{ marginRight: "20px", fontSize: "12px", fontWeight: "500", lineHeight: "14px" }}>A</span>
                                        <input type="range" id="text-size" min="0" max="100" value={value} step="20" name="text-size" onChange={handleChange} />
                                        <span style={{ marginLeft: "20px", fontSize: "30px", fontWeight: "500", lineHeight: "36px" }}>A</span>

                                    </div>
                                </div>
                                <hr />
                                <div className='icons26-text-buttons'>
                                    <p>Icons</p>
                                    <div className="icons26-buttons">
                                        <button className={dark ?"icons26-button" : "activeicons26-button" } onClick={() => setDark(false)} ><img src={Rectangle60} alt="RectangleFilled" /><p>Solid</p></button>
                                        <button className={dark ? "activeicons26-button" : "icons26-button" } onClick={() => setDark(true)}><img src={Rectangle61} alt="RectangleNotFilled" /><p>Regular</p></button>
                                    </div>
                                </div>
                                <hr />
                                <div className='styles23'>
                                    <h2>Styles</h2>
                                    <div className='styles23-border'>
                                        <p>Rounded borders</p>
                                        <button className={buttonActive == "vector1" ? "isActive" : "notActive"} onClick={() => { setButtonActive("vector1"); }}><img src={Vector1} alt="vector1" /></button>
                                        <button className={buttonActive == "vector2" ? "isActive" : "notActive"} onClick={() => { setButtonActive("vector2"); }}><img src={Vector2} alt="vector2" /></button>
                                        <button className={buttonActive == "vector3" ? "isActive" : "notActive"} onClick={() => { setButtonActive("vector3"); }}><img src={Vector3} alt="vector3" /></button>
                                        <button className={buttonActive == "vector4" ? "isActive" : "notActive"} onClick={() => { setButtonActive("vector4"); }}><img src={Vector4} alt="vector4" /></button>
                                    </div>
                                    <div className='styles23-shadow'>
                                        <p>Shadows</p>
                                        <button className={ShadowActive == "rectangleShadow1" ? "isActive" : "notActive"} onClick={() => setShadowActive("rectangleShadow1")}>
                                            <img src={rectangle} alt="Rectangle" style={{boxShadow:"2px 2px 2px #00000026"}} />
                                        </button>
                                        <button className={ShadowActive == "rectangleShadow2" ? "isActive" : "notActive"} onClick={() => setShadowActive("rectangleShadow2")}>
                                            <img src={rectangle} alt="Rectangle" style={{boxShadow:"2px 2px 1px #00000026"}} />
                                        </button>
                                        <button className={ShadowActive == "rectangleShadow3" ? "isActive" : "notActive"} onClick={() => setShadowActive("rectangleShadow3")}>
                                            <img src={rectangle} alt="Rectangle" style={{boxShadow:"1px 0px 2.2px 2px #00000026"}} />

                                        </button>
                                        <button className={ShadowActive == "rectangleShadow4" ? "isActive" : "notActive"} onClick={() => setShadowActive("rectangleShadow4")}>
                                            <img src={rectangle} alt="Rectangle" style={{boxShadow:"1px 0px 5.8px 4px #00000026"}} />

                                        </button>
                                    </div>
                                    <div className='styles23-text-align'>
                                        <p>Text alignment</p>
                                        <button className={textAlignSetting == "left" ? "isActive" : "notActive"} onClick={() => setTextAlignSetting("left")}><div className='Text-aligns-style1'><img src={fontAlignLeftImg} alt="Text-align-images-left" /></div></button>
                                        <button className={textAlignSetting == "center" ? "isActive" : "notActive"} onClick={() => setTextAlignSetting("center")}><div className='Text-aligns-style2'><img src={fontAlignCenterImg} alt="Text-align-images-center" /></div></button>
                                        <button className={textAlignSetting == "right" ? "isActive" : "notActive"} onClick={() => setTextAlignSetting("right")}><div className='Text-aligns-style3'><img src={fontAlignRightImg} alt="Text-align-images-right" /></div></button>
                                        <button className={textAlignSetting == "justify" ? "isActive" : "notActive"} onClick={() => setTextAlignSetting("justify")}><div className='Text-aligns-style4'><img src={fontAlignJustifyImg} alt="Text-align-images-justify" /></div></button>
                                    </div>
                                    <div className='styles23-button-color'>
                                        <p>Button color</p>
                                        <div>
                                            <div className='styles23-button-color-hexa-opacity'>

                                                <label htmlFor="opacitys">Opacity</label>
                                                <input type="number" id="opacitys" name="opacitys" min="10" max="100" value={myGeneralButtonOpacity} onChange={(e)=> {setMyGeneralButtonOpacity(e.target.value);myGeneralsettingButtonColor("nfgwdyhcvjmiuwg5675",myGeneralBTNColor)}}/>

                                                <label htmlFor="Hexa-code">Hex</label>
                                                <input type="text" name="Hexa-code" id="Hexa-code"  maxlength="7" onKeyDown={(e)=> {const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace';if(!validKeys){e.preventDefault();} } } value={myGeneralBTNColor} onChange={(e)=> {setMyGeneralBTNColor(`#${e.target.value.replace('#', '')}`);setMyGeneralButtonColorId("nfgwdyhcvjmiuwg5675");}} />
                                            </div>
                                            <div className='button-color-opacitys-hexa'>

                                            <input className={"nfgwdyhcvjmiuwg5675" === MyGeneralButtonColorId? "border-for-active" :"text-colors-white"} type='color' style={{backgroundColor:"#fff",margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onChange={(e)=>{myGeneralsettingButtonColor("rt547ru785rt3454",(e.target.value));setMyGeneralButtonColorId("nfgwdyhcvjmiuwg5675");}}/> 

                                                {ButtonColor.map((item) => (<div key={item.id} className={item.id === MyGeneralButtonColorId? "border-for-active" :item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onClick={()=>{myGeneralsettingButtonColor(item.id,item.color)}}></div>))}
                                            </div>
                                        </div>

                                    </div>
                                    <div className='styles23-button-type'>
                                        <p>Button Type</p>
                                        <div style={{display:"flex"}}>
                                            <button className={BTNType? "activeicons26-button" : "icons26-button"} onClick={()=>setBTNType(true)}>Solid</button>
                                        <button className={BTNType? "icons26-button" : "activeicons26-button"} onClick={()=>setBTNType(false)}>Regular</button>
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div className='styles23-body-size'>
                                    <p>Body Size</p>
                                    <div 
                                    // style={{display:"flex",justifyContent:"center"}} 
                                    >
                                        <img style={bodySize=="with margin"?{border: "1px solid black",padding: "10px",width:"40%"}:{border:"none",padding: "10px",width:"40%"}} src={MockIphone1} alt="MockIphone1" onClick={()=>setBodySize("with margin")} />
                                        <img style={bodySize=="without margin"?{border: "1px solid black",padding: "10px",width:"40%"}:{border:"none",padding: "10px",width:"40%"}} src={MockIphone2} alt="MockIphone2" onClick={()=>setBodySize("without margin")} />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                {/* <MyPhone NavName="My Radio" OtherContent={MyContent}/> */}

            </div >
        </>
    )
}

export default GeneralSettings