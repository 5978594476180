import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function LiveFeedDesign() {
  let {setMobileValue,displayForSinglePhoneScreen,} = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="0uu8it987")                
            setMobileValue(value.text)
             },[])
  const textColors = [
    { classIs: "text-colors-white", color: "white" },
    { classIs: "text-colors-red", color: "#EF5050" },
    { classIs: "text-colors-yellow", color: "#FFB257" },
    { classIs: "text-colors-green", color: "#A9B42A" },
    { classIs: "text-colors-lightgreen", color: "#4DCE71" },
    { classIs: "text-colors-skyblue", color: "#4DCEC6" },
    { classIs: "text-colors-lightblue", color: "#4D98CE" },
    { classIs: "text-colors-blue", color: "#504DCE" },
    { classIs: "text-colors-pink", color: "#CB4DCE" },
    { classIs: "text-colors-black", color: "black" },
]
  return (
    <>
    <div className='text-clr'>
                                <p style={{color:"#fff"}}>Text Color</p>
                                <div className='text-colors'>
                                    <div className='text-hexa-colors'>
                                        {textColors.map((item) => (<div key={item.color} className={item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}></div>))}
                                    </div>
                                    <div className='Hexa-text'>
                                        <label style={{color:"#fff"}} htmlFor="Hexa-text-here">Hexa</label>
                                        <input type="text" name="Hexa-text-here" id="Hexa-text-here" />
                                    </div>
                                </div>
                            </div>
    </>
  )
}

export default LiveFeedDesign