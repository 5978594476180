import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function PodcastDesign() {
  let { setMobileValue,myPodcastDesignColorId,setMyPodcastDesignColorId,myPodcastDesignColor,setMyPodcastDesignColor,displayForSinglePhoneScreen } = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="jgwqdu")                
            setMobileValue(value.text)
             },[])
  const textColors = [
    { id: "vkd908e390778upoiuytrewq12", classIs: "text-colors-yellow", color: "#ff9150" },
    { id: "uykd908e390778upoiuytrewq23", classIs: "text-colors-green", color: "#ff4757" },
    { id: "uykd908e390778upoiuytrewq34", classIs: "text-colors-lightgreen", color: "#2ed573" },
    { id: "kd908e390778upoiuytrewq45", classIs: "text-colors-skyblue", color: "#1e90ff" },
    { id: "ukd908e390778upoiuytrewq56", classIs: "text-colors-lightblue", color: "#ffc0cb" },
    // { classIs: "text-colors-blue", color: "#504DCE" },
    { id: "ugkd908e390778upoiuytrewq67", classIs: "text-colors-pink", color: "#CB4DCE" },
    { id: "vuykd908e390778upoiuytrewq78", classIs: "text-colors-black", color: "#000000" },
    // { id: "vkd908e390778upoiuytrewq89", classIs: "text-colors-white", color: "none" },
  ]

  const myPodcastButtonColor = (id, color) => {

    setMyPodcastDesignColorId(id);
    setMyPodcastDesignColor(color);
  }

  return (
    <>
      <div className='text-clr'>
        <p style={{ color: "#fff" }}>Text Color</p>
        <div className='text-colors'>
          <div className='text-hexa-colors'>
            <input className={"iuweqrtqqyquqioqqpoq" === myPodcastDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myPodcastButtonColor("rt547ru785rt3454", (e.target.value)); setMyPodcastDesignColorId("iuweqrtqqyquqioqqpoq"); }} />

            {textColors.map((item) => (<div key={item.id} className={item.id === myPodcastDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myPodcastButtonColor(item.id, item.color) }}></div>))}
          </div>
          <div className='Hexa-text'>
            <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myPodcastDesignColor} onChange={(e) => { setMyPodcastDesignColor(`#${e.target.value.replace('#', '')}`); setMyPodcastDesignColorId("iuweqrtqqyquqioqqpoq"); }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PodcastDesign