import React, { useContext, useEffect, useState } from 'react'
import { TbBellPlusFilled } from "react-icons/tb";
import { FaQuestion } from "react-icons/fa6";
import { AiFillLike } from "react-icons/ai";
import { AiFillDislike } from "react-icons/ai";
import { FaShareAlt } from "react-icons/fa";
import Mask1 from '../images/Mask1.png'
import Mask2 from '../images/Mask2.png'
import Mask3 from '../images/Mask3.png'
import Mask4 from '../images/Mask4.png'
import Mask5 from '../images/Mask5.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MyMobileContext } from './Context/MyMobileContext';

function ChooseTheme() {

    const navigate = useNavigate();
    const {buttonValue,setButtonValue} = useContext(MyMobileContext)
    const [templates1, setTemplates1] = useState(null)
    const [templates2, setTemplates2] = useState(null)
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [t1monthly, setT1Monthly] = useState(false);
    const [t1annually, setT1Annually] = useState(false);
    const [t2monthly, setT2Monthly] = useState(false);
    const [t2annually, setT2Annually] = useState(false);
    

    useEffect(() => {
        setLoading(true);
        axios.post("http://radioik.com/appadmin/api/theme-list")
            .then((responce) => {
                console.log(responce,"all")
                setTemplates1(responce.data.result.data[0].
                    theme_image)
                setTemplates2(responce.data.result.data[1].
                    theme_image)
                // console.log(responce.data.result.data[0].
                //     theme_image,"inner")
                
                setLoading(false);
            })
            .catch((error) => {
                setError('Error: ' + error)
                alert(error);
                setLoading(false);

            })
    },[])

    // const templates = [
    //     {heading:"Home Screen",image:<img style={{backgroundColor:"white",borderRadius:"40px"}} src={Mask1} alt="Mask1"/>},
    //     {heading:"Podcast Screen",image:<img style={{backgroundColor:"white",borderRadius:"40px"}} src={Mask2} alt="Mask2"/>},
    //     {heading:"Schedule Screen",image:<img style={{backgroundColor:"white",borderRadius:"40px"}} src={Mask3} alt="Mask3"/>},
    //     {heading:"Gallery Screen",image:<img src={Mask4} alt="Mask4"/>},
    //     {heading:"Style Screen",image:<img src={Mask5} alt="Mask5"/>},
    // ]

    const goToDashboard = (themeName) => {
        if(themeName == "Theme1"){

            if(t1annually && t1monthly){
                alert("Please select one either monthly or annually both are not allowed");
            }else if(t1annually || t1monthly){
                alert("you have selected Theme 1 "+ (t1annually?"Annualy Plan":"Monthly Plan"))
                navigate("/radio/generalsetting");
                console.log(buttonValue,"chooseTheme before");
                setButtonValue(t1annually ? "$10.99" : "$0.99");
                console.log(buttonValue,"chooseTheme after");
                
            }else{
                alert("Please select one either monthly or annually plan");
            }

        }else{
            if(t2annually && t2monthly){
                alert("Please select one either monthly or annually both are not allowed");
            }else if(t2annually || t2monthly){
                alert("you have selected Theme 2 "+ (t2annually?"Annualy Plan":"Monthly Plan"))
                navigate("/radio/generalsetting");
                console.log(buttonValue,"chooseTheme after");
                setButtonValue(t2annually ? "$10.99" : "$0.99");

                console.log(buttonValue,"chooseTheme after");
                
            }else{
                alert("Please select one either monthly or annually plan");
            }
        }

        
    }
    const fun = (url) =>{
        // return url.replace('%28', '(').replace('%29', ')');
        // return decodeURIComponent(url);
        return url
        .replace('(', '%28')
        .replace(')', '%29');
    }

    return (
        <>
            <div className='Theme-navbar'>
                <div className='theme-nev-option'>
                    <h2>Home</h2>
                </div>
                <div className='theme-nev-options'>
                    <p>See all offers</p>
                    <FaQuestion />
                    <TbBellPlusFilled />
                </div>
            </div>

            <div className='themes'>
                <div className='themes-main'>
                    <div className='themes-content1'>
                        <p className='themes-content1-para1'>Theme 1</p>
                        <p onClick={() => goToDashboard("Theme1")} className='themes-content1-para2'>Next &#62;&#62;</p>
                    </div>
                    <div className='themes-content2'>
                        <div className='themes-content2-div1'><input type="checkbox" name="monthly-price" checked={t1monthly} id="monthly-price1" onChange={()=>setT1Monthly(!t1monthly)}/><label htmlFor='monthly-price1'>Monthly $0.99</label></div>
                        <div className='themes-content2-div2'><input type="checkbox" name="Annually-price" checked={t1annually} id="Annually-price1" onChange={()=>setT1Annually(!t1annually)}/><label htmlFor='Annually-price1'>Annually $10.99</label></div>
                    </div>
                    <div className='Main-themes'>
                        {/* {!templates && <div></div>} */}
                        {loading && <div className='loader'></div>}
                        {!loading && templates1 && Array.isArray(templates1) && templates1.map((item,index) => (
                            <div className='tempss' key={index}>
                                <div className='temp122'>
                                    <div className='main-temps' style={{ backgroundImage: `url(${fun(item)})`}}>
                                        {/* {console.log(fun(item))
                                        } */}
                                         {/* .theme_image.map((item,index)=>item[index])})` }}> */}
                                        {/* <div className='temp-heading'><p>{item.heading}</p></div>
                     <div className='temp-image'>{item.image}</div>
                     <div className='temp-logos'><div className='temp-2logos'><AiFillLike /> <AiFillDislike /></div> <FaShareAlt /></div>
                     <div className='temp-end-btn'><button >GET IT</button></div> */}
                                    </div>
                                </div>
                            </div>
                            // <div className="tempss" style={{background:"red"}}>
                            //     {item.heading}
                            // </div>
                        ))}
                    </div>

                </div>
            </div>
            <hr />
            <div className='themes'>
                <div className='themes-main'>
                    <div className='themes-content1'>
                        <p className='themes-content1-para1'>Theme 2</p>
                        <p onClick={() => goToDashboard("Theme2")} className='themes-content1-para2'>Next &#62;&#62;</p>
                    </div>
                    <div className='themes-content2'>
                        <div className='themes-content2-div1'><input type="checkbox" name="monthly-price" checked={t2monthly} id="monthly-price" onChange={()=>setT2Monthly(!t2monthly)}/><label htmlFor='monthly-price'>Monthly $0.99</label></div>
                        <div className='themes-content2-div2'><input type="checkbox" name="Annually-price" checked={t2annually} id="Annually-price" onChange={()=>setT2Annually(!t2annually)} /><label htmlFor='Annually-price'>Annually $10.99</label></div>
                    </div>
                    <div className='Main-themes'>

                        {/* {!templates && <div></div>} */}
                        {loading && <div className='loader'></div>}
                        {!loading && templates2 && Array.isArray(templates2) && templates2.map((item,index) => (
                            <div className='tempss' key={index}>
                                <div className='temp122'>
                                    <div className='main-temps' style={{ backgroundImage: `url(${fun(item)})`}}>
                                        {/* {console.log(fun(item))
                                        } */}
                                         {/* .theme_image.map((item,index)=>item[index])})` }}> */}
                                        {/* <div className='temp-heading'><p>{item.heading}</p></div>
                     <div className='temp-image'>{item.image}</div>
                     <div className='temp-logos'><div className='temp-2logos'><AiFillLike /> <AiFillDislike /></div> <FaShareAlt /></div>
                     <div className='temp-end-btn'><button >GET IT</button></div> */}
                                    </div>
                                </div>
                            </div>
                            // <div className="tempss" style={{background:"red"}}>
                            //     {item.heading}
                            // </div>
                        ))}
                        
                        {/* {templates.map((item) => (
                            <div className='tempss' key={item.heading}>
                                <div className='temp1'>
                                    <div className='main-temps'>
                                        <div className='temp-heading'><p>{item.heading}</p></div>
                                        <div className='temp-image'>{item.image}</div>
                                        <div className='temp-logos'><div className='temp-2logos'><AiFillLike /> <AiFillDislike /></div> <FaShareAlt /></div>
                                        <div className='temp-end-btn'><button >GET IT</button></div>
                                    </div>
                                </div>
                            </div>
                        ))} */}
                    </div>

                </div>
            </div>

        </>
    )
}

export default ChooseTheme