import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';
import { CiCircleInfo } from "react-icons/ci";
import { HtmlTextContext } from './Context/HtmlTextContext';
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { BsX } from 'react-icons/bs';

function HTMLPage() {

  const [myht,setMyht] = useState("")

  var modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // Adding header options from h1 to h6
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ["link", "image"],
        [{ align: [] }],
      
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleProcedureContentChange = (content) => {
    console.log("content---->", content);
    setTextValue(content);
  };

  let { myPagies, setMyPagies, allMyPagies, setAllMyPagies, icons } = useContext(SidebarMenuContext)
  const { textValue, setTextValue } = useContext(HtmlTextContext)
  const handleChange = (e) => {
    setTextValue(e.target.value);
  }
  let { setMobileValue, displayForSinglePhoneScreen, setDisplayForSinglePhoneScreen } = useContext(MyMobileContext)
  useEffect(() => {
    let value = displayForSinglePhoneScreen.find((item) => item.id == "iuoweg")
    setMobileValue(value.text)
  }, [displayForSinglePhoneScreen])
  const updateText = (id, text, secondId) => {
    setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item)));
    setDisplayForSinglePhoneScreen(prev => prev.map((item) => item.id === secondId ? { ...item, text: text } : item))
    setMobileValue(text)
  };

  const targetItem = myPagies.find(item => item.id === "hbjvrobrds");
  const targetText = targetItem ? targetItem.text : "";

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  return (
    <>

      <div>
        <hr style={{ margin: "20px 0px" }} />
        <div className='my-radio-facebook-icon'>
          <p>Icon and page name</p>
          <div className='my-radio-icon-and-page-facebook'>
            <div onClick={() => { setDropdownVisible(!isDropdownVisible) }}>
              {/* {selectedIcon ? selectedIcon : myPagies.find(((item) => item.id == "hbjvrobrds")).icon || allMyPagies.find((item)=>item.id == "hbjvrobrds").icon} */}
              {selectedIcon ||
                myPagies.find(item => item.id === "hbjvrobrds")?.icon ||
                allMyPagies.find(item => item.id === "hbjvrobrds")?.icon}
            </div>
            {isDropdownVisible && (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    height: "150px",
                    width: "305px",
                    color: "black",
                    padding: "25px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    background: "#fff",
                    overflowY: "auto",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    position: "absolute",
                    zIndex: 1,
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                  }}
                >
                  <div style={{ position: 'fixed', cursor: 'pointer', marginLeft: "290px" }} onClick={() => setDropdownVisible(false)}>
                    <BsX fontSize="20px" />
                  </div>
                  {icons.map((iconObj) => (
                    <div
                      key={iconObj.id}
                      onClick={() => {
                        setSelectedIcon(iconObj.icon);
                        setDropdownVisible(false);
                        setMyPagies(prev => prev.map((item) => item.id == "hbjvrobrds" ? { ...item, icon: iconObj.icon } : item))
                        setAllMyPagies(prev => prev.map((item) => item.id == "hbjvrobrds" ? { ...item, icon: iconObj.icon } : item))
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        background: "#f9f9f9",
                      }}
                    >
                      {iconObj.icon}
                      <span style={{ fontSize: "12px", marginTop: "5px" }}>{iconObj.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <input type='text' maxLength={45} value={targetText} onChange={(e) => { updateText("hbjvrobrds", e.target.value, "iuoweg") }} />
          </div>
        </div>
        <hr />
        <div className='my-radio-rss-feed-link'>
          <p>Page contents</p>
          {/* <textarea type="text" name="html-text-here" id="html-text-here" value={textValue} onChange={handleChange} /> */}
          <ReactQuill
                      theme="snow"
                      modules={modules}
                      formats={formats}
                      value={textValue}
                      placeholder="write your content ...."
                      onChange={handleProcedureContentChange}
                      style={{backgroundColor:"#eddcff",color:"#343236",borderRadius:"10px",marginBottom:"20px" }}
          >

      
          </ReactQuill>
        </div>

      </div>


    </>
  )
}

export default HTMLPage