import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';
import { MdOutlineRecordVoiceOver } from "react-icons/md";
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { BsX } from 'react-icons/bs';

function AudioShoutOut() {
    let { myPagies, setMyPagies,allMyPagies,setAllMyPagies,icons } = useContext(SidebarMenuContext)
     let {setMobileValue,setDisplayForSinglePhoneScreen,displayForSinglePhoneScreen} = useContext(MyMobileContext)
    useEffect(() => {
      let value = displayForSinglePhoneScreen.find((item)=>item.id=="iuy5o8")                
      setMobileValue(value.text)
    }, [displayForSinglePhoneScreen])
    const updateText = (id, text,secondId) => { 
      setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item))); 
      setDisplayForSinglePhoneScreen(prev =>prev.map((item)=>item.id===secondId?{...item,text:text}:item))
      setMobileValue(text)
  };


             const targetItem = myPagies.find(item => item.id === "bsj9y8hgfc");
             const targetText = targetItem ? targetItem.text : "";

               const [selectedIcon, setSelectedIcon] = useState(null);
               const [isDropdownVisible, setDropdownVisible] = useState(false);
             
  return (
    <>
     <div>
                     <hr style={{ margin: "20px 0px" }} />
                                        <div className='my-radio-facebook-icon'>
                                            <p>Icon and page name</p>
                                            <div className='my-radio-icon-and-page-facebook'>
                                                 <div onClick={() => { setDropdownVisible(!isDropdownVisible) }}>
                                                              {/* {selectedIcon ? selectedIcon : myPagies.find(((item) => item.id == "bjeuiuo3uh")).icon || allMyPagies.find((item)=>item.id == "bjeuiuo3uh").icon} */}
                                                              {selectedIcon ||
                                                    myPagies.find(item => item.id === "bsj9y8hgfc")?.icon ||
                                                    allMyPagies.find(item => item.id === "bsj9y8hgfc")?.icon}
                                                              </div>
                                                            {isDropdownVisible && (
                                                              <div style={{ position: 'relative' }}>
                                                                <div
                                                                  style={{
                                                                    height: "150px",
                                                                    width: "305px",
                                                                    color: "black",
                                                                    padding: "25px",
                                                                    border: "1px solid #ccc",
                                                                    borderRadius: "5px",
                                                                    background: "#fff",
                                                                    overflowY: "auto",
                                                                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                                                    position: "absolute",
                                                                    zIndex: 1,
                                                                    display: "grid",
                                                                    gridTemplateColumns: "repeat(3, 1fr)",
                                                                    gap: "10px",
                                                                  }}
                                                                >
                                                                  <div style={{ position: 'fixed', cursor: 'pointer', marginLeft: "290px" }} onClick={() => setDropdownVisible(false)}>
                                                                    <BsX fontSize="20px" />
                                                                  </div>
                                                                  {icons.map((iconObj) => (
                                                                    <div
                                                                      key={iconObj.id}
                                                                      onClick={() => {
                                                                        setSelectedIcon(iconObj.icon);
                                                                        setDropdownVisible(false);
                                                                        setMyPagies(prev => prev.map((item) => item.id == "bsj9y8hgfc" ? { ...item, icon: iconObj.icon } : item))
                                                                        setAllMyPagies(prev => prev.map((item) => item.id == "bsj9y8hgfc" ? { ...item, icon: iconObj.icon } : item))
                                                                      }}
                                                                      style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        padding: "10px",
                                                                        cursor: "pointer",
                                                                        borderRadius: "5px",
                                                                        background: "#f9f9f9",
                                                                      }}
                                                                    >
                                                                      {iconObj.icon}
                                                                      <span style={{ fontSize: "12px", marginTop: "5px" }}>{iconObj.name}</span>
                                                                    </div>
                                                                  ))}
                                                                </div>
                                                              </div>
                                                            )}
                                                
                                                <input type='text' maxLength={45} value={targetText} onChange={(e)=>{updateText("bsj9y8hgfc",e.target.value,"iuy5o8")}}/>
                                            </div>
                                        </div>
                                        {/* <hr />
                                        
                                        <div className='my-radio-blog-content-text'>
                                        <p>This page requires a RadioKing stream to work.  <br/><span style={{fontWeight:"800"}}>Create my radio</span></p>
                                        </div>

                                        <hr style={{margin:"20px 0px"}}/>
                                        <div className='my-radio-rss-feed-link'>
                                            <p>Radio</p>
                                            <select name="Audio-shout-type" id="Audio-shout-type" className='Audio-shout-out-options'>
                                <option value="radioik">Choose a radio</option>
                                <option value="etc">etc</option>
                            </select>
                                        </div>

                                        <hr style={{margin:"20px 0px"}}/>
                                        <div className='my-radio-rss-feed-link'>
                                            <p>Text</p>

                                            <div className='My-radio-buttons-part2'><label htmlFor="Welcome">Welcome</label>
                                <label className="switch">
                                    <input type="checkbox" name='Welcome' id='Welcome' />
                                    <span className="slider round"></span>
                                </label></div>

                                <div className='My-radio-buttons-part2'><label htmlFor="End">End</label>
                                <label className="switch">
                                    <input type="checkbox" name='End' id='End' />
                                    <span className="slider round"></span>
                                </label></div>
                                        </div> */}
                </div>
    </>
  )
}

export default AudioShoutOut