import React from 'react'
import MainNavbar from '../MainNavbar'
import { Outlet } from 'react-router-dom'

function OthersLayout() {
  return (
    <>
    <MainNavbar/>
    <Outlet/>
    </>
  )
}

export default OthersLayout