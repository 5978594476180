import React from 'react'
import { NavLink } from 'react-router-dom'

function MyRadioMain({RadioLink1,RadioLink2}) {
  return (
    <>
     <div className='my-radio-background-part'>
                        <p>Background</p>
                        <div className='my-radio-background-part1-buttons'>
                        <NavLink to={RadioLink1}  className={(navData) => navData.isActive ? `Active-bg` : "Not-Active-bg"}><div>Content</div></NavLink>
                        <NavLink to={RadioLink2}  className={(navData) => navData.isActive ? `Active-bg` : "Not-Active-bg"}> <div>Design</div></NavLink>
                        </div>
                    </div>
    </>
  )
}

export default MyRadioMain