import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';
import { LuMessageSquarePlus } from "react-icons/lu";
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { BsX } from 'react-icons/bs';

function Dedication() {
  let { myPagies, setMyPagies,allMyPagies,setAllMyPagies, icons } = useContext(SidebarMenuContext)
  let { setMobileValue, setDisplayForSinglePhoneScreen, displayForSinglePhoneScreen } = useContext(MyMobileContext)

  useEffect(() => {
    let value = displayForSinglePhoneScreen.find((item) => item.id == "76ryt383")
    setMobileValue(value.text)
  }, [displayForSinglePhoneScreen])
  const updateText = (id, text, secondId) => {
    setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item)));
    setDisplayForSinglePhoneScreen(prev => prev.map((item) => item.id === secondId ? { ...item, text: text } : item))
    setMobileValue(text)
  };

  const targetItem = myPagies.find(item => item.id === "bjeuiuo3uh");
  const targetText = targetItem ? targetItem.text : "";

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  return (
    <>
      <div>
        <hr style={{ margin: "20px 0px" }} />
        <div className='my-radio-facebook-icon'>
          <p>Icon and page name</p>
          <div className='my-radio-icon-and-page-facebook'>
            <div onClick={() => { setDropdownVisible(!isDropdownVisible) }}>
              {/* {selectedIcon ? selectedIcon : myPagies.find(((item) => item.id == "bjeuiuo3uh")).icon || allMyPagies.find((item)=>item.id == "bjeuiuo3uh").icon} */}
              {selectedIcon ||
    myPagies.find(item => item.id === "bjeuiuo3uh")?.icon ||
    allMyPagies.find(item => item.id === "bjeuiuo3uh")?.icon}
              </div>
            {isDropdownVisible && (
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    height: "150px",
                    width: "305px",
                    color: "black",
                    padding: "25px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    background: "#fff",
                    overflowY: "auto",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    position: "absolute",
                    zIndex: 1,
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                  }}
                >
                  <div style={{ position: 'fixed', cursor: 'pointer', marginLeft: "290px" }} onClick={() => setDropdownVisible(false)}>
                    <BsX fontSize="20px" />
                  </div>
                  {icons.map((iconObj) => (
                    <div
                      key={iconObj.id}
                      onClick={() => {
                        setSelectedIcon(iconObj.icon);
                        setDropdownVisible(false);
                        setMyPagies(prev => prev.map((item) => item.id == "bjeuiuo3uh" ? { ...item, icon: iconObj.icon } : item))
                        setAllMyPagies(prev => prev.map((item) => item.id == "bjeuiuo3uh" ? { ...item, icon: iconObj.icon } : item))
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "5px",
                        background: "#f9f9f9",
                      }}
                    >
                      {iconObj.icon}
                      <span style={{ fontSize: "12px", marginTop: "5px" }}>{iconObj.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <input type="text" maxLength={45} value={targetText} onChange={(e) => updateText("bjeuiuo3uh", e.target.value, "76ryt383")} />
          </div>
        </div>
        <hr />
        <div className='my-radio-rss-feed-link'>
          <p>Link to your Radio website</p>
          <input type="url" name="rss-feed-url" id="rss-feed-url" />
        </div>
        {/* <div className='my-radio-blog-content-text'>
          <p>This page requires a Radioik website to work.<br /><span style={{ fontWeight: "800" }}>Create my Radio Website</span></p>
        </div> */}
      </div>
    </>
  )
}

export default Dedication