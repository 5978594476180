import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function RSSDesign() {
    let { setMobileValue,myRSSDesignColorId,setMyRSSDesignColorId,myRSSDesignColor,setMyRSSDesignColor,displayForSinglePhoneScreen,} = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="hu3489hsd")                
            setMobileValue(value.text)
             },[])
    const textColors = [
        { id: "vuip333oo9907873y1", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "uyui79poo9907873y2", classIs: "text-colors-green", color: "#ff4757" },
        { id: "uyu598poo9907873y3", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "ui99oo9907873y4", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "u4485poo9907873y5", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "ugu8pppoo9907873y6", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "vu98ipppoo9907873y7", classIs: "text-colors-black", color: "#000000" },
        // { id: "v58ipppoo99078738", classIs: "text-colors-white", color: "none" },
    ]

    const myRSSButtonColor = (id, color) => {

        setMyRSSDesignColorId(id);
        setMyRSSDesignColor(color);
    }


    return (
        <>
            <div className='text-clr'>
                <p style={{ color: "#fff" }}>Text Color</p>
                <div className='text-colors'>
                    <div className='text-hexa-colors'>
                        <input className={"ihjkp000098iolmk" === myRSSDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myRSSButtonColor("rt547ru785rt3454", (e.target.value)); setMyRSSDesignColorId("ihjkp000098iolmk"); }} />

                        {textColors.map((item) => (<div key={item.id} className={item.id === myRSSDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myRSSButtonColor(item.id, item.color) }}></div>))}
                    </div>
                    <div className='Hexa-text'>
                        <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
                        <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myRSSDesignColor} onChange={(e) => { setMyRSSDesignColor(`#${e.target.value.replace('#', '')}`); setMyRSSDesignColorId("ihjkp000098iolmk"); }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default RSSDesign