import React, { useState, useEffect } from 'react'
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

function MainNavbar2() {
    const navigate = useNavigate()
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [showAlert]);
    return (
        <>
            <div className='main-navbar2-main'>
                <div className='navbar2-first-div' onClick={() => navigate("/chooseappname")}>Previous</div>
                <div className='navbar2-second-div'>

                    <div className='navbar2-second-div-one'>
                        <div style={{ backgroundColor: "#ebd7ff" }}><FaCheck /></div>
                        <hr style={{ border: "1.5px solid #ebd7ff" }} />
                        <div style={{ border: "2px solid #ebd7ff", backgroundColor: "#fff" }}>2</div>
                        <hr style={{ border: "1.5px solid #FFF" }} />
                        <div>3</div>
                        <hr style={{ border: "1.5px solid #FFF" }} />
                        <div>4</div>
                    </div>
                    <div className='navbar2-content'>
                        <p>Name</p>
                        <p>Design & content</p>
                        <p>Generation</p>
                        <p>Your turn</p>
                    </div>
                </div>
                <div className='navbar2-third-div' onClick={() => { setShowAlert(true); navigate("/radioik/pricingplan");  }}>Next</div>
                {showAlert && (<div className="alert" style={{ display: showAlert ? 'block' : 'none' }}> This is an alert message! </div>)}
            </div>
        </>
    )
}

export default MainNavbar2