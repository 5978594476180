import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function VideoDesign() {
  let { setMobileValue,myVideoDesignColorId,setMyVideoDesignColorId,myVideoDesignColor,setMyVideoDesignColor,displayForSinglePhoneScreen } = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="0y86ttguy")                
            setMobileValue(value.text)
             },[])
  const textColors = [
    { id: "vuipppoo9907873y1", classIs: "text-colors-yellow", color: "#ff9150" },
    { id: "uyuipppoo9907873y2", classIs: "text-colors-green", color: "#ff4757" },
    { id: "uyuipppoo9907873y3", classIs: "text-colors-lightgreen", color: "#2ed573" },
    { id: "uipppoo9907873y4", classIs: "text-colors-skyblue", color: "#1e90ff" },
    { id: "uuipppoo9907873y5", classIs: "text-colors-lightblue", color: "#ffc0cb" },
    // { classIs: "text-colors-blue", color: "#504DCE" },
    { id: "uguipppoo9907873y6", classIs: "text-colors-pink", color: "#CB4DCE" },
    { id: "vuyuipppoo9907873y7", classIs: "text-colors-black", color: "#000000" },
    // { id: "vuuipppoo99078738", classIs: "text-colors-white", color: "none" },
  ]

  const myVideoButtonColor = (id, color) => {

    setMyVideoDesignColorId(id);
    setMyVideoDesignColor(color);
  }
  return (
    <>
      <div className='text-clr'>
        <p style={{ color: "#fff" }}>Text Color</p>
        <div className='text-colors'>
          <div className='text-hexa-colors'>
            <input className={"iuweqrtqqyquqioqqpoq" === myVideoDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myVideoButtonColor("rt547ru785rt3454", (e.target.value)); setMyVideoDesignColorId("iuweqrtqqyquqioqqpoq"); }} />

            {textColors.map((item) => (<div key={item.id} className={item.id === myVideoDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myVideoButtonColor(item.id, item.color) }}></div>))}
          </div>
          <div className='Hexa-text'>
            <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myVideoDesignColor} onChange={(e) => { setMyVideoDesignColor(`#${e.target.value.replace('#', '')}`); setMyVideoDesignColorId("iuweqrtqqyquqioqqpoq"); }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default VideoDesign