import React, { useContext, useState } from 'react'
import AuthNavbar from './AuthNavbar'
import Maskgroup from '../images/Maskgroup.png'
import { useNavigate } from 'react-router-dom'
import { MyMobileContext } from './Context/MyMobileContext'

function ChooseAppName() {


    const{appName,setAppName,shortDescription,setShortDescription,longDescription,setLongDescription} = useContext(MyMobileContext)
    
    let navigate = useNavigate();

    const goToRadioDashboard = (e) =>{   
       e.preventDefault();

        if (!appName || appName.length === 0) {
            alert("Please enter app name");
        } else if (appName.length < 3) {
            alert("App name must be at least 3 characters long");
        } else {
            navigate("/choosetheme");
        }
    }
  return (
    <>
    <AuthNavbar/>
    <div className='App-name-main'>
        <div className='app-main1'>
            <div className='main-app-text'><p>Hello User !</p></div>
            <div className='app-main2'>

                <div className='app-left-section'>
                    <div className='app-left-inner'>
                    <form onSubmit={goToRadioDashboard}>
                    <p>Choose app name</p>
                    <input className='app-left-input1' value={appName} type="text" name="name" id="name" onChange={(e)=>setAppName(e.target.value)}/>
                    <p>Short description</p>
                    <textarea className='app-left-input2' value={shortDescription} id="w3review" name="w3review" rows="4" cols="50" onChange={(e)=>setShortDescription(e.target.value)}/>
                    <p>Long description</p>
                    <textarea className='app-left-input3' value={longDescription} id="w3review" name="w3review" rows="6" cols="50" onChange={(e)=>setLongDescription(e.target.value)}/>
                    <button className='app-left-btn' type='submit'>Start</button>
                    </form>
                    </div>

                    
                </div>
                <div className='app-right-section' style={{margin:"0px",width:"100%",height:"79.8vh"}}>
                    <img style={{width:"100%",height:"100%"}} src={Maskgroup} alt="Radioik images" />
                </div>
            </div>
        </div>

    </div>
    </>
  )
}

export default ChooseAppName