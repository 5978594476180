import React,{useState} from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'

function PodcastLayout() {
  return (
    <>
    <div style={{width:"35%" }}>
        <div className='my-radio-content-starts-here1'>
        <div className='setting-45'>
            <MyRadioMain RadioLink1={"/radio/podcast"} RadioLink2={"/radio/podcast/design"}/>
            <Outlet />
        </div>
        </div>
       
    </div>
</>
  )
}

export default PodcastLayout