import React, { useState, useEffect, useContext } from 'react'
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { MyMobileContext } from './Context/MyMobileContext';

function MainNavbar3() {
    const navigate = useNavigate()
    const {setShowPopup,appIconPreviews, setAppIconPreviews,smartPhoneLargePreviews, setSmartPhoneLargePreviews,smartPhonePreviews, setSmartPhonePreviews} = useContext(MyMobileContext)
    
    return (
        <>
            <div className='main-navbar2-main'>
                <div className='navbar2-first-div' onClick={() => navigate("/radioik/pricingplan")}>Previous</div>
                <div className='navbar2-second-div'>

                    <div className='navbar2-second-div-one'>
                        <div style={{ backgroundColor: "#ebd7ff" }}><FaCheck /></div>
                        <hr style={{ border: "1.5px solid #ebd7ff" }} />
                        <div style={{ backgroundColor: "#ebd7ff" }} ><FaCheck /></div>
                        <hr style={{ border: "1.5px solid #FFF" }} />
                        <div style={{ border: "2px solid #ebd7ff", backgroundColor: "#fff" }}>3</div>
                        <hr style={{ border: "1.5px solid #FFF" }} />
                        <div>4</div>
                    </div>
                    <div className='navbar2-content'>
                        <p>Name</p>
                        <p>Design & content</p>
                        <p>Generation</p>
                        <p>Your turn</p>
                    </div>
                </div>
                <div className='navbar2-third-div' onClick={() => {appIconPreviews&&smartPhoneLargePreviews&&smartPhonePreviews?setShowPopup(true):alert("please upload all the Images")}}>Next</div>
            </div>
        </>
    )
}

export default MainNavbar3