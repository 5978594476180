import React, { useState } from 'react';
import signup from '../images/signup.png';
import AuthNavbar from './AuthNavbar';
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function SignUp() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [eye, setEye] = useState(false);
    const [confEye, setConfEye] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(null);

    let navigate = useNavigate();

        const [message, setMessage] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);

    const handleForm = (e) => {
        e.preventDefault();
        setError(null); 
        setLoading(false);

        if(email && password && confirmPassword){
            if(password.length < 8 || confirmPassword.length < 8){
                setMessage("Password should contain 8 characters minimum")
                setTimeout(Mytime,2000)
                setLoading(false)
            }else if(password.length>7 && confirmPassword.length>7){                
                if(password != confirmPassword){
                    setMessage("Password miss match with confirm password")
                    setTimeout(Mytime,2000)
                    setLoading(false)
                }else{
                    axios.post("http://radioik.com/appadmin/api/register",{email,password})
                    .then((response)=>{
                        console.log(response.data);
                        if(response.data.status == 1){
                            setLoading(false)
                            setMessage('Login successful!');
                            navigate("/chooseappname")
                        }else {
                            setLoading(false);
                            setMessage(response.data.message)
                            setTimeout(Mytime,2000)
                        }
                        

                    })
                    .catch(()=>{
                        setError('Error: ' + error.message);
                    })
                }
            }

        }else {
            alert("Please enter email,password and confirm password")
        }
    }
    const Mytime =()=>{
        setMessage('')
      }

    return (
        <>
            <AuthNavbar />
            <div className='Auth-main'>
                <div className='inner-auth-main'>
                    <div className='signup-div1'>
                        <h1 className='signup-div1-heading'>Hello...</h1>
                        <p className='signup-div1-para'>Launch your very own
                            Internet Radio Station App
                        </p>
                    </div>
                    <div className='signup-div2'><img style={{ width: "100%", height: "100%" }} src={signup} alt="singing image" /></div>
                    <div className='signup-div3'>
                        <form onSubmit={handleForm}>
                            <h2 className='signup-div3-heading'>Create your account</h2>
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" placeholder='example@example.com' onChange={(e) => setEmail(e.target.value)} />

                            <label htmlFor="password">Password</label>
                            <div style={{ position: "relative" }}>

                                {eye ? <ImEye onClick={() => setEye(false)
                                } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888', cursor: "pointer" }} />
                                    : <ImEyeBlocked onClick={() => setEye(true)
                                    } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888', cursor: "pointer" }} />}
                                <input style={{ marginBottom: "15px", paddingLeft: '40px' }} value={password} placeholder='8 characters minimum' onChange={(e) => setPassword(e.target.value)} className='passrec-div3-input' type={eye ? "text" : "password"} name="password" id="password" />

                            </div>

                            <label htmlFor="confirm-password">Confirm Password</label>
                            <div style={{ position: "relative" }}>

                                {confEye ? <ImEye onClick={() => setConfEye(false)
                                } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888', cursor: "pointer" }} />
                                    : <ImEyeBlocked onClick={() => setConfEye(true)
                                    } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888', cursor: "pointer" }} />}
                                <input style={{ marginBottom: "15px", paddingLeft: '40px' }} value={confirmPassword} placeholder='8 characters minimum' onChange={(e) => setConfirmPassword(e.target.value)} className='passrec-div3-input' type={confEye ? "text" : "password"} name="confirm-password" id="confirm-password" />

                            </div>
                            
                            {/* <input type="password" name="confirm-password" id="confirm-password" onChange={(e) => setConfirmPassword(e.target.value)} /> */}
                            {!loading && <input className='login-button' type="submit" value="Signup" />}
                            {loading && <div className='loader'></div>}

                            {message &&  <div style={{marginTop:"15px",color:"red"}}>{message}</div> }
                        </form>
                        <div className='login-with'>
                            <div style={{ float: "left", width: "20%" }}><hr /></div>
                            <p className='login-with-text1'>&nbsp; Or create your account with &nbsp;</p>
                            <div style={{ float: "right", width: "20%" }}><hr /></div>
                        </div>

                        <div className='signup-with-btns'>
                            <button>Facebook</button>
                            <button>Google</button>
                        </div>

                        <p className='login-with-text2'>Already have an account? <Link className='my-signup-login-txt' to="/login" > Log in.</Link></p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SignUp