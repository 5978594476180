import React, { useContext, useState,useEffect } from 'react'
import { HtmlTextContext } from './Context/HtmlTextContext'
import { MyMobileContext } from './Context/MyMobileContext'
import DOMPurify from 'dompurify';

function HtmlPhoneScreen() {
    const { textValue, setTextValue } = useContext(HtmlTextContext)
        const {buttonActive,bodySize} = useContext(MyMobileContext)
        let [myRadious,setMyRadious] = useState(null)
console.log(textValue);

        const sanitizedHTML = DOMPurify.sanitize(textValue, {
            USE_PROFILES: { html: true },
            ADD_TAGS: ['span', 'li', 'ol', 'ul', 'p'],
            ADD_ATTR: ['style', 'class']
});
        useEffect(()=>{
    
    if(buttonActive=="vector1"){
        setMyRadious("0px")
    
    }else if(buttonActive=="vector2"){
        setMyRadious("5px"
    )}
    else if(buttonActive=="vector3"){
        
        setMyRadious("20px")
    
    }else if(buttonActive=="vector4"){
        setMyRadious("50px")
    }
    
        },[buttonActive])
    return (
        <>
            <div style={{borderRadius:`${myRadious}`,margin:bodySize=="with margin"?"10px 25px":"10px"}} className='facebook-phone-conten'>
                <div className='Html-home-screen-content' style={{ padding: "10px", width: "100%", height: "100%", overflowY: "scroll",overflowWrap: "anywhere", paddingRight: "32px", boxSizing: "content-box"}}>
                    {/* <div style={{backgroundColor:"#fff",boxSizing:"content-box",borderRadius:"10px",padding:"25px 10px",margin:"40px 10px"}}>  */}
                    <p  dangerouslySetInnerHTML={{ __html: sanitizedHTML }}/>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}

export default HtmlPhoneScreen
