import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { BsFacebook } from "react-icons/bs";
import { BsX } from 'react-icons/bs';
import { MdOutlineMenuOpen } from "react-icons/md";
// import{all} from 'react-icons/fa'
import { MyMobileContext } from './Context/MyMobileContext';
import { SidebarMenuContext } from './Context/SidebarMenuContext';

function Facebook() {
    let { myPagies, setMyPagies,allMyPagies, setAllMyPagies,icons } = useContext(SidebarMenuContext)
    let { setMobileValue,DropdownVisibleFor, setDropdownVisibleFor,setDisplayForSinglePhoneScreen,displayForSinglePhoneScreen } = useContext(MyMobileContext)
    useEffect(() => {
      let value = displayForSinglePhoneScreen.find((item)=>item.id=="hkiw86g87t32fr575")                
      setMobileValue(value.text)
    }, [displayForSinglePhoneScreen])
    const updateText = (id, text,secondId) => { 
      setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item))); 
      setDisplayForSinglePhoneScreen(prev =>prev.map((item)=>item.id===secondId?{...item,text:text}:item))
      setMobileValue(text)
  };

    const targetItem = myPagies.find(item => item.id === "vbfo4iuy449");
    const targetText = targetItem ? targetItem.text : "";
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    
     const iconChange = (iconObj) =>{
      setSelectedIcon(iconObj.icon);
      setDropdownVisible(false);
      setMyPagies(prev=>prev.map((item)=>item.id=="vbfo4iuy449"?{...item,icon:iconObj.icon}:item))
      setAllMyPagies(prev=>prev.map((item)=>item.id=="vbfo4iuy449"?{...item,icon:iconObj.icon}:item))
     }


// useEffect(()=>{
//     setMyPagies(prev=>prev.map((item)=>item.id=="vbfo4iuy449"?{...item,icon:selectedIcon}:item))
// },[selectedIcon])

    // console.log(DropdownVisibleFor);
    

    
      

    return (
        <>
            <div>
                <div className='i2u'>
                    {/* <div className='my-radio-background-part'>
                        <p>Background</p>
                        <div className='my-radio-background-part1-buttons'>
                            <NavLink to="" className={(navData) => navData.isActive ? `Active-bg` : "Not-Active-bg"}><div>Content</div></NavLink>
                            <NavLink to="" className={(navData) => navData.isActive ? `Active-bg` : "Not-Active-bg"}> <div>Design</div></NavLink>
                        </div>
                    </div> */}
                    <hr style={{ margin: "20px 0px" }} />
                    <div className='my-radio-facebook-icon'>
                        <p>Icon and page name</p>
                        <div className='my-radio-icon-and-page-facebook'>
<div onClick={() => {setDropdownVisible(!isDropdownVisible)}}>

{/* {selectedIcon?selectedIcon:myPagies.find(((item)=>item.id=="vbfo4iuy449")).icon} */}
{selectedIcon ||
    myPagies.find(item => item.id === "vbfo4iuy449")?.icon ||
    allMyPagies.find(item => item.id === "vbfo4iuy449")?.icon}
</div>
{isDropdownVisible && (
  <div style={{ position: 'relative' }}>
    <div
      style={{
        height: "150px",
        width: "305px",
        color: "black",
        padding: "25px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        background: "#fff",
        overflowY: "auto",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        position: "absolute",
        zIndex: 1,
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: "10px",
      }}
    >
      <div style={{ position: 'fixed', cursor: 'pointer',marginLeft:"290px" }} onClick={() => setDropdownVisible(false)}>
        <BsX fontSize="20px"/>
      </div>
      {icons.map((iconObj) => (
        <div
          key={iconObj.id}
          onClick={() => iconChange(iconObj)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            cursor: "pointer",
            borderRadius: "5px",
            background: "#f9f9f9",
          }}
        >
          {iconObj.icon}
          <span style={{ fontSize: "12px", marginTop: "5px" }}>{iconObj.name}</span>
        </div>
      ))}
    </div>
  </div>
)}



                            <input type='text' value={targetText} maxLength={45} onChange={(e) => {updateText("vbfo4iuy449", e.target.value,"hkiw86g87t32fr575");}} />
                        </div>
                    </div>
                    <hr />
                    <div className='my-radio-facebook-link'>
                        <p>Website link</p>
                        <input type="url" name="facebook-url" id="facebook-url" />
                    </div>
                </div>
                {/* <div className='mobile'>
                    <div className='temps'>
                        <div className='temp1'>
                            <div className='main-temp'>
                                <div className='phone-screen-settings123'>
                                    <div className='facebook-phone-nav'>
                                        <MdOutlineMenuOpen style={{ marginRight: "10px", fontSize: "28px" }} />
                                        <p>Facebook</p>
                                    </div>
                                    <hr style={{ width: "99%", color: "#715F83" }} />
                                    <div className='facebook-phone-content'>
                                        <p>The preview is not available on the simulator. But don’t worry, the website will display fine on your mobile.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default Facebook