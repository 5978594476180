import React, { useState, useContext, useEffect } from 'react'
import { FaRadio } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { PiImagesSquareThin } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import { LuMessageSquarePlus } from "react-icons/lu";
import { IoGlobeOutline } from "react-icons/io5";
import { MdRssFeed } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import libraryMusic from '../images/library_music.png'
import playIcon from '../images/play icon.png'
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { NavLink } from 'react-router-dom';
import { LuGripVertical } from "react-icons/lu";
import MyRadioMain from './MyRadioMain';
import { MyMobileContext } from './Context/MyMobileContext';




function Menu() {
  let {setToggleClass} = useContext(MyMobileContext)
  const { myPagies, setMyPagies } = useContext(SidebarMenuContext)
  const updateText = (id, text) => (setMyPagies(prev => prev.map((item) => item.id == id ? { ...item, text: text } : item)))


  const handleDragStart = (e, index) => { e.dataTransfer.setData('dragIndex', index); console.log('Drag Start:', index);};
  const handleDragOver = (e) => { e.preventDefault();console.log('Drag Over'); };
  const handleDrop = (e, dropIndex) => {
  // const dragIndex = parseInt(e.dataTransfer.getData('dragIndex'), 10);
  e.preventDefault(); 
  const dragIndex = (e.dataTransfer.getData('dragIndex'));
  console.log('Drop:', { dragIndex, dropIndex });
  
  if (dragIndex === dropIndex) return;
  const reorderedItems = [...myPagies];
  const [draggedItem] = reorderedItems.splice(dragIndex, 1);
  reorderedItems.splice(dropIndex, 0, draggedItem); 
  setMyPagies(reorderedItems);
  };

  useEffect(()=>{
 
      setToggleClass(true)
      return () =>{
          setToggleClass(false)
      };

  },[])


  return (
    <>
      <div>
        <div className='Apps'><p>Pages</p></div>
        <div>
          <div className='Pages-div'>
            {myPagies.map((item, index) => (
              <p className='' key={index} > <NavLink to="#" className={(navData) => navData.isActive ? "menu-nav-link" : "nav-link"} >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }} draggable onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)}>
                  {item.iconSame}
                  {item.icon}
                  <input id={`input-${item.id}`} type="text" value={item.text} autofocus onChange={(e) => updateText(item.id, e.target.value)} style={{ fontSize: "16px", lineHeight: "20px", fontWeight: "500", border: "none", cursor: "pointer", width: "80%", padding:"12px 15px" }} />
                </div>
                <div>
                </div>
              </NavLink>
              </p>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}

export default Menu