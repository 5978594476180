import React from 'react'
import Navbar from '../Navbar'
import Hero from '../Hero'
import Footer from '../Footer'

function Landing() {
  return (
    <>
    <Navbar/>
    <Hero/>
    <Footer/>
    </>
  )
}

export default Landing