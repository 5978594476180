import React, { useContext, useState } from 'react'
import backgroundDancingImg from '../images/background-dancing-img.png'
import { MyMobileContext } from './Context/MyMobileContext'
import { useNavigate } from 'react-router-dom'

function OrderSummary() {
    const {buttonValue,setButtonValue,appName,allApps,setAllApps} = useContext(MyMobileContext)
    const uniqueId = `id-${new Date().getTime()}`;
    console.log(allApps);
    
    
    const navigate = useNavigate()
  return (
    <>
    <div className='order-summary-main-not-for-dasktop-screen-125'>
     <div className='order-summary-main-heading'>
        <h3>Order Summary</h3>
     </div>
     <div className='order-summary-second-div'>
        <div className='order-summary-second-div-inner'>
        <div className='order-summary-all-app-and-pricing-information'>
            <p>App Name</p>
            <p>Price</p>
        </div>
        <div className='order-summary-app-and-pricing-information'>
            <input type="text" name="" id="" readOnly value={appName} />
            <input type="text" name="" id="" readOnly value={buttonValue} />
        </div>
        <div className='order-summary-app-and-pricing-information'>
            <input type="text" name="" id="" />
            <input type="number" name="" id="" />
        </div>
        <div className='order-summary-app-and-pricing-information'>
            <input type="text" name="" id="" />
            <input type="number" name="" id="" />
        </div>
        <div className='order-summary-total-price-info'>
            <p></p>
            {/* <p onClick={()=> {!buttonValue?alert("first select your app name"): setAllApps(prev=>([...prev,Value == appName]?[...prev]:[...prev,{id:uniqueId+appName+buttonValue,Value:appName,price:buttonValue}])); navigate("/radioik/myapps")}}>Total Price<span> - {buttonValue}</span></p> */}
            <p onClick={() => {
    if (!buttonValue) {
        alert("First select your app name");
    } else {
        setAllApps(prev => 
            prev.some(app => app.Value === appName)
                ? prev
                : [...prev, { id: uniqueId + appName + buttonValue, Value: appName, price: buttonValue }]
        );
        navigate("/radioik/myapps");
    }
}}>Total Price<span> - {buttonValue}</span></p>
        </div>
        </div>
     </div>
     <div className='order-summary-continue-and-open-app'>
        <div className='order-summary-inner-continue-and-open-app'>
        <div className='order-summary-continue-shopping-div'>
            <hr />
            <button onClick={()=>navigate("/orderlist")}>Continue Shopping</button>
        </div>
        <div className='order-summary-open-app-div'>
            <hr />
            <button onClick={()=>navigate("/orderlist")}>Open App</button>
        </div>
        </div>
        <div className='order-summary-background-image'> </div>
     </div>
     
    </div>
 
    <div className='pricing-plan-about-radioik-app'>
   <div><p>About Radioik App</p></div>
 </div>
    
    </>
  )
}

export default OrderSummary