import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'
import { MdOutlineMenuOpen } from "react-icons/md";

function WebsitePageLayout() {
   
    return (
        <>
    <div style={{width:"35%"}}>
        <div className='my-radio-content-starts-here1'>
        <div className='setting-45'>
            <MyRadioMain RadioLink1={"/radio/website"} RadioLink2={"/radio/website/design"}/>
            <Outlet />
        </div>
        </div>
    </div>
</>
)
}

export default WebsitePageLayout