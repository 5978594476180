import React,{useState} from 'react'
import { FileUploader } from 'react-drag-drop-files'

function Publish() {
      const [urls, setUrls] = useState();
      const fileTypes = ["JPG", "PNG", "GIF"];
  
      const [file, setFile] = useState(null);
      const handleChange = (file) => {
          setUrls(file);
      };
  return (
    <>
    <div className='publish-page-main-div'>
        <div className='publish-page-inner-main-div1'>
          <h2>Congratulations, you are almost there...</h2>
          <h4>Publish image collection</h4>
          <p>Images no more than 10MB</p>
          <p>Supported image formats jpg & png</p>
        </div>


<div className='publish-page-inner-main-div2'>
       <div className='publish-page-inner-main-div2-main1'>
       <div className='publish-page-whole-content1'>
          <div>
          <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
          </div>
          <div>
            <h4 className='publish-page-whole-content1-heading'>App Icon</h4>
            <p className='publish-page-whole-content1-para'>Technical requirements <br /> Recommended 1024px X 1024px</p>
          </div>
        </div>

        <div className='publish-page-whole-content2'>
          <div>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
          </div>
          <div>
            <h4 className='publish-page-whole-content2-heading'>Smartphone- large screen</h4>
            <p className='publish-page-whole-content2-para1'>The image corresponds to the display at the launch of the app on phones with a larger screen.</p>
            <p className='publish-page-whole-content2-para2'>Technical requirements <br /> Recommended 1024px X 1024px</p>
          </div>
        </div>
       </div>

       <hr />

        <div className='publish-page-inner-main-div2-main2'>
          <div className='publish-page-whole-content3'>
          <div>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
          </div>
          <div>
            <h4 className='publish-page-whole-content3-heading'>Smartphone- large screen</h4>
            <p className='publish-page-whole-content3-para1'>The image corresponds to the display at the launch of the app on phones with a larger screen.</p>
            <p className='publish-page-whole-content3-para2'>Technical requirements <br /> Recommended 1024px X 1024px</p>
          </div>
          </div>
        </div>
        </div>
        <div style={{height:"15px"}}></div>
    </div>



    <div className='pricing-plan-about-radioik-app'>
  <div><p>About Radioik App</p></div>
</div>
    </>
  )
}

export default Publish