import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'
import { MdRssFeed } from "react-icons/md";

function RSSLayout() {
    return (
        <>
    <div style={{ width:"35%"}}>
        <div className='my-radio-content-starts-here1'>
        <div className='setting-45'>
            <MyRadioMain RadioLink1={"/radio/RSS"} RadioLink2={"/radio/RSS/design"}/>
            <Outlet />
        </div>
        </div>
    </div>
</>
    )
}

export default RSSLayout