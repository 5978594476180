import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function BlogDesign() {
  let { setMobileValue,myBlogDesignColorId,setMyBlogDesignColorId,myBlogDesignColor,setMyBlogDesignColor,displayForSinglePhoneScreen } = useContext(MyMobileContext)
  useEffect(() => {
    let value = displayForSinglePhoneScreen.find((item)=>item.id=="hwgtyu57666666666")                
    setMobileValue(value.text)
  }, [])
  const textColors = [
    { id: "vuipppo19907873y1", classIs: "text-colors-yellow", color: "#ff9150" },
    { id: "uyuippp2o9907873y2", classIs: "text-colors-green", color: "#ff4757" },
    { id: "uyuippp3o9907873y3", classIs: "text-colors-lightgreen", color: "#2ed573" },
    { id: "uippp4o9907873y4", classIs: "text-colors-skyblue", color: "#1e90ff" },
    { id: "uuippp5o9907873y5", classIs: "text-colors-lightblue", color: "#ffc0cb" },
    // { classIs: "text-colors-blue", color: "#504DCE" },
    { id: "uguipppo69907873y6", classIs: "text-colors-pink", color: "#CB4DCE" },
    { id: "vuyuippp7o9907873y7", classIs: "text-colors-black", color: "#000000" },
    // { id: "vuuippp8o99078738", classIs: "text-colors-white", color: "none" },
  ]

  const myBlogButtonColor = (id, color) => {

    setMyBlogDesignColorId(id);
    setMyBlogDesignColor(color);
  }


  return (
    <>
      <div className='text-clr'>
        <p style={{ color: "#fff" }}>Text Color</p>
        <div className='text-colors'>
          <div className='text-hexa-colors'>
            <input className={"wijqd2h7328434t54yhgh" === myBlogDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myBlogButtonColor("rt547ru785rt3454", (e.target.value)); setMyBlogDesignColorId("wijqd2h7328434t54yhgh"); }} />

            {textColors.map((item) => (<div key={item.id} className={item.id === myBlogDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myBlogButtonColor(item.id, item.color) }}></div>))}
          </div>
          <div className='Hexa-text'>
            <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myBlogDesignColor} onChange={(e) => { setMyBlogDesignColor(`#${e.target.value.replace('#', '')}`); setMyBlogDesignColorId("wijqd2h7328434t54yhgh"); }} />
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogDesign