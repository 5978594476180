import React, { useContext, useEffect, useState } from 'react'
import { MyMobileContext } from './Context/MyMobileContext';

function HTMLDesign() {
    let { setMobileValue,myHTMLDesignColorId,setMyHTMLDesignColorId,myHTMLDesignColor,setMyHTMLDesignColor,displayForSinglePhoneScreen } = useContext(MyMobileContext)
             useEffect(()=>{
                let value = displayForSinglePhoneScreen.find((item)=>item.id=="iuoweg")                
            setMobileValue(value.text)
             },[])

    const textColors = [
        { id: "vuipppoo99y7yyyuiwe873y10", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "uyuipppoo99y7yyyuiwe873y20", classIs: "text-colors-green", color: "#ff4757" },
        { id: "uyuipppoo99y7yyyuiwe873y30", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "uipppoo99y7yyyuiwe873y40", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "uuipppoo99y7yyyuiwe873y50", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "uguipppoo99y7yyyuiwe873y60", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "vuyuipppoo99y7yyyuiwe873y70", classIs: "text-colors-black", color: "#000000" },
        // { id: "vuuipppoo99y7yyyuiwe87380", classIs: "text-colors-white", color: "none" },
    ]

    const myHTMLButtonColor = (id, color) => {

        setMyHTMLDesignColorId(id);
        setMyHTMLDesignColor(color);
    }

    return (
        <>
            <div className='text-clr'>
                <p style={{ color: "#fff" }}>Text Color</p>
                <div className='text-colors'>
                    <div className='text-hexa-colors'>
                        <input className={"asadafagahajakalaazaxacavabanam" === myHTMLDesignColorId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { myHTMLButtonColor("rt547ru785rt3454", (e.target.value)); setMyHTMLDesignColorId("asadafagahajakalaazaxacavabanam"); }} />

                        {textColors.map((item) => (<div key={item.id} className={item.id === myHTMLDesignColorId ? "border-for-active" : item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { myHTMLButtonColor(item.id, item.color) }}></div>))}
                    </div>
                    <div className='Hexa-text'>
                        <label style={{ color: "#fff" }} htmlFor="Hexa-text-here">Hexa</label>
                        <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myHTMLDesignColor} onChange={(e) => { setMyHTMLDesignColor(`#${e.target.value.replace('#', '')}`); setMyHTMLDesignColorId("asadafagahajakalaazaxacavabanam"); }} />
                    </div>
                </div>
            </div>
        </>
    )

}

export default HTMLDesign