import React, { useContext, useEffect, useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { MyMobileContext } from './Context/MyMobileContext';
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { BsX } from 'react-icons/bs';


function MyRadio() {

    let {myPagies,setMyPagies,icons} = useContext(SidebarMenuContext)
    let {mobileValue,setMobileValue,displayForSinglePhoneScreen,setDisplayForSinglePhoneScreen,allOptions,setAllOptions,preview,setPreview} = useContext(MyMobileContext);
    // console.log(preview);
    
    const [urls, setUrls] = useState();
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader(); 
        reader.onloadend = () => { 
            setPreview(reader.result);
         }; if (file) { reader.readAsDataURL(file); }
        
    };


 useEffect(()=>{
   displayForSinglePhoneScreen.map((item)=>(item.id==="wf765fx76ui897"?setMobileValue(item.text):""))
 });

const textChange = (e) =>{
    let value = e.target.value;
    setMobileValue(value);
    setDisplayForSinglePhoneScreen(prev => prev.map(item => (item.id === "wf765fx76ui897" ? { ...item, text: value } : item)));
}

const updateText = (id, text) => { setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item))); };

const targetItem = myPagies.find(item => item.id === "jhi9ourftru"); 
const targetText = targetItem ? targetItem.text : "";



const optionChange = (e) =>{
    let Name = e.target.name;
    let isChecked = e.target.checked;
    setAllOptions(prev => ({...prev , [Name] : isChecked}))
}

  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

    return (
        <>
            
                <div className='i2u'>
                    <div className='my-radio-icon-and-page-part'>
                        <p>Icon and page name</p>
                        <div className='my-radio-icon-and-page-part1'>
                            <div onClick={() => { setDropdownVisible(!isDropdownVisible) }}>
                                          {/* {selectedIcon ? selectedIcon : myPagies.find(((item) => item.id == "bjeuiuo3uh")).icon || allMyPagies.find((item)=>item.id == "bjeuiuo3uh").icon} */}
                                          {selectedIcon ||
                                myPagies.find(item => item.id === "jhi9ourftru")?.icon}
                                          </div>
                                        {isDropdownVisible && (
                                          <div style={{  }}>
                                            <div
                                              style={{
                                                height: "150px",
                                                width: "305px",
                                                color: "black",
                                                padding: "25px",
                                                border: "1px solid #ccc",
                                                borderRadius: "5px",
                                                background: "#fff",
                                                overflowY: "auto",
                                                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                                                position: "absolute",
                                                zIndex: 1,
                                                display: "grid",
                                                gridTemplateColumns: "repeat(3, 1fr)",
                                                gap: "10px",
                                              }}
                                            >
                                              <div style={{ position: 'fixed', cursor: 'pointer', marginLeft: "290px" }} onClick={() => setDropdownVisible(false)}>
                                                <BsX fontSize="20px" />
                                              </div>
                                              {icons.map((iconObj) => (
                                                <div
                                                  key={iconObj.id}
                                                  onClick={() => {
                                                    setSelectedIcon(iconObj.icon);
                                                    setDropdownVisible(false);
                                                    setMyPagies(prev => prev.map((item) => item.id == "jhi9ourftru" ? { ...item, icon: iconObj.icon } : item))
                                                  }}
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px",
                                                    cursor: "pointer",
                                                    borderRadius: "5px",
                                                    background: "#f9f9f9",
                                                    color:"black"
                                                  }}
                                                >
                                                  {iconObj.icon}
                                                  <span style={{ fontSize: "12px", marginTop: "5px" }}>{iconObj.name}</span>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                            <input type='text' maxLength={45} value={targetText} onChange={(e)=>updateText("jhi9ourftru",e.target.value)}/>
                        </div>
                    </div>
                    <hr />
                    <div className='my-radio-radio-stream-part'>
                        <p>Radio Stream</p>
                        <div className='my-radio-stream-part1'>
                            <label htmlFor="stream-name">Stream name</label>
                            <input type="text" name="steam-name" maxLength={45} id="stream-name" value={mobileValue} onChange={textChange} />

                            <label htmlFor="stream-type">Stream type</label>
                            <select name="stream-type" id="stream-type">
                                <option value="radioik">Radioik</option>
                                <option value="Shoutcast-v1">Shoutcast</option>
                                <option value="Icecast">Icecast</option>
                                {/* <option value="others">Others</option> */}
                            </select>

                            <label htmlFor="stream-url">Stream URL</label>
                            <input type="url" name="stream-url" id="stream-url" />

                            <label htmlFor="stream-logo">Stream Logo</label>
                            {!preview && <FileUploader handleChange={handleChange} name="file" types={fileTypes} />}
                                {preview &&  <div style={{width:"240px",height:"240px"}} className='my-radio-stream-logo-image-visible'><img className='menu-design-menu-image' src={preview} alt={urls}  style={{width:"inherit",height:"inherit"}} /> <button className='btn-mnu-chg' onClick={()=> setPreview(null)
                        }>Change image</button></div> }
                            <div className='my-radio-stream-part2'>
                                {/* <img src={urls} alt="preview"  style={{margin:"50px 0px",height:"200px"}}/> */}
                                <p>Recomended size <br /> Max Size</p>
                                <p>552 X 240px <br /> 1Cmo</p>
                            </div>

                            <button>+ Add Stream</button>
                        </div>
                    </div>

                    <div className='My-radio-buttons-part'>
                        <p>Buttons</p>
                        <div className='My-radio-buttons-part1'>
                            <div className='My-radio-buttons-part2'><label htmlFor="call">Call</label>
                                <label className="switch">
                                    <input type="checkbox" name='CallOption' id='CallOption' checked={allOptions.CallOption} onChange={optionChange} />
                                    <span className="slider round"></span>
                                </label></div>

                            <div className='My-radio-buttons-part2'><label htmlFor="sms">SMS</label>
                                <label className="switch">
                                    <input type="checkbox" name='SMSOption' id='SMSOption' checked={allOptions.SMSOption} onChange={optionChange} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                            <input type="number" name="sms" id="sms" className='my-radio-sms' />

                            <div className='My-radio-buttons-part2'><label htmlFor="share">Share</label>
                                <label className="switch">
                                    <input type="checkbox" name='ShareOption' id='ShareOption' checked={allOptions.ShareOption} onChange={optionChange}  />
                                    <span className="slider round"></span>
                                </label></div>

                            <div className='My-radio-buttons-part2'><label htmlFor="email">Email</label>
                                <label className="switch">
                                    <input type="checkbox" name='EmailOption' id='EmailOption' checked={allOptions.EmailOption} onChange={optionChange} />
                                    <span className="slider round"></span>
                                </label></div>

                            <div className='My-radio-buttons-part2'><label htmlFor="apple-music">Buy Apple Music</label>
                                <label className="switch">
                                    <input type="checkbox" name='appleMusicOption' id='appleMusicOption' checked={allOptions.appleMusicOption} onChange={optionChange} />
                                    <span className="slider round"></span>
                                </label>

                            </div>
                            <input type="text" name="apple-music" id="apple-music" placeholder='Your Apple Music Affiliate ID' className='my-radio-apply-music' />

                        </div>
                    </div>

                    <div className='My-radio-other-actions-part'>
                        <p className='My-radio-other-actions-part-text'>Other Actions</p>
                        <div className='My-radio-other-actions-part1'>
                            <div>
                                <div className='My-radio-other-actions-part2'>
                                    <label htmlFor="vote">Vote</label>
                                    <label className="switch">
                                        <input type="checkbox" name='VoteOption' id='VoteOption' checked={allOptions.VoteOption} onChange={optionChange} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <p className='My-radio-other-action-paras'>Votes allow your listeners to tell you when they like
                                    your music.</p>

                            
                            <textarea name="features-for-radioik" className='features-for-radioik' placeholder='This feature is only available by radios hosted by Radioik'></textarea>


                            </div>
                            <div className='My-radio-other-actions-part3'>
                                <label htmlFor="sleep-timer">Sleep timer</label>
                                <label className="switch">
                                    <input type="checkbox" name='SleepOption' id='SleepOption' checked={allOptions.SleepOption} onChange={optionChange} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                
                
           

        </>
    )
}

export default MyRadio