import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { MyMobileContext } from './Context/MyMobileContext';


function PricingPlan() {

  const [planId, setPlanId] = useState(null);
  const { buttonValue, setButtonValue } = useContext(MyMobileContext)
  const navigate = useNavigate();




  const pricingPlan = [
    {
      id: "2jh3oidh",
      planeName: "BASIC PLAN",
      PlanPrice: "$29",
      planButton: "Choose this plan.",
      thingsProvided: ["App native Android & iPhone", "100% customizable design",
        "UNLIMITED tabs", "UNLIMITED app downloads",
        "Multi stream Radio", "Multiple languages",
        "App publication by Radioik [optional]"]
    },
    {
      id: "t3635786",
      planeName: "PREMIUM PLAN",
      PlanPrice: "$99",
      planButton: "Choose this plan.",
      thingsProvided: ["App native Android & iPhone",
        "Android Auto & Apple CarPlay", "Android TV & Apple TV",
        "Apple Watch", "100% customizable design",
        "UNLIMITED tabs", "UNLIMITED app downloads",
        "Multi stream Radio", "Multiple languages",
        "App publication by Radioik INCLUDED",
        "100,000 push notifications", "White label"]
    },
    {
      id: "jo03973h",
      planeName: "SMART PLAN",
      PlanPrice: "$69",
      planButton: "Choose this plan.",
      thingsProvided: ["App native Android & iPhone",
        "100% customizable design",
        "UNLIMITED tabs", "UNLIMITED app downloads",
        "Multi stream Radio", "Multiple languages",
        "App publication by Radioik [optional]",
        "50,000 push notifications", "White label"]
    },
  ]

  const selectedPlan = pricingPlan.find((item) => item.id === planId)
  return (
    <>
      <div className='Pricing-plan-main-div'>
        <h3>Choose my offer</h3>
        <p>Choose the offer you need,</p>
        <div className='Pricing-plan-main-smart-phone-div'>
          {pricingPlan.map((item) => (
            <div className='mobile' key={item.id}>
              <div className='temps'>
                <div className='temp1'>
                  <div className='main-temp'>
                    <div className='phone-screen-settings8732489'>
                      <div className='Pricing-plan-mobile-speak'></div>
                      <p className='pricing-plan-type'>{item.planeName}</p>
                      <p className='pricing-plan-per-month'><span className='pricing-plan-span'>{item.PlanPrice}</span>/month</p>
                      <button onClick={() => {
                        setPlanId(item.id);
                        console.log(buttonValue, "priceplan before");
                        setButtonValue(prev => {
                          const prevPrice = parseFloat(prev.replace('$', ''));
                          const itemPrice = parseFloat(item.PlanPrice.replace('$', ''));
                          const totalPrice = prevPrice + itemPrice;
                          console.log(`$${totalPrice.toFixed(2)}`, "priceplan after");
                          return `$${totalPrice.toFixed(2)}`;
                        });
                      }} className='pricing-plan-button'>{item.planButton}</button>

                      <ul className='pricing-plan-unorder-list'>
                        {item.thingsProvided.map((things) => (
                          <li className='pricing-plan-lists'>{things}</li>
                        ))}
                      </ul>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>{selectedPlan ? <div>
          {alert(`You Have selected ${selectedPlan.planeName}`)}
          {navigate("/radioik/publication")}

        </div> :
          <div></div>}</div>

        <p className='pricing-page-right-logo'>RADIOIK</p>
        <div className='pricing-plan-about-radioik-app'>
          <div><p>About Radioik App</p></div>
        </div>
      </div>
    </>
  )
}

export default PricingPlan