import React from 'react'
import MainNavbar from '../MainNavbar'
import Footer from '../Footer'
import { Outlet } from 'react-router-dom'
import MainNavbar2 from '../MainNavbar2'
import Sidebar from '../Sidebar'
import MyPhone from '../MyPhone'


function ManuLayout() {
  return (
    <>
      <MainNavbar />
      <MainNavbar2 />
      <div style={{ display: "flex" }} className='menu-layout-div'>
        <Sidebar />
        <div className='div-width70' style={{ display: "flex", justifyContent: "space-around", paddingTop: "60px" }}>
          <Outlet />
          <MyPhone />
        </div>
      </div>
      {/* <Footer/> */}
    </>
  )
}

export default ManuLayout