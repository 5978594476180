import React,{useContext} from 'react'
import { MyMobileContext } from './Context/MyMobileContext'

function WebsitePhoneScreen() {
  let {textAndfont,mywbsiteDesignColor } = useContext(MyMobileContext)
  return (
    <>
    <div class="My-radio-main-phone-div">
        <p style={{fontSize:`${textAndfont}`,margin:"0px 12px",height:"inherit",display:"Flex",color:mywbsiteDesignColor,justifyContent:"center",alignItems:"center"}}>
            The preview is not available on the simulator. But don’t worry, the website will display fine on your mobile.</p>
        </div>
    </>
  )
}

export default WebsitePhoneScreen