import React from 'react'
import { CiHeadphones } from "react-icons/ci";
import { CiMobile3 } from "react-icons/ci";
import { TbCaptureFilled } from "react-icons/tb";
import { MdAirplay } from "react-icons/md";

function BuyNewServices() {

    const buyNowData = [
        { id: "fg2376r", logo: <CiHeadphones style={{ fontSize: "18px" }} />, heading: "Radio", listValue:["Radio Discover", "Radio Start", "Radio Pro", "Radio Business"] },
        { id: "huo8334", logo: <CiMobile3 style={{ fontSize: "18px" }} />, heading: "Mobile Application", listValue:["Mob app", "Mob app", "Mob app", "Mob app"] },
        { id: "huf3ioy", logo: <TbCaptureFilled style={{ fontSize: "18px" }} />, heading: "Radio Website", listValue:["Radio Web", "Radio Web", "Radio Web", "Radio Web"] },
        { id: "jhoi40", logo: <MdAirplay style={{ fontSize: "18px" }} />, heading: "Video app", listValue:["Video App", "Video App", "Video App", "Video App"] },

    ]

    return (
        <>
            <div className='buy-new-services-main-div'>

                <div className='buy-new-services-inner-main-div'>
                    <h3>Buy New Services</h3>
                    <div className='buy-new-services-card-main-div'>
                        {buyNowData.map((item) => (
                            <div className='buy-new-services-inner-card-main-div' key={item.id}>
                                <div className='buy-new-services-card-strap'>{item.logo} <h5> {item.heading}</h5></div>

                                <ul className='buy-new-services-unordered-list'>
                                    {item.listValue.map((values) => (
                                        <li key={values}>
                                            <p>{values}</p>
                                            <p>$19.00</p>
                                            <button>Order</button>
                                        </li>
                                    ))}

                                </ul>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <div className='pricing-plan-about-radioik-app'>
                <div><p>About Radioik App</p></div>
            </div>

        </>
    )
}

export default BuyNewServices