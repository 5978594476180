import React from 'react'
import { TbBellPlusFilled } from "react-icons/tb";
import { FaQuestion } from "react-icons/fa6";

function MainNavbar() {
  return (
    <>
        <div className='main-navbar-all'>
        <div className='main-navbar-first'>
            <div className='navbar-first1'>
                <h2>Radioik</h2>
            </div>
            <div className='theme-nev-options'>
                <p>See all offers</p>
                <FaQuestion/>
                <TbBellPlusFilled />
            </div>
            
        </div>
        <div className='main-navbar-second'><p>Hello user......</p></div>
        </div>
    </>
  )
}

export default MainNavbar