import React, { useContext, useEffect, useState, useRef } from 'react'
import { MdOutlineMenuOpen } from "react-icons/md";
import { MyMobileContext } from './Context/MyMobileContext';
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { NavLink } from 'react-router-dom';
import { ImGift } from 'react-icons/im';


function MyPhone() {
    let { mobileValue, preview, displayForSinglePhoneScreen, sideBarToggleRef, toggleClass, setToggleClass, GeneralTextRef, MyBGColorChgRef, textAndfont, myMenuBGColor, myMenuTextColor, menuPreviews, showLogoBorder, bodySize,dark } = useContext(MyMobileContext);
    // console.log("value",mobileValue);
    const { myPagies } = useContext(SidebarMenuContext)


    const showPhoneNavSection = () => {
        setToggleClass(true)
    }
    // console.log(toggleClass);



    useEffect(() => {
        if (toggleClass) {
            const handleClick = (e) => {
                if (sideBarToggleRef.current && sideBarToggleRef.current.contains(e.target)) {
                    // console.log("clicked on sidebartoggleRef");
                    setToggleClass(false);
                }
            };
    
            document.addEventListener("click", handleClick);
    
            // Cleanup on unmount
            return () => {
                document.removeEventListener("click", handleClick);
            };
        }
    }, [toggleClass, sideBarToggleRef]);

    let item = displayForSinglePhoneScreen.find((item) => item.id == "wf765fx76ui897")
    let radio = item.text;


    return (
        <>
            <div className='mobile'>
                <div className='temps'>
                    <div className='temp1'>
                        <div ref={MyBGColorChgRef} className='main-temp' style={{ backgroundColor: "#9682aa", fontFamily: "monospace" }}>
                            <div className='phone-screen-settings123'>
                                <div className='facebook-phone-nav'>
                                    <MdOutlineMenuOpen style={{ marginRight: "10px", fontSize: "28px", paddingLeft: bodySize == "with margin" ? "15px" : "0px" }} onClick={showPhoneNavSection} />


                                    {(radio == mobileValue && preview) ? <div className=" my-previews-image-my-phone" style={{ backgroundImage: `URL(${preview})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div> : <p style={{ fontSize: `${textAndfont}` }} ref={GeneralTextRef}>{mobileValue.length > 12 ? `${mobileValue.slice(0, 12)}..` : mobileValue}</p>}

                                </div>
                                <hr style={{ width: "99%", color: "#715F83" }} />
                                {displayForSinglePhoneScreen.map((item) => item.text === mobileValue ? item.OtherContent : "")}




                                <div ref={sideBarToggleRef} className={toggleClass ? "SidebarPhoneToggleBtn SidebarPhoneToggleBtnIsActive" : 'SidebarPhoneToggleBtn SidebarPhonetoggleBtnMain'}>
                                    <div onClick={(e) => e.stopPropagation()} style={{ backgroundColor: `${myMenuBGColor}`, width: "85%", borderRadius: "12px 0px 0px 14px", padding: "10px 10px 10px" }}>
                                        <div style={{ height: "90px", backgroundColor: "transparent" }}>
                                            {showLogoBorder && menuPreviews && <img src={menuPreviews} alt='preview' height="100%" width="100%" />}
                                        </div> {/*set image in it if any one click show logo in design of manu bar*/}
                                        <div style={{ width: "100%", height: "494px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                            <div style={{ height: "480px", overflow: "hidden", overflowY: "auto",scrollbarWidth: "thin" }}>
                                                {myPagies.map((item) => (
                                                    <>
                                                        <p key={item.id} id={item.id} style={{margin:"0px"}} className='' > <NavLink to={item.sideBarLink}
                                                            // className={(navData) => navData.isActive ? "my-nav-link" : "nav-link"}
                                                            className="myPhoneNavLinks" style={{ textDecoration: "none", color: "black" }}
                                                        >
                                                            <div style={{ display: "flex", alignItems: "center", padding: "10px 0px" }}>
                                                                <div style={{ fontSize: `${textAndfont}`, color: `${myMenuTextColor}`, display: "flex", justifyContent: "center", alignItems: "center" }}>{dark?item.PhoneIcon:item.icon}</div>
                                                                <p style={{ fontSize: `${textAndfont}`, color: `${myMenuTextColor}`,margin:"0px" }}>{item.text}</p>

                                                            </div>
                                                            <div>
                                                            </div></NavLink></p> <hr />
                                                    </>
                                                ))}
                                            </div>
                                            <div style={{ backgroundColor: "#D9CFE4", textAlign: "center", padding: "10px", fontSize: "22px", lineHeight: "26px", fontWeight: "500" }}>About Radioik</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default MyPhone