import React from 'react'
import { IoMdArrowDropdown } from "react-icons/io";
import { FaEarthAmericas } from "react-icons/fa6";
import { Link, NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <div id="navbar">
      <h2>Radioik</h2>
      <ul className='nav-list-items' id="first-list">
      <NavLink to="#" className="list-property-link-nav-Property">
        <li className='list-propertys'>Products </li>
        {/* <div className='display-content-Property'>
            <table>
              <thead>
                <th> Create your radio</th>
                <th>Develop your audience</th>
              </thead>
              <tbody>
                <td>
                  <tr><NavLink to="#">Overview</NavLink></tr>
                  <tr><NavLink to="#">Media Library</NavLink></tr>
                  <tr><NavLink to="#">Schedule</NavLink></tr>
                  <tr><NavLink to="#">Share</NavLink></tr>
                  <tr><NavLink to="#">Live</NavLink></tr>
                  <tr><NavLink to="#">Statistics</NavLink></tr>
                </td>
                <td>
                  <tr><NavLink to="#">Mobile Application</NavLink></tr>
                  <tr><NavLink to="#">Website</NavLink></tr>
                  <tr><NavLink to="#">Smart Speaker Apps</NavLink></tr>
                </td>
              </tbody>
            </table>
          </div> */}
      </NavLink>
        <NavLink to="#" className="list-property-link-nav-solution">
          <li className='list-propertys'>Solutions </li>
          {/* <div className='display-content-solution'>
            <table>
              <thead>
                <th> Individuals & Associations</th>
                <th>Pro</th>
              </thead>
              <tbody>
                <td>
                  <tr><NavLink to="#">Individuals</NavLink></tr>
                  <tr><NavLink to="#">Online radio associations</NavLink></tr>
                  <tr><NavLink to="#">Education</NavLink></tr>
                </td>
                <td>
                  <tr><NavLink to="#">AM/FM Radio</NavLink></tr>
                  <tr><NavLink to="#">Brands and stores</NavLink></tr>
                  <tr><NavLink to="#">Marketing & Communication Agencies</NavLink></tr>
                  <tr><NavLink to="#">Event</NavLink></tr>
                </td>
              </tbody>
            </table>
          </div> */}
        </NavLink>
        {/* <NavLink to="radioik/pricingplan"  */}
        <NavLink to="#" 
        className="list-property-link-nav"><li className='list-propertys'>Pricing</li></NavLink>
        <NavLink to="#" className="list-property-link-nav-resources">
          <li className='list-propertys'>Resources <IoMdArrowDropdown /></li>
          {/* <div className='display-content-resources'>
            <table>
              <thead>
                <th>Start</th>
                <th>Help</th>
              </thead>
              <tbody>
                <td>
                  <tr><NavLink to="#">Start an online radio station</NavLink></tr>
                  <tr><NavLink to="#">Radio Academy</NavLink></tr>
                  <tr><NavLink to="#">Success Stories</NavLink></tr>
                  <tr><NavLink to="#">Blog</NavLink></tr>
                </td>
                <td>
                  <tr><NavLink to="#">Help center</NavLink></tr>
                  <tr><NavLink to="#">Video tutorials</NavLink></tr>
                  <tr><NavLink to="#">FAQ</NavLink></tr>
                </td>
              </tbody>
            </table>
          </div> */}
          </NavLink>
      </ul>
      <ul className='nav-list-items' id="second-list">
        <li className='list-property left-padding'><FaEarthAmericas className='left-padding' /> EN</li>
        <li><Link to="/login">Login</Link></li>
      </ul>
    </div>
  )
}

export default Navbar