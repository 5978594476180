import React, { useState, useContext, useEffect } from 'react'
import { LuPencil } from "react-icons/lu";
import { MyMobileContext } from './Context/MyMobileContext';
import { HiPencil } from "react-icons/hi2";

function DedicationPhoneScreen() {


    const { value, buttonActive, ShadowActive,textAlignSetting,myGeneralBTNColor,BTNType,bodySize,myDedicationDesignColor,dark} = useContext(MyMobileContext)
    const [textSize, setTextSize] = useState(null)
    const [paraTextSize, setParaTextSize] = useState(null)
    let [myRadious, setMyRadious] = useState(null)
    let [shadow, setMyShadow] = useState(null)
    const [text,setText] = useState(null)

        useEffect(()=>{
            setText(textAlignSetting)
        },[textAlignSetting])


    useEffect(() => {
        if (value == 0) {
            // MyBGColorChgRef.current.style.fontSize = "14px"
            setTextSize("12px")
            setParaTextSize("13px")
        } else if (value == 20) {
            // MyBGColorChgRef.current.style.fontSize = "15.2206px"
            setTextSize("13.2206px")
            setParaTextSize("14.2206px")
        } else if (value == 40) {
            // MyBGColorChgRef.current.style.fontSize = "18.2647px"
            setTextSize("12px")
            setParaTextSize("16px")
        } else if (value == 60) {
            // MyBGColorChgRef.current.style.fontSize = "20.2941px"
            setTextSize("15.2941px")
            setParaTextSize("15.2941px")
        } else if (value == 80) {
            // MyBGColorChgRef.current.style.fontSize = "22.3235px"
            setTextSize("16.3235px")
            setParaTextSize("16.3235px")
        } else if (value == 100) {
            // MyBGColorChgRef.current.style.fontSize = "24.3529px"
            setTextSize("17.3529px")
            setParaTextSize("17.3529px")
        }
    }, [value])

    useEffect(() => {
        if (buttonActive == "vector1") {
            setMyRadious("0px")

        } else if (buttonActive == "vector2") {
            setMyRadious("5px"
            )
        }
        else if (buttonActive == "vector3") {

            setMyRadious("20px")

        } else if (buttonActive == "vector4") {
            setMyRadious("50px")
        }

    }, [buttonActive])

    const AllDedication = [
        { id: "483920", time: "luna-1 month", text: "Soothing melodies to calm your mind.", color: "#7D9DFF" },
        { id: "562310", time: "atlas-3 weeks", text: "Dance your worries away with these beats.", color: "#FFA07A" },
        { id: "672318", time: "nova-1 month", text: "Epic anthems for every victory.", color: "#8EFDA1" },
        { id: "739420", time: "echo-2 months", text: "Relive the golden days with these classics.", color: "#FFD700" },
        { id: "810562", time: "stellar-1 week", text: "Feel the rhythm of the city.", color: "#6A5ACD" },
        { id: "923140", time: "blaze-2 weeks", text: "Ignite your passion with these tunes.", color: "#FF6347" },
        { id: "109324", time: "zenith-3 days", text: "Find your zen with peaceful sounds.", color: "#98FB98" },
        { id: "112034", time: "harmony-1 month", text: "Unite through the universal language of music.", color: "#DDA0DD" },
        { id: "124567", time: "orbit-3 months", text: "Travel through time with timeless hits.", color: "#FFDAB9" },
        { id: "137890", time: "chime-2 weeks", text: "Ring in joy with these cheerful tunes.", color: "#ADD8E6" }, {
            id: "52w46",
            time: "vijomc-2 months",
            text: "Special one for an unconditional love and care.",
            color: "#4DCEC6"

        },
        {
            id: "9o78723",
            time: "ceaser-2 months",
            text: "Pump up the spirits with evergreen tunes.",
            color: "#FFB257"
        },
        {
            id: "oii7986",
            time: "zac-2 months",
            text: "A playlist for everyone in the struggles of life.",
            color: "#D8FC74"

        },
        {
            id: "389652",
            time: "wilo-2 months",
            text: "Empowering one another with music.",
            color: "#CB4DCE"
        },
    ];

    useEffect(() => {

        if (ShadowActive == "rectangleShadow1") {
            setMyShadow("2px 2px 2px #00000026")

        } else if (ShadowActive == "rectangleShadow2") {
            setMyShadow("2px 2px 1px2px #00000026")
        }
        else if (ShadowActive == "rectangleShadow3") {
            setMyShadow("1px 0px 2.2px 4px #00000026")

        } else if (ShadowActive == "rectangleShadow4") {
            setMyShadow("1px 0px 5.8px 6px #00000026")
        }

    }, [ShadowActive])
    return (
        <>

            <div style={{ overflow: "hidden", height: "530px" }}>
                <div style={{padding:bodySize=="with margin"?"10px 25px 10px 21px":"10px 32px 10px 10px", width:bodySize=="with margin"?"92%":"100%", height: "100%", overflowY: "scroll", boxSizing: "content-box" }}>

                    {AllDedication.map((item) => (
                        <div style={{ borderRadius: `${myRadious}` }} className='Dedication-layout-of-phone' key={item.id} id={item.id}>
                            <div className='Dedication-layout-of-phone-name-logo' ><p className="dedication-layout-letter-logo" style={{ backgroundColor: item.color, boxShadow: `${shadow}` }}>{item.time.slice(0, 1)}</p></div>
                            <div style={{textAlign:`${text}`}} className='Dedication-layout-of-phone-content'>
                                <p style={{ fontSize: `${textSize}`, fontWeight: "400" ,color:myDedicationDesignColor}} className='Dedication-layout-of-phone-name-and-time'>{item.time}</p>
                                <p style={{ fontSize: `${paraTextSize}`, fontWeight: "600",color:myDedicationDesignColor }} className='Dedication-layout-of-phone-text'>{item.text}</p>
                            </div>
                        </div>
                    ))}


                    <div style={BTNType?{ boxShadow: `${shadow}`,backgroundColor:`${myGeneralBTNColor}`,color:"#fff" }:{ boxShadow: `${shadow}`,backgroundColor:"#fff",color:`${myGeneralBTNColor}` }} className='Dedication-layout-of-phone-edit-icon'>{dark?<LuPencil />:<HiPencil />}</div>
                </div>
            </div>



        </>
    )
}

export default DedicationPhoneScreen