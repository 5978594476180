import React, { useState, useRef } from 'react'
import { IoMdArrowDropdown } from "react-icons/io";
import image1 from '../images/image1.png'
import wave from '../images/sound wave image.png'
import image2 from '../images/image2.png'
import wing from '../images/wing.png'
import wing32 from '../images/32.png'
import by7 from '../images/24by7.png'
import app from '../images/app.png'
import love from '../images/love.png'
import thebest from '../images/thebest.png'
import service from '../images/service.png'
import pngwing from '../images/pngwing.png'
import art from '../images/art.png'
import smallart from '../images/smallart.png'
import { NavLink } from 'react-router-dom';
import desktop from '../images/Desktop.png'
import phone from '../images/PhoneLanding.png'
import Alexa from '../images/Alexa.png'
import Landing from '../images/Landing_21.png'
import MaskLanding from '../images/Mask-group-landing.png'



function Hero() {

  // const [dropToggle,setDropToggle]=useState(false)
  const [openDropdown, setOpenDropdown] = useState(null);
  let values = [
    { "value": "What is an Internet radio station?", "content": "An internet radio station broadcasts content online instead of through traditional airwaves. It can be accessed globally via websites, apps, or platforms like Spotify, offering music, talk shows, news, and more." },
    { "value": "What are the prices?", "content": "Our prices start at $5 per month. To see all our offers, go to our rates page." },
    { "value": "Can I get help?", "content": "Yes" },
    { "value": "Are technical skills required?", "content": "No" },
    { "value": "What happens when my demo period ends?", "content": "When your demo period ends, you may need to start paying for the service if you want to continue using it." },
    { "value": "Can I add adverts?", "content": "Yes" },
    { "value": "Do I need to have my computer running 24/7?", "content": "No" }
  ];
  const toggle = (value) => {
    setOpenDropdown((prev) => (prev === value ? null : value));
  };
  // const toggle = (e)=>{
  //   setDropToggle(!dropToggle)
  // }

  return (
    <>
      <div className='hero-main2'>
        <div className='hero-main'>


          <div className='hero-main-main'>
            <h1 className='hero-heading'>Create your Own</h1>
            <p className='hero-para1'>Internet Radio Station App</p>
            <div className='hero-paras2'>
              <p className='hero-para2'>Launch your radio app in</p>
              <p className='hero-para2'>3 simple steps</p>
            </div>
            <NavLink to="/signup"><button className='hero-btn' style={{fontFamily:"montserrat"}}>Try for free</button></NavLink>
            <p className='hero-para3'>14 days free- No credit card required- No commitment</p>
          </div>

          <div>
            <img src={image1} alt="insta image" className='hero-image' />
          </div>



        </div>
        <img src={wave} alt="waves" className='waves' />
      </div>




      <div className='hero-main3'>

        <div><img src={image2} alt="" /></div>
        <div className='hero2-main'>
          <p className='hero2-para1'>It’s simple & quick to create
            your <span style={{ color: '#9682aa' }}>Radio App.</span></p>

          <ul className='hero3-styled-list'>
            <li>Choose an app</li>
            <li>Choose a theme</li>
            <li>Design the content</li>
            <li>Publish</li>
          </ul>
          <NavLink to="/signup"><button className='hero-btn' style={{fontFamily:"montserrat",marginTop:"20px"}}>Try for free</button></NavLink>
        </div>
      </div>



      <div style={{height:"736px",backgroundColor:"#EBEBEB"}} className='hero-main-height-hlp'>
      <div className='hero-main4' >

        <div className='hero3-main'>
          <div className='hero3-buttons'>
            <div className='hero3-button1'>Listener numbers</div>
            <div className='hero3-button2'>Average time</div>
            <div className='hero3-button3'>Total sessions</div>
            <div className='hero3-button4'>Sources</div>
          </div>
          <div className='hero3-main1'>
            <p className='hero3-para1'>Grow your Audience globally.</p>
            <NavLink to="/signup"><button className='hero-btn' style={{marginTop:"30px"}}>Insights & Analytics</button></NavLink>
          </div>
        </div>


        <div className='hero4-main'><img src={wing} alt="wing" width="100%"/></div>

      </div>
      <div className='hero3-main-with-pattern'></div>
      </div>


      <div className='hero-main5' >

        <div className='hero5-main'>
          <p className='hero5-para1'>Manage your Radio App
            in easy steps.</p>
          <p className='hero5-para2'>Create, schedule and broadcast
            your online radio station
            in minutes.</p>
          <NavLink to="/signup"><button className='hero5-button' style={{fontFamily:"montserrat"}}>Free test</button></NavLink>
        </div>
        <div className='hero5-main2'><img className="ma" src={wing32} alt="wing32" style={{ width: "100%" }} /></div>


      </div>



      <div className='hero-main90' >

        <div className='hero90-main'>
          <p className='hero90-para1'>Develop your audience</p>
          <p className='hero90-para2'>Create a Radio Website or Launch your Own
          Mobile Application, or <span style={{fontSize:"22px",fontWeight:"700",lineHeight:"30px",color:"#000"}}>Both</span> !</p>
          {/* <NavLink to="/signup"><button className='hero5-button' style={{fontFamily:"montserrat"}}>Free test</button></NavLink> */}
        </div>
        <div className='hero90-main2'>
          <div>
            <div className='hero90-image-div'><img src={desktop} alt="Desktop" style={{backgroundImage:`url(${Landing})`,backgroundPositionX:"9px",backgroundPositionY:"9px",backgroundRepeat:"no-repeat"}}/></div>
            <div className='hero90-content-div'>
              <p>Radio Website</p>
              <p>From $44/month</p>
              <button>Know more</button>
            </div>
          </div>
          <div>
            <div className='hero90-image-div'><img style={{backgroundImage:`url(${MaskLanding})`,boxShadow: "0px 2px 0px 0px #00000014",backgroundPosition:"23px 9px",backgroundRepeat:"no-repeat"}} src={phone} alt="phone" /></div>
            <div className='hero90-content-div'>
              <p>Mobile Application</p>
              <p>From $29/month</p>
              <button>Know more</button>
            </div>
          </div>
          <div>
            <div className='hero90-image-div'><img src={Alexa} alt="Alexa" /></div>
            <div className='hero90-content-div'>
              <p>Smart Speakers</p>
              <p>From $5/month</p>
              <button>Know more</button>
            </div>
          </div>
        </div>


      </div>




      <div className='hero-main6'>

        <div className='hero-two-images1'>
          <img src={by7} alt="cloud 24/7" className='cloud24' />
          <img src={app} alt="app" className='app' />
        </div>
        <div className='hero-image-cont'>
          <img src={thebest} alt="The Best" className='thebest' />
          <div className='hero6-main'>
            <h3 className='hero6-heading'>+ 4,000 radio stations trust us.</h3>
            <p className='hero6-para'>Radioik has been helping people create their own internet
              radio stations for over 10 years.</p>
          </div>
        </div>
        <div className='hero-two-images2'>
          <img src={love} alt="Love it" className='love' />
          <img src={service} alt="Great Service" className='service' />
        </div>


        {/* <div className='hero6-shape1'></div>
<div className='hero6-shape2'></div>
<div className='hero6-shape3'></div>
<div className='hero6-shape4'></div> */}

      </div>


      <div className='hero-main7'>
        <div className='hero7-main'>
          <h3 className='hero7-headding'>What are you waiting for?</h3>
          <p className='hero7-para1'>Join the 4000+ creators who has chosen RadioIK</p>
          <p className='hero7-para2'>14 days free- No credit card required- No commitment</p>
          <div className='hero7-button-div'><NavLink to="/signup"><button className='hero7-button' style={{fontFamily:"montserrat",marginBottom:"40px"}}>Get Started for free</button></NavLink></div>
        </div>
<p style={{fontSize:"90px",fontWeight:"900",lineHeight:"35px",letterSpacing:"5vw",color:"#0000000D"}}>RADIOIK</p>
        <img src={pngwing} alt="music wings" className='music-wings' />
      </div>




      <div className='hero-main8'>

        <div className='hero8-images'>
          <img src={art} alt="art" style={{position:"relative",top:"-120px"}}/>
          <img src={smallart} alt="small art" style={{position:"relative",top:"-18px",left:"-49px"}}/>
        </div>
        <div className='hero8-main'>
          <h2>Frequently asked questions</h2>
          {/* {values.map((item)=>(  <div className="dropdown" key={item.value}>
  <span>{item.value} </span><IoMdArrowDropdown />
  <div className="dropdown-content">
    <p>{item.content}</p>
  </div>
</div>))} */}
          <div style={{display:"flex",justifyContent:"center"}} >
            <div style={{width:"50%"}}>
              {values.map((item) => (
                <div key={item.value}>
                  <h1
                    style={{ display: "flex", position: "relative", zIndex: "1", justifyContent: "space-between", backgroundColor: "#343236", margin: "10px", color: "white", padding: "10px 20px", fontSize: "18px", fontWeight: "600", borderRadius: "12px", cursor: "pointer", boxShadow: "0px 4px 4px #00000040" }}
                    onClick={() => toggle(item.value)}
                  >
                    {item.value} <IoMdArrowDropdown style={{ transform: openDropdown === item.value ? "rotate(180deg)" : "" }} />
                  </h1>
                  <p
                    style={{
                      overflow: "hidden",
                      transition: "max-height 0.4s ease, padding 0.4s ease",
                      width: "95%", color: "black", position: "relative", top: "-12px", zIndex: "0", backgroundColor: "#f9f9f9", borderRadius: "12px", padding: "10px 20px",
                      maxHeight: openDropdown === item.value ? "100px" : "0",
                      padding: openDropdown === item.value ? "10px 20px" : "0",
                    }}
                  >
                    {item.content}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>



      {/* <div className='hero-main9'>
<div className='hero9-main9'>
<div className='hero9-main'>
<h1 className='hero9-heading'>Radioik</h1>
<button className='hero9-button'>Try for free</button>
<p className='hero9-para'>14 days free</p>
</div>
</div>
</div> */}


    </>
  )
}

export default Hero