import React, { useState, useContext, useEffect } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { MyMobileContext } from './Context/MyMobileContext';

function MenuDesign() {
    let { setToggleClass, showLogoBorder, setShowLogoBorder,myMenuBGColor, setMyMenuBGColor,myMenuBGColorTextId, setMyMenuBGColorTextId,myMenuTextColor, setMyMenuTextColor,myMenuTextId, setMyMenuTextId,menuPreviews, setMenuPreviews } = useContext(MyMobileContext)
    const [urls, setUrls] = useState();
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader();
        reader.onloadend = () => {
            setMenuPreviews(reader.result);
        }; if (file) { reader.readAsDataURL(file); }

    };


    const bgColors = [
        { id: "gsdighwe787645623", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "gsighwe787645632", classIs: "text-colors-green", color: "#ff4757" },
        { id: "gsdyighwe787645634", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "gsdighwe787645635", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "gsdyighwe7876456766", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "gsighwe787645677", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "gsighwe787645689", classIs: "text-colors-black", color: "#000000" },
        // { id: "ighwe7876456po09", classIs: "text-colors-white", color: "none" },
    ]

    const textColors = [
        { id: "gy3u8ti75543fg23", classIs: "text-colors-yellow", color: "#ff9150" },
        { id: "y3u8ti75543fg32", classIs: "text-colors-green", color: "#ff4757" },
        { id: "gsy3u8ti75543fg45", classIs: "text-colors-lightgreen", color: "#2ed573" },
        { id: "gy3u8ti75543fg56", classIs: "text-colors-skyblue", color: "#1e90ff" },
        { id: "gsdy3u8ti75543fg67", classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        { id: "y3u8ti75543fg78", classIs: "text-colors-pink", color: "#CB4DCE" },
        { id: "y3u8ti75543fg89", classIs: "text-colors-black", color: "#000000" },
        // { id: "y3u8ti75543fg90", classIs: "text-colors-white", color: "none" },
    ]

    useEffect(() => {

        setToggleClass(true)
        return () => {
            setToggleClass(false)
        };

    }, [])

    const changeBGColor =(id,color) =>{
        setMyMenuBGColor(color);
        setMyMenuBGColorTextId(id);
          }
    const changetextColor =(id,color) =>{
        setMyMenuTextColor(color);
        setMyMenuTextId(id);
          }

    return (
        <>
            <div>

                <div className='my-radio-design-default'>

                    <div className='My-radio-design-buttons-part2'><label htmlFor="Show-logo">Show logo</label>
                        <label className="switch">
                            <input type="checkbox" name='Show-logo' id='Show-logo' checked={showLogoBorder} onChange={(e) => {
                                setShowLogoBorder(prev => !prev);
                            }} />
                            <span className="slider round"></span>
                        </label></div>
                    <div>
                        {showLogoBorder && !menuPreviews && <FileUploader handleChange={handleChange} name="file" types={fileTypes} />}
                        {showLogoBorder && menuPreviews && <div style={{width:"240px",height:"240px"}}><img className='menu-design-menu-image' style={{width:"inherit",height:"inherit"}} src={menuPreviews} alt={urls} /> <button className='btn-mnu-chg' onClick={()=> setMenuPreviews(prev=>!prev)
                        }>Change image</button></div>}
                        <p style={{fontSize:"14px",lineHeight:"17px",fontWeight:"500"}}>Recomended size 552 X 248px</p>
                        <p style={{fontSize:"14px",lineHeight:"17px",fontWeight:"500"}}> Max Size  1Cmo</p>

                    </div>
                </div>

                <hr style={{ margin: "40px 10px" }} />
                <div className='my-radio-design-Title-and-artist'>
                    <h5>Styles</h5>
                    <p>Text Color</p>
                    <div className='text-colors'>
                        <div className='text-hexa-colors'>

                        <input className={"gre646k00066775q" === myMenuTextId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { changetextColor("gre646k00066775q", (e.target.value)); setMyMenuTextId("gre646k00066775q"); }} />

                            {textColors.map((item) => (<div key={item.id} className={item.id === myMenuTextId ? "border-for-active" : item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { changetextColor(item.id, item.color) }} ></div>))}
                        </div>
                        <div className='Hexa-text'>
                            <label htmlFor="Hexa-text-here">Hexa</label>
                            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myMenuTextColor} onChange={(e) => { setMyMenuTextColor(e.target.value); setMyMenuTextId("gre646k00066775q") }} />
                        </div>
                    </div>
                    <p>Background</p>
                    <div className='text-colors'>
                        <div className='text-hexa-colors'>

                            <input className={"g78t23r2gu8t" === myMenuBGColorTextId ? "border-for-active" : "text-colors-white"} type='color' style={{ backgroundColor: "#fff", margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onChange={(e) => { changeBGColor("g78t23r2gu8t", (e.target.value)); setMyMenuBGColorTextId("g78t23r2gu8t"); }} />

                            {bgColors.map((item) => (<div key={item.id} className={item.id === myMenuBGColorTextId ? "border-for-active" : item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }} onClick={() => { changeBGColor(item.id, item.color) }} ></div>))}
                        </div>
                        <div className='Hexa-text'>
                            <label htmlFor="Hexa-text-here">Hexa</label>
                            <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e) => { const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace'; if (!validKeys) { e.preventDefault(); } }} value={myMenuBGColor} onChange={(e) => { setMyMenuBGColor(e.target.value); setMyMenuBGColorTextId("g78t23r2gu8t") }} />
                        </div>
                    </div>

                    <div className='text-clr'>

                    </div>

                </div>

            </div>
        </>
    )
}

export default MenuDesign