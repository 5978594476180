import React, { useContext, useEffect, useState } from 'react'
import { IoMdHome } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdMenu } from "react-icons/io";
import { LuPencilLine } from "react-icons/lu";
import { GiBookmarklet } from "react-icons/gi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRadio } from "react-icons/fa6";
import { BsFacebook } from "react-icons/bs";
import { PiImagesSquareThin } from "react-icons/pi";
import { BsClockHistory } from "react-icons/bs";
import { LuMessageSquarePlus } from "react-icons/lu";
import { IoGlobeOutline } from "react-icons/io5";
import { MdRssFeed } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { RiExpandLeftRightLine } from "react-icons/ri";
import { MdFlipToFront } from "react-icons/md";
import { TbScanEye } from "react-icons/tb";
import { MdOutlinePodcasts } from "react-icons/md";
import { IoMdAlarm } from "react-icons/io";
import { Link, NavLink } from 'react-router-dom';
import { FaSave } from "react-icons/fa";
import { PiRadioDuotone } from "react-icons/pi";
import { CiFacebook } from "react-icons/ci";
import { LuGripVertical } from "react-icons/lu";
import { PiImagesSquareFill } from "react-icons/pi";
import { BsClockFill } from "react-icons/bs";
import { LuMessageSquareDiff } from "react-icons/lu";
import { IoGlobeSharp } from "react-icons/io5";
import { FaCode } from "react-icons/fa";
import { PiWaveform } from "react-icons/pi";
import { PiWaveformBold } from "react-icons/pi";
import { MdFlipToBack } from "react-icons/md";
import { LuScanEye } from "react-icons/lu";
import { LiaPodcastSolid } from "react-icons/lia";
import { SidebarMenuContext } from './Context/SidebarMenuContext';
import { MyMobileContext } from './Context/MyMobileContext';



function Sidebar() {

  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editId, setEditId] = useState(null);
  // const [editing, setEditing] = useState(false);
  const { myPagies, setMyPagies,dark,allMyPagies } = useContext(SidebarMenuContext)
  const {setDisplayForSinglePhoneScreen,displayForSinglePhoneScreen,setMobileValue} = useContext(MyMobileContext)
 

  const addPagies = [
    { id: "2653r", icon: <LuMessageSquarePlus />, text: "Dedication",textInfo:"Dedication" },
    { id: "6784ru", icon: <IoGlobeOutline />, text: "Website" ,textInfo:"Website" },
    { id: "43890", icon: <MdRssFeed />, text: "RSS" ,textInfo:"RSS" },
    { id: "u9t8u", icon: <IoNewspaperOutline />, text: "News" ,textInfo:"News" },
    { id: "9y34pu", icon: <RiExpandLeftRightLine />, text: "HTML" ,textInfo:"HTML" },
    { id: "78343y", icon: <PiWaveform />, text: "Shout Outs" ,textInfo:"Audio Shout-out" },
    { id: "76tr98y", icon: <MdFlipToFront />, text: "Live Feed" ,textInfo:"Live Feed" },
    { id: "tyt889", icon: <TbScanEye />, text: "Video" ,textInfo:"Video" },
    { id: "096094", icon: <MdOutlinePodcasts />, text: "Podcast" ,textInfo:"Podcast" },
    { id: "guy8723", icon: <IoMdAlarm />, text: "Alarm Clock" ,textInfo:"Alarm Clock" },
  ]

  const updateText = (id,sec,text) => { 
  setMyPagies(prev => prev.map(item => (item.id === id ? { ...item, text: text } : item))); 
  // console.log(id);
  setDisplayForSinglePhoneScreen(prev =>prev.map((item)=>item.id===sec?{...item,text:text}:item))
  };
  


  const editText = (id) => {
    setIsReadOnly(!isReadOnly)
    setEditId(id);
    setTimeout(() => { const input = document.getElementById(`input-${id}`); if (input) { input.focus(); } }, 100);
    // setEditing(!editing);

  }
  const addPageToMyPagies = (pageText) => {
    console.log("pageText",pageText);
    
    const myPage = myPagies.find((item) => item.text == pageText);

    if (!myPage) {
      const missingPage = allMyPagies.find((item) => item.text == pageText);
      if (missingPage) {
        setMyPagies((prev) => [...prev, missingPage])
      }
    }
  }

  const removeItem = (value) => {
    const newdata = myPagies.filter((item) => item.id != value);
    setMyPagies(newdata);
  }

  const firstFiveElem = addPagies.slice(0, 5).map((item) => (
    <p onClick={() => addPageToMyPagies(item.text)} key={item.id}>{item.icon} {item.textInfo}</p>
  ))
  const remainingItems = addPagies.slice(5).map((item) => (
    <p onClick={() => addPageToMyPagies(item.text)} key={item.id}>{item.icon} {item.textInfo}</p>
  ))



  return (
    <>
      <div className='sidebar-main-not-for-desktop-screen-125' style={{ width: "25%",height:"100%", backgroundColor: "#9682AA", display: "flex", justifyContent: "center", position: "fixed", top: "135px" }}>
        <div className='sidebar-main'>
          {/* <div><p className='sidebar-main-para'><NavLink to="/radio" className= "nav-link" ><div><IoMdHome /> Home </div> </NavLink></p> </div> */}

          <div> <p className='sidebar-main-para'><NavLink to="generalsetting" className={(navData) => navData.isActive ? `my-nav-link` : "nav-link"}>
            <div style={{ display: "flex", alignItems: "center" }}><IoSettingsOutline style={{ marginRight: "5px" }} /> General setting</div> <LuPencilLine className='toggle-on-click-and-hover' /></NavLink></p> </div>
          <div><p className='sidebar-main-para'> <NavLink to="menu" className={(navData) => navData.isActive ? "my-nav-link" : "nav-link"}> <div style={{ display: "flex", alignItems: "center" }}><IoMdMenu style={{ marginRight: "5px" }} /> Menu</div> <LuPencilLine className='toggle-on-click-and-hover' /></NavLink></p></div>

          <div className='sidebar-main-content'>
            <h5 className='sidebar-main2-heading'><GiBookmarklet /> My Pages</h5>
            <div className='sidebar-main2-relative-div'>
              {myPagies.map((item) => (
                <p key={item.id} id={item.id} className='sidebar-main2-para'> <NavLink to={item.sideBarLink} className={(navData) => navData.isActive ? "my-nav-link" : "nav-link"}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {item.icon}
                    <input id={`input-${item.id}`} maxLength={45} type="text" value={item.id === editId ? item.text : item.text} readOnly={item.id !== editId} autofocus onChange={(e) => updateText(item.id,item.secondId, e.target.value)} style={{ fontSize: "16px", lineHeight: "20px", fontWeight: "500", border: "none", cursor: "pointer" }} />
                  </div>
                  <div className='my-sidebar-pancil-delete-icons-flex'><LuPencilLine onClick={(e) => editText(item.id)} />
                    {/* {editing && item.id === editId ? <FaSave onClick={() => setEditId("kje")} /> : <LuPencilLine onClick={(e) => editText(item.id)} />} */}
                    <RiDeleteBin6Line className='toggle-on-click-and-hover-in-my-pagies' onClick={() => removeItem(item.id)
                    } /></div></NavLink></p>
              ))}

            </div>
          </div>
          <h5 className='sidebar-main3-heading'>Add a Page</h5>
          <div className='sidebar-main-content2'>
            <div className='sidebar-main-content2s'>
              {firstFiveElem}
            </div>
            <div className='sidebar-main-content2s'>
              {remainingItems}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar

