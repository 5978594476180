import React, { useContext, useEffect, useState } from 'react'
import doneOutline from '../images/done_outline.png'
import { useNavigate } from 'react-router-dom';
import { MyMobileContext } from './Context/MyMobileContext';

function ChooseOptions() {

    const [monthly,setMonthly] = useState(false);
    const [annually,setAnnually] = useState(false);
   const {buttonValue,setButtonValue} = useContext(MyMobileContext)
    const navigate = useNavigate();
    
  return (
   <>
   <div className='choose-option-main-div'>
    <div className='choose-option-inner-main-div'>
        <h3>Choose the options </h3>
        <div className='choose-option-strip'>
            <div className='choose-option-circle-div'><img src={doneOutline} alt="done image" width="25px" height="25px"/></div>
            <hr />
            <div className='choose-option-circle-div'></div>
            <hr />
            <div className='choose-option-circle-div'></div>
            
        </div>
        <div className='choose-option-more-info'>
            <div className='choose-option-inner-more-info1'>
                <h4>Smartphone App</h4>
                <hr />
                <p>Choosing billing cycle</p>
                <div>
                    <div className='choose-option-monthly-div'>
                        <p>Monthly</p>
                    <label htmlFor="Monthly-push-notification">$59</label>
                    <input type="checkbox" name="Monthly-push-notification" id="Monthly-push-notification"  onChange={()=>{setMonthly(!monthly);
                        setButtonValue(prev => {
                                        const prevPrice = parseFloat(prev.replace('$', ''));
                                        const itemPrice = 59;
                                        let totalPrice;
                                        if(!monthly){
                                            totalPrice = prevPrice + itemPrice
                                        }else{
                                            totalPrice = prevPrice - itemPrice
                                        }
                                        console.log(`$${totalPrice.toFixed(2)}`, "priceplan after");
                                        return `$${totalPrice.toFixed(2)}`;
                                    })
                           }}/>
                    </div>

                    <div className='choose-option-annually-div'>
                    <p>Annually</p>
                    <label htmlFor="Annually-push-notification">$590</label>
                    <input type="checkbox" name="Annually-push-notification" id="Annually-push-notification" onChange={()=>{setAnnually(!annually);
                        setButtonValue(prev => {
                                        const prevPrice = parseFloat(prev.replace('$', ''));
                                        const itemPrice = 590;
                                        let totalPrice;
                                        if (!annually) {
                                            totalPrice = prevPrice + itemPrice;
                                          } else {
                                            totalPrice = prevPrice - itemPrice;
                                          }
                                        console.log(`$${totalPrice.toFixed(2)}`, "priceplan after");
                                        return `$${totalPrice.toFixed(2)}`;
                                    })
                            }} />
                    </div>
                </div>
                

            </div>
            <hr />
            <div className='choose-option-inner-more-info2'>
                <h4>Configuration Upgrades</h4>
                <hr />
                <p style={{textAlign:"center"}}>Push notifications</p>
                <div>
                    <div className='choose-option-configuration-upgrade1'>
                    <label htmlFor="push-notification-50000">50000</label>
                    <input type="checkbox" name="push-notification-50000" id="push-notification-50000" />
                    </div>
                    <div className='choose-option-configuration-upgrade2'>
                    <label htmlFor="push-notification-100000">100000</label>
                    <input type="checkbox" name="push-notification-100000" id="push-notification-100000" />
                    </div>
                  
                </div>
            </div>
            <hr />
            <div className='choose-option-inner-more-info3'>
                <h4>Available Address</h4>
                <hr />
                <input type="url" name="Available-address" id="Available-address" placeholder='Type your address here...' />
            </div>
        </div>
        <div className='choose-option-last-div'>
            <p>Your total</p>
            <button className='choose-option-btn'onClick={()=>{annually&&monthly?alert("Choosing one annualy or monthly billing cycle"):!annually&&!monthly?alert("Choosing one annualy or monthly"):navigate("/radioik/ordersummary")}}>{buttonValue}</button> 
        </div>
    </div>
   </div>

   <div className='pricing-plan-about-radioik-app'>
  <div><p>About Radioik App</p></div>
</div>
   
   </>
  )
}

export default ChooseOptions