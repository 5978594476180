import React, { useState,useContext, useEffect } from 'react'
import { MyMobileContext } from './Context/MyMobileContext'
import soundTrap from '../images/soundtrap.png'
import randomVideo from '../assets/randomVideo.mp4'
import VideoMask1 from '../images/VideoMask1.png'
import VideoMask2 from '../images/VideoMask2.png'
import VideoMask3 from '../images/VideoMask3.png'

function VideoPhoneScreen() {


          const [textSize, setTextSize] = useState(null)
          const [paraTextSize, setParaTextSize] = useState(null)
    const {value,buttonActive,textAlignSetting,bodySize,myVideoDesignColor} = useContext(MyMobileContext)
    let [myRadious,setMyRadious] = useState(null)

           const [text,setText] = useState(null)
        useEffect(()=>{
            setText(textAlignSetting)
        },[textAlignSetting])

          useEffect(()=>{
      
      if(buttonActive=="vector1"){
          setMyRadious("0px")
      
      }else if(buttonActive=="vector2"){
          setMyRadious("5px"
      )}
      else if(buttonActive=="vector3"){
          
          setMyRadious("20px")
      
      }else if(buttonActive=="vector4"){
          setMyRadious("50px")
      }
      
          },[buttonActive])

       useEffect(()=>{
          if(value == 0){
              // MyBGColorChgRef.current.style.fontSize = "14px"
              setTextSize("16px")
              setParaTextSize("14px")
          } else if(value == 20){
              // MyBGColorChgRef.current.style.fontSize = "15.2206px"
              setTextSize("17.2206px")
              setParaTextSize("14.2206px")
          }else if(value == 40){
              // MyBGColorChgRef.current.style.fontSize = "18.2647px"
              setTextSize("17px")
              setParaTextSize("14px")
          }else if(value == 60){
              // MyBGColorChgRef.current.style.fontSize = "20.2941px"
              setTextSize("18.2941px")
              setParaTextSize("14.2941px")
          }else if(value == 80){
              // MyBGColorChgRef.current.style.fontSize = "22.3235px"
              setTextSize("19.3235px")
              setParaTextSize("14.3235px")
          }else if(value == 100){
              // MyBGColorChgRef.current.style.fontSize = "24.3529px"
              setTextSize("20.3529px")
              setParaTextSize("15.3529px")
          }
       },[value])

    const videoData = [
        { id: "172t78s", ThumbNail: VideoMask1, video: <source src={randomVideo} type="video/mp4" />, title: "Title1", description: "description" },
        { id: "u893e2d", ThumbNail: VideoMask2, video: <source src={randomVideo} type="video/mp4" />, title: "Title2", description: "description" },
        { id: "901r76e", ThumbNail: VideoMask3, video: <source src={randomVideo} type="video/mp4" />, title: "Title3", description: "description" },
    ]
  return (
    <>

                            <div className='video-layout-phone-main-div' style={{width:bodySize=="with margin"?"90%":"95%",}}>
                                <div className='video-layout-phone-first-div'>
                                <video style={{borderRadius:`${myRadious}`}} id="first-video" controls poster={soundTrap}>
    <source src={randomVideo} type="video/mp4"/>
    Your browser does not support the video tag.
</video>
                                    <p style={{fontSize:`${textSize}`,textAlign:`${text}`,color:myVideoDesignColor}}>Launch your  Website  & Mobile App in a Week.</p>
                                </div>
                                <div style={{ overflow: "hidden", height: "248px" }}>
                                <div className='video-layout-phone-all-main-div'>
                                    {videoData.map((item)=>(
                                        <div className='video-layout-phone-all-div-with-same-view' key={item.id}>
                                        <video style={{borderRadius:`${myRadious}`}} controls poster={item.ThumbNail}>
                                            {item.video}
                                        Your browser does not support the video tag.
                                        </video>
                                        <div style={{textAlign:`${text}`}} className='video-layout-phone-all-div-title-and-discription'>
                                            <h4 style={{color:myVideoDesignColor}}>{item.title}</h4>
                                            <p style={{fontSize:`${paraTextSize}`,color:myVideoDesignColor}} >{item.description}</p>
                                        </div>
                                        </div>
                                    ))}
                                </div>
                                </div>
                            </div>
    </>
  )
}

export default VideoPhoneScreen