import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'

function HtmlPageLayout() {

   

    return (
        <>
    <div style={{ width:"35%"}}>
        <div className='my-radio-content-starts-here1'>
        <div className='setting-45'>
            <MyRadioMain RadioLink1={"/radio/html"} RadioLink2={"/radio/html/design"}/>
            <Outlet />
        </div>
        </div>
    </div>
</>
)
}

export default HtmlPageLayout