import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'

function BlogsLayout() {

    return (
        <>
            <div style={{width:"35%"}}>
                <div className='my-radio-content-starts-here1'>
                <div className='setting-45'>
                    <MyRadioMain RadioLink1={"/radio/blog"} RadioLink2={"/radio/blog/design"} />
                    <Outlet />
                </div>
                </div>
            </div>
        </>
    )
}

export default BlogsLayout