import React, { useState,useContext, useEffect } from 'react'
import { PiMicrophoneFill } from "react-icons/pi";
import { MyMobileContext } from './Context/MyMobileContext'
import { BiMicrophone } from "react-icons/bi";

function AudioShoutOutPhoneScreen() {

          const [textSize, setTextSize] = useState(null)
    const {value,buttonActive,ShadowActive,myGeneralBTNColor,BTNType,myAudioShoutOutDesignColor,dark } = useContext(MyMobileContext)
    let [myRadious,setMyRadious] = useState(null)
     let [shadow,setMyShadow] = useState(null)

        useEffect(()=>{
        
            if(ShadowActive == "rectangleShadow1"){
        setMyShadow("2px 2px 2px #00000026")
        
            }else if(ShadowActive == "rectangleShadow2") {
        setMyShadow("2px 2px 1px2px #00000026")
            }
            else if(ShadowActive == "rectangleShadow3") {
        setMyShadow("1px 0px 2.2px 4px #00000026")
            
            }else if(ShadowActive == "rectangleShadow4") {
        setMyShadow("1px 0px 5.8px 6px #00000026")
            }
        
        },[ShadowActive])

          useEffect(()=>{
      
      if(buttonActive=="vector1"){
          setMyRadious("0px")
      
      }else if(buttonActive=="vector2"){
          setMyRadious("5px"
      )}
      else if(buttonActive=="vector3"){
          
          setMyRadious("20px")
      
      }else if(buttonActive=="vector4"){
          setMyRadious("50px")
      }
      
          },[buttonActive])


       useEffect(()=>{
          if(value == 0){
              // MyBGColorChgRef.current.style.fontSize = "14px"
              setTextSize("14px")
              // setParaTextSize("14px")
          } else if(value == 20){
              // MyBGColorChgRef.current.style.fontSize = "15.2206px"
              setTextSize("14.2206px")
              // setParaTextSize("14.2206px")
          }else if(value == 40){
              // MyBGColorChgRef.current.style.fontSize = "18.2647px"
              setTextSize("15px")
              // setParaTextSize("14px")
          }else if(value == 60){
              // MyBGColorChgRef.current.style.fontSize = "20.2941px"
              setTextSize("16.2941px")
              // setParaTextSize("14.2941px")
          }else if(value == 80){
              // MyBGColorChgRef.current.style.fontSize = "22.3235px"
              setTextSize("17.3235px")
              // setParaTextSize("14.3235px")
          }else if(value == 100){
              // MyBGColorChgRef.current.style.fontSize = "24.3529px"
              setTextSize("18.3529px")
              // setParaTextSize("15.3529px")
          }
       },[value])
  return (
    <>
                            <div className='audio-short-out-phone-layout'>
                                <p style={{color:myAudioShoutOutDesignColor}} className='audio-short-out-phone-layout-para'>Record</p>
                                <div style={BTNType?{boxShadow:`${shadow}`,backgroundColor:`${myGeneralBTNColor}`,color:"#fff"}:{boxShadow:`${shadow}`,backgroundColor:"#fff",color:`${myGeneralBTNColor}`}} className='audio-short-out-phone-layout-logo-div'>{dark?<BiMicrophone />:<PiMicrophoneFill />}</div>
                            </div>
                            
                            <div style={{borderRadius:`${myRadious}`}} className='audio-short-out-phone-layout-last-div-with-recording'>
                                <p style={{fontSize:`${textSize}`,color:myAudioShoutOutDesignColor}}>You can record upto 30 seconds</p>
                            </div>
                 
    </>
  )
}

export default AudioShoutOutPhoneScreen