import React, { useState,useEffect,useContext } from 'react'
import playIcon from '../images/play icon.png'
import libraryMusic from '../images/library_music.png'
import { MdOutlineMenuOpen } from "react-icons/md";
import { TbBellRingingFilled } from "react-icons/tb";
import { GoPlus } from "react-icons/go";
import { MyMobileContext } from './Context/MyMobileContext';
import { HiOutlineBellAlert } from "react-icons/hi2";

function AlarmPhoneScreen() {
    var [date,setDate] = useState(new Date());
        let [myRadious,setMyRadious] = useState(null)
        let [shadow,setMyShadow] = useState(null)
    const {value,buttonActive,ShadowActive,myGeneralBTNColor,BTNType,bodySize,myAlarmClockDesignColor,dark} = useContext(MyMobileContext)
            const [textSize, setTextSize] = useState(null)
            const [paraTextSize, setParaTextSize] = useState(null)
        useEffect(() => {
            var timer = setInterval(()=>setDate(new Date()), 1000 )
            return function cleanup() {
                clearInterval(timer)
            }
        
        }); 

    useEffect(()=>{

if(buttonActive=="vector1"){
    setMyRadious("0px")

}else if(buttonActive=="vector2"){
    setMyRadious("5px"
)}
else if(buttonActive=="vector3"){
    
    setMyRadious("20px")

}else if(buttonActive=="vector4"){
    setMyRadious("50px")
}

    },[buttonActive])


useEffect(()=>{

    if(ShadowActive == "rectangleShadow1"){
setMyShadow("2px 2px 2px #00000026")

    }else if(ShadowActive == "rectangleShadow2") {
setMyShadow("2px 2px 1px #00000026")
    }
    else if(ShadowActive == "rectangleShadow3") {
setMyShadow("1px 0px 2.2px 2px #00000026")
    
    }else if(ShadowActive == "rectangleShadow4") {
setMyShadow("1px 0px 5.8px 4px #00000026")
    }

},[ShadowActive])


useEffect(()=>{
    if(value == 0){
        // MyBGColorChgRef.current.style.fontSize = "14px"
        setTextSize("22px")
        setParaTextSize("63px")
    } else if(value == 20){
        // MyBGColorChgRef.current.style.fontSize = "15.2206px"
        setTextSize("23.2206px")
        setParaTextSize("64.2206px")
    }else if(value == 40){
        // MyBGColorChgRef.current.style.fontSize = "18.2647px"
        setTextSize("24px")
        setParaTextSize("65px")
    }else if(value == 60){
        // MyBGColorChgRef.current.style.fontSize = "20.2941px"
        setTextSize("25.2941px")
        setParaTextSize("65.2941px")
    }else if(value == 80){
        // MyBGColorChgRef.current.style.fontSize = "22.3235px"
        setTextSize("26.3235px")
        setParaTextSize("66.3235px")
    }else if(value == 100){
        // MyBGColorChgRef.current.style.fontSize = "24.3529px"
        setTextSize("27.3529px")
        setParaTextSize("67.3529px")
    }
 },[value])

  return (
    <>
                                        <div className='Main-alarm-set-here-part'>
                                        <div className='Main-alarm-set-here-part34' style={{width:bodySize=="with margin"?"240px":"270px"}}>
                                            <p style={{fontSize:`${textSize}`,color:myAlarmClockDesignColor}} className='main-alarm-date-here'>{date.toDateString()}</p>
                                            <p style={{fontSize:`${paraTextSize}`,color:myAlarmClockDesignColor}} className='main-alarm-time-here'>{date.getHours()}:{date.getMinutes()}</p>
                                            <div style={{borderRadius:`${myRadious}`,color:myAlarmClockDesignColor}} className='Main-alarm-set-here'>
                                                {dark?<HiOutlineBellAlert style={{boxShadow:`${shadow}`,color:`${myGeneralBTNColor}`}} className='alarm-clock-bell-icon'/>:<TbBellRingingFilled style={{boxShadow:`${shadow}`,color:`${myGeneralBTNColor}`}} className='alarm-clock-bell-icon'/>}
                                                <div className='My-radio-buttons-part2'>
                                                    <label htmlFor="Alarm-Clock">08:00</label>
                                                    <label className="switch">
                                                        <input type="checkbox" name='Alarm-Clock' id='Alarm-Clock' />
                                                        <span className="slider round"></span>
                                                    </label></div>
                                                    <p style={{marginBottom:"20px",color:"#343236"}}>Mo-Tu-We-Th-Fr-Sa-Su</p>

                                            </div>
                                            </div>
                                            <div className='AlarmPhonePlus' style={BTNType?{boxShadow:`${shadow}`,backgroundColor:`${myGeneralBTNColor}`}:{boxShadow:`${shadow}`,color:`${myGeneralBTNColor}`,backgroundColor:"#fff"}}>
                                                <GoPlus/>
                                            </div>
                                        </div >

    </>
  )
}

export default AlarmPhoneScreen