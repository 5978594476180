import React, { useState,useEffect } from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'

function AlarmLayout() {

    var [date,setDate] = useState(new Date());
    
    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }
    
    });    

    return (
        <>
            <div style={{ width:"35%"}}>
                <div className='my-radio-content-starts-here1'>
                <div className='setting-45'>
                    <MyRadioMain RadioLink1={"/radio/alarmclock"} RadioLink2={"/radio/alarmclock/design"} />
                    <Outlet />
                </div>
                </div>
               
            </div>
        </>
    )
}

export default AlarmLayout