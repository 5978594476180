import React, { useContext } from 'react'
import { MyMobileContext } from './Context/MyMobileContext'

function MyApps() {

// const myAllApps = [
// {id:"uyge3500",Value:"Radioik app",price:"$18.00"},
// {id:"25367rt2",Value:"Bol Punjabi",price:"$18.00"},
// {id:"ukmjh0m",Value:"Modern Station",price:"$18.00"},
// {id:"n593rt",Value:"Chill Zone",price:"$18.00"},
// {id:"nj4309",Value:"All Time Hits",price:"$18.00"},
// ]

const {allApps} = useContext(MyMobileContext)
console.log(allApps);


  return (
    <>
    
    <div className='my-apps-main-div'>
        <div className='my-apps-inner-main-div'>
            <h4>My Radio Apps</h4>

                <ul className='my-apps-unordered-list'>
                   {allApps.map((item,index)=>(
                     <li key={item.id} id={item.id}>
                     <p>{item.Value}</p>
                     <p>{item.price}</p>
                     <button onClick={()=>alert(`pay ${item.price}`)}>Buy now</button>
                 </li>
                   ))}
                </ul>
        </div>
    </div>

    <div className='my-apps-last-div'>
               
            </div>



    </>
  )
}

export default MyApps