import React, { useContext, useState } from 'react'
import MainNavbar3 from './MainNavbar3'
import { FileUploader } from "react-drag-drop-files";
import { MyMobileContext } from './Context/MyMobileContext';
import { useNavigate } from 'react-router-dom';

function Publication() {

    const navigate = useNavigate()

    const {showPopup,setShowPopup,appIconPreviews, setAppIconPreviews,smartPhoneLargePreviews, setSmartPhoneLargePreviews,smartPhonePreviews, setSmartPhonePreviews} = useContext(MyMobileContext)

    const [urls, setUrls] = useState();
    const fileTypes = ["JPG", "PNG", "GIF"];

    const [file, setFile] = useState(null);
    const handleChange = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader();
        reader.onloadend = () => {
            setAppIconPreviews(reader.result);
        }; if (file) { reader.readAsDataURL(file); }
    };

    const handleChanges = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader();
        reader.onloadend = () => {
            setSmartPhoneLargePreviews(reader.result);
        }; if (file) { reader.readAsDataURL(file); }
    };

    const handleChangess = (file) => {
        setUrls(file);
        console.log(urls);
        const reader = new FileReader();
        reader.onloadend = () => {
            setSmartPhonePreviews(reader.result);
        }; if (file) { reader.readAsDataURL(file); }
    };



    return (
        <>
            <MainNavbar3 />
            <div className='Publication-main-for-other-devices-only' style={{ position: "relative", top: "200px",zIndex:"0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", 
            filter:showPopup?"blur(10px)":"blur(0px)",
                overflow: showPopup?"hidden":"visible" }}>
                <h2 style={{ fontSize: "2.25rem", lineHeight: "2.5rem", fontWeight: "700" }}>It's almost finished!</h2>

                <h5 style={{ fontSize: "1.5rem", lineHeight: "2rem", marginTop: "3.25rem" }}>Images for the publication</h5>
                <p>Images must not be larger than 10 Mb.</p>
                <p>Accepted image formats are: jpg, png</p>

                <div style={{ margin: "2.25rem 0rem", width: "100%", display: "flex", justifyContent: "center" }}>
                    <div className="My-publication-div-image1" >
                        <div style={{ display: "flex", justifyContent: "space-evenly", padding: "2.25rem 0rem" }}>

                            {!appIconPreviews && <FileUploader handleChange={handleChange} name="file" types={fileTypes} />}
                            {appIconPreviews && <div style={{width:"240px",height:"200px"}}><img src={appIconPreviews} alt={urls} style={{ height: "inherit",width:"inherit" }} /></div>}

                            <div style={{ width: "275px" }}>
                                <h6 style={{ fontWeight: "700", fontSize: "16px", marginBottom: ".5rem" }}>App icon</h6>
                                <p style={{ fontWeight: "700", fontSize: "14px" }}>Technical specifications</p>
                                <p>Recommended size: 1024 x 1024px</p>
                                {appIconPreviews && <button style={{marginTop:"25px",border:"none",padding:"15px 20px",borderRadius:"10px"}} onClick={()=>setAppIconPreviews(null)}>Change image</button>}
                            </div>
                        </div>
                        <hr />
                        <div style={{ display: "flex", justifyContent: "space-evenly", padding: "2.25rem 0rem" }}>

                            {!smartPhoneLargePreviews && <FileUploader handleChange={handleChanges} name="file" types={fileTypes} />}
                            {smartPhoneLargePreviews && <div style={{width:"240px",height:"200px"}}><img src={smartPhoneLargePreviews} alt={urls} style={{ height: "inherit",width:"inherit" }} /></div>}

                            <div style={{ width: "275px" }}>
                                <h6 style={{ fontWeight: "700", fontSize: "16px", marginBottom: ".5rem" }}>Smartphone - Large screen</h6>
                                <p style={{ marginBottom: "1rem" }}>This image corresponds to the display at the launch of the app on phones with a larger screen.</p>
                                <p style={{ fontWeight: "700", fontSize: "14px" }}>Technical specifications</p>
                                <p>Recommended size: 1242 x 2688px</p>
                                {smartPhoneLargePreviews &&<button style={{marginTop:"25px",border:"none",padding:"15px 20px",borderRadius:"10px"}} onClick={()=>setSmartPhoneLargePreviews(null)}>Change image</button>}
                            </div>
                        </div>
                        <hr />
                    </div>
                    <hr style={{margin:"0px"}}/>
                    <div className="My-publication-div-image2" >

                       <div style={{display: "flex", justifyContent: "space-evenly", padding: "2.25rem 0rem" }}>
                       {!smartPhonePreviews && <FileUploader handleChange={handleChangess} name="file" types={fileTypes} />}
                        {smartPhonePreviews && <div style={{width:"240px",height:"200px"}}><img src={smartPhonePreviews} alt={urls} style={{ height: "inherit",width:"inherit" }} /></div>}

                        <div style={{ width: "275px" }}>

                            <h6 style={{ fontWeight: "700", fontSize: "16px", marginBottom: ".5rem" }}>Smartphone</h6>
                            <p style={{ marginBottom: "1rem" }}>This image corresponds to the display at the launch of the app.</p>
                            <p style={{ fontWeight: "700", fontSize: "14px" }}>Technical specifications</p>
                            <p>Recommended size: 1242 x 2208px</p>
                            {smartPhonePreviews && <button style={{marginTop:"25px",border:"none",padding:"15px 20px",borderRadius:"10px"}} onClick={()=>setSmartPhonePreviews(null)}>Change image</button>}
                        </div>
                       </div>

                        <hr />
                    </div>
                </div>
               


            </div>
            
            {showPopup && appIconPreviews&&smartPhoneLargePreviews&&smartPhonePreviews &&(<div className="popup" style={{display: showPopup ? 'block' : 'none',color:"black", filter:"blur(0px)",overflow:"visible"  }}> <div className="popup-content"> <span className="close" style={{color:"black"}} onClick={() => setShowPopup(false)}>&times;</span> 
                <h3 style={{marginBottom:"30px"}}>Do you want Radioik to publish the application for you?</h3>
                <p style={{marginBottom:"20px"}}>We take care of the entire publication of your application on your Apple and Google developer accounts. Entrust us with this task, and ensure that your app is online quickly, in accordance with platform requirements.</p> 
                <p style={{marginBottom:"20px"}}>This option costs only $50 once.</p>
                <p style={{marginBottom:"45px"}}>If you wish to publish it yourself, our tutorials will guide you step-by-step.</p>
                <div style={{width:"100%",display:"flex",justifyContent:"space-around"}}>
                    <button className='my-Publication-buttons' onClick={()=>navigate("/orderlist")}>I publish myself</button>
                    <button className='my-Publication-buttons' onClick={()=>navigate("/radioik/chooseoptions")}>Radioik publishes for me</button>
                    </div>
                </div> 
                </div> )}
        </>
    )
}

export default Publication