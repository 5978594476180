import React from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'


function AudioShoutOutLayout() {
    return (
        <>
            <div style={{ width: "35%" }}>
                <div className='my-radio-content-starts-here1'>
                    <div className='setting-45'>
                        <MyRadioMain RadioLink1={"/radio/audioshoutout"} RadioLink2={"/radio/audioshoutout/design"} />
                        <Outlet />
                    </div>
                </div>
   
            </div>
        </>
    )
}

export default AudioShoutOutLayout