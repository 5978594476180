import './App.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HtmlTextProvider } from './Components/Context/HtmlTextContext';
import { useState } from 'react';
import { SidebarMenuProvider } from './Components/Context/SidebarMenuContext';
import {Facebook,ForgotPassword,ManuLayout,MyApps,MyRadio,MyRadioDesign,MyRadioLayout,Menu,ChooseAppName,ChooseTheme,ChooseOptions,Demo,AlarmClockContent,AlarmLayout,AlarmClockDesign,AudioShoutOut,AudioShoutOutDesign,AudioShoutOutLayout,HTMLDesign,HTMLPage,Landing,LiveFeed,LiveFeedDesign,LiveFeedLayout,Login,PasswordRecovery,Podcast,PodcastDesign,PodcastLayout,PricingPlan,Publish,OrderList,OrderSummary,OthersLayout,Dedication,DedicationDesign,DedicationLayout,Video,VideoDesign,VideoLayout,BlogContent,BlogDesign,BlogsLayout,BuyNewServices,SignUp,GeneralSettings,Website,WebsiteDesign,WebsitePageLayout,RSS,RSSDesign,RSSLayout,HtmlPageLayout} from './Components/index.js'
import MenuLayout from './Components/Layout/MenuLayout';
import MenuDesign from './Components/MenuDesign';
import FacebookLayout from './Components/Layout/FacebookLayout';
import FacebookDesign from './Components/FacebookDesign';
import { MyMobileContextProvider } from './Components/Context/MyMobileContext';
import Publication from './Components/Publication';

function App() {
  return (
    <>
    <HtmlTextProvider>
    <MyMobileContextProvider>
    <SidebarMenuProvider>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Landing/>}/>
      <Route path='/signup' element={<SignUp/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/forgotpassword' element={<ForgotPassword/>}/>
      <Route path='/reset' element={<PasswordRecovery/>}/>
      <Route path='/chooseappname' element={<ChooseAppName/>}/>
      <Route path='/orderlist' element={<OrderList/>}/>
      <Route path='/choosetheme' element={<ChooseTheme/>}/>
      <Route path='/radio/' element={<ManuLayout/>}>
      {/* <Route path='home' /> */}
      <Route path='generalsetting' element={<GeneralSettings/>}/>
      
      
      <Route path='menu/' element={<MenuLayout/>}>
      <Route path='' element={<Menu/>}/>
      <Route path='design' element={<MenuDesign/>}/>
      </Route>

      <Route path='myradio/' element={<MyRadioLayout/>}>
      <Route path='' element={<MyRadio/>}/>
      <Route path='design' element={<MyRadioDesign/>}/>
      </Route>

      <Route path='facebook/' element={<FacebookLayout/>}>
      <Route path='' element={<Facebook/>}/>
      <Route path='design' element={<FacebookDesign/>}/>
      </Route>

     

      <Route path='blog/' element={<BlogsLayout/>}>
      <Route path='' element={<BlogContent/>}/>
      <Route path='design' element={<BlogDesign/>}/>
      </Route>

      <Route path='alarmclock/' element={<AlarmLayout/>}>
      <Route path='' element={<AlarmClockContent/>}/>
      <Route path='design' element={<AlarmClockDesign/>}/>
      </Route>

      <Route path='dedication/' element={<DedicationLayout/>}>
      <Route path='' element={<Dedication/>}/>
      <Route path='design' element={<DedicationDesign/>}/>
      </Route>

      <Route path='website/' element={<WebsitePageLayout/>}>
      <Route path='' element={<Website/>}/>
      <Route path='design' element={<WebsiteDesign/>}/>
      </Route>

      <Route path='RSS/' element={<RSSLayout/>}>
      <Route path='' element={<RSS/>}/>
      <Route path='design' element={<RSSDesign/>}/>
      </Route>

      <Route path='html/' element={<HtmlPageLayout/>}>
      <Route path='' element={<HTMLPage/>}/>
      <Route path='design' element={<HTMLDesign/>}/>
      </Route>

      <Route path='audioshoutout/' element={<AudioShoutOutLayout/>}>
      <Route path='' element={<AudioShoutOut/>}/>
      <Route path='design' element={<AudioShoutOutDesign/>}/>
      </Route>

      <Route path='livefeed/' element={<LiveFeedLayout/>}>
      <Route path='' element={<LiveFeed/>}/>
      <Route path='design' element={<LiveFeedDesign/>}/>
      </Route>

      <Route path='video/' element={<VideoLayout/>}>
      <Route path='' element={<Video/>}/>
      <Route path='design' element={<VideoDesign/>}/>
      </Route>

      <Route path='podcast/' element={<PodcastLayout/>}>
      <Route path='' element={<Podcast/>}/>
      <Route path='design' element={<PodcastDesign/>}/>
      </Route>



      </Route>
      <Route path='/radioik/' element={<OthersLayout/>}>
      <Route path='pricingplan' element={<PricingPlan/>}/>
      <Route path='publication' element={<Publication/>}/>
      <Route path='publish' element={<Publish/>}/>
      <Route path='chooseoptions' element={<ChooseOptions/>}/>
      <Route path='ordersummary' element={<OrderSummary/>}/>
      <Route path='buynewservices' element={<BuyNewServices/>}/>
      <Route path='myapps' element={<MyApps/>}/>
      <Route path='demo' element={<Demo/>}/>

      </Route>
      {/* <Route path='' element={}/> */}

    </Routes>
    
    </BrowserRouter>
    </SidebarMenuProvider>
    </MyMobileContextProvider>
    </HtmlTextProvider>
    </>
  )
}

export default App
