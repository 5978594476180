import React, { useContext, useEffect, useState } from 'react'
import signup from '../images/signup.png'
import AuthNavbar from './AuthNavbar'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MyMobileContext } from './Context/MyMobileContext';

function ForgotPassword() {
    const {token, setToken} = useContext(MyMobileContext)
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const resetPassword = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        if (!email) {
            alert("Please enter email")
            setLoading(false)
        } else {
            axios.post("http://radioik.com/appadmin/api/forget-password", { email })
                .then((responce) => {
                    console.log(responce.data);

                    if (responce.data.status == 1) {
                        setLoading(false)
                        setMessage(responce.data.message);
                        setToken(responce.data.result[0].token)
                        navigate("/reset")

                    } else {
                        setLoading(false)
                        setMessage(responce.data.result[0].Message)
                        setTimeout(Mytime, 2000)

                    }

                })
                .catch((error)=>{
                    setError('Error: ' + error)
                    alert(error);
                })
        }
    }

    const Mytime = () => {
        setMessage('')
    }


    

    
    return (
        <>
            <AuthNavbar />
            <div className='Auth-main'>
                <div className='inner-auth-main'>
                    <div className='passrec-div1'>
                        <h1 className='passrec-div1-heading'>Hello...</h1>
                        <p className='passrec-div1-para1'>Launch your very own Internet Radio Station App</p>
                        <p className='passrec-div1-para2'>Radioik</p>
                    </div>
                    <div className='passrec-div2'><img style={{ width: "100%", height: "100%" }} src={signup} alt="password recovery image" /></div>
                    <div className='passrec-div3'>
                        <h2 className='passrec-div3-heading'>Password Recovery</h2>
                        <p className='passrec-div3-para' htmlFor="email">Enter your email to recover your password </p>
                        <input className='passrec-div3-input' placeholder='example@example.com' value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" />

                        {message && <div style={{ marginTop: "15px", color: "red" }}>{message}</div>}
                        {!loading && <button onClick={resetPassword} className='passrec-div3-btn'>Recover password</button>}
                        {loading && <div className='loader'></div>}



                    </div>
                </div>

            </div>
        </>
    )
}

export default ForgotPassword