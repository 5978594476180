import React,{useContext, useState,useEffect,useRef} from 'react'
import { FileUploader } from "react-drag-drop-files";
import { MyMobileContext } from './Context/MyMobileContext';
import MyRadioDesignPhoneRoundedRectangle from '../images/MyRadioDesignRoundedRectangle.png';
// import MyRadioDesignPhoneRectangle from '../images/MyRadioDesignRectangle.png';
import MyRadioDesignPhoneRoundedCircle from '../images/MyRadioDesignCircle.png';
import MyRadioDesignPhoneRectangle from '../images/MyRadioDesignPhoneRectangle .png';
import { FaBan } from "react-icons/fa";

function MyRadioDesign() {

    const {previews,setPreviews,setMobileValue,displayForSinglePhoneScreen,designChange,setDesignChange,MyBorderRef,MyLikeRef,
        myRadioActive,setMyRadioActive,myRadioBorderOpacity,setMyRadioBorderOpacity,myRadioBorderColor,setMyRadioBorderColor,myRadioselectedColorId, setMyRadioSelectedColorId,myRadioArtistColor, setMyRadioArtistColor,myRadioSelectedBGColorId, setMyRadioSelectedBGColorId,myRadioSelectedColorTextId, setMyRadioSelectedColorTextId,myRadioBackgroundOpacity, setMyRadioBackgroundOpacity,myRadioBackgroundColor,setMyRadioBackgroundColor,myRadioTextColor,setMyRadiotextColor,setMyBorderRadious,myBorderRadious
    } = useContext(MyMobileContext)



    // const MyRadioDesignRef = useRef(null);
    const textColors = [
        // {id:"rt547ru785rt3455",classIs: "text-colors-white", color: "#" },
        {id:"rt547ru785rt3467",classIs: "text-colors-yellow", color: "#ff9150" },
        {id:"rt547ru785rt3465",classIs: "text-colors-green", color: "#ff4757" },
        {id:"rt547ru785rt3468",classIs: "text-colors-lightgreen", color: "#2ed573" },
        {id:"rt547ru785rt3469",classIs: "text-colors-skyblue", color: "#1e90ff" },
        {id:"rt547ru785rt3488",classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        {id:"rt547ru785rt3499", classIs: "text-colors-pink", color: "#CB4DCE" },
        {id:"rt547ru785rt3410", classIs: "text-colors-black", color: "#000000" },
    ]
    const bgColors = [
        {id:"gsdyu567yfedw56236fy2341",classIs: "text-colors-yellow", color: "#ff9150" },
        {id:"gsdyu567yfedw56236fy346",classIs: "text-colors-green", color: "#ff4757" },
        {id:"gsdyu567yfedw56236fy34tt4",classIs: "text-colors-lightgreen", color: "#2ed573" },
        {id:"gsdyu567yfedw56236fy31tr",classIs: "text-colors-skyblue", color: "#1e90ff" },
        {id:"gsdyu567yfedw56236fyrt1t67",classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        {id:"gsdyu567yfedw56236fykop", classIs: "text-colors-pink", color: "#CB4DCE" },
        {id:"gsdyu567yfedw56236fyi76", classIs: "text-colors-black", color: "#000000" },
        {id:"gsdyu567yfedw56236fyipo",classIs: "text-colors-white", color: "none" },
    ]
    const myTextColorss = [
        {id:"g65er7667gdycft5df1",classIs: "text-colors-yellow", color: "#ff9150" },
        {id:"65er76267gdycft5df2",classIs: "text-colors-green", color: "#ff4757" },
        {id:"gs65er767gdycft5df3",classIs: "text-colors-lightgreen", color: "#2ed573" },
        {id:"g65er7667gdycft5df4",classIs: "text-colors-skyblue", color: "#1e90ff" },
        {id:"gsd65er67gdycft5df5",classIs: "text-colors-lightblue", color: "#ffc0cb" },
        // { classIs: "text-colors-blue", color: "#504DCE" },
        {id:"65er76267gdycft5df6", classIs: "text-colors-pink", color: "#CB4DCE" },
        {id:"65er76267gdycft5df7", classIs: "text-colors-black", color: "#000000" },
        // {id:"65er76267gdycft5df8",classIs: "text-colors-white", color: "none" },
    ]

    const hexToRgba = (hex, opacity) => {
         const expandedHex = hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex; const bigint = parseInt(expandedHex.slice(1), 16); 
         const r = (bigint >> 16) & 255; 
         const g = (bigint >> 8) & 255; 
         const b = bigint & 255; 
         
         return `rgba(${r},${g},${b},${opacity / 100})`; 
        };

        const yourHex = (hex, opacity) => { 
             const expandedHex = hex.length === 4 ? `#${hex[1]}${hex[1]}${hex[2]}${hex[2]}${hex[3]}${hex[3]}` : hex; 
             const hasAlpha = expandedHex.length === 9; 
              const bigint = parseInt(expandedHex.slice(1), 16); 
              const r = hasAlpha ? (bigint >> 24) & 255 : (bigint >> 16) & 255; 
              const g = hasAlpha ? (bigint >> 16) & 255 : (bigint >> 8) & 255; 
              const b = hasAlpha ? (bigint >> 8) & 255 : bigint & 255; 
              const a = hasAlpha ? (bigint & 255) / 255 : 1; 
               const finalAlpha = Math.round((opacity / 100) * 255); 
                const toHex = (value) => { 
                    const hex = value.toString(16); 
                    return hex.length === 1 ? '0' + hex : hex; }; 
                    return `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(finalAlpha)}`.toUpperCase(); 
                };


    const [urls, setUrls] = useState();
      const fileTypes = ["JPG", "PNG", "GIF"];
  
      const [file, setFile] = useState(null);
      const handleChange = (file) => {
          setUrls(file);
          console.log(urls);
        const reader = new FileReader(); 
        reader.onloadend = () => { 
            setPreviews(reader.result);
         }; if (file) { reader.readAsDataURL(file); }
  
      };

      const myRadioDesignBorder =(id,color) =>{
        
        setMyRadioSelectedColorId(id);
        const rgbaColor = yourHex(color, myRadioBorderOpacity); 
        console.log(rgbaColor);
        
        setMyRadioBorderColor(rgbaColor);
          }

      const changeTextColor =(id,color) =>{
        setMyRadiotextColor(color);
        setMyRadioSelectedColorTextId(id);
          }

          const myRadioDesignBackgroundColor = (id, color) => {
            setMyRadioSelectedBGColorId(id);
        
            if (id === "gsdyu567yfedw56236fyipo") {
                const rgbaColor = yourHex("#000000", 0);
                setMyRadioBackgroundColor(rgbaColor);
            } else {
                const rgbaColor = yourHex(color, myRadioBackgroundOpacity);
                setMyRadioBackgroundColor(rgbaColor);
            }
        };



     useEffect(()=>{
        displayForSinglePhoneScreen.map((item)=>(item.id==="wf765fx76ui897"?setMobileValue(item.text):""))
      });
    


    //   useEffect(() => { 
    //     if (MyLikeRef.current) 
    //         { const rgbaColor = hexToRgba(myRadioBackgroundColor, myRadioBackgroundOpacity); 
    //             MyLikeRef.current.style.backgroundColor = rgbaColor; 
    //         } 
    //     }, [myRadioBackgroundOpacity, myRadioBackgroundColor]);

    //   useEffect(() => { 
    //     if (MyLikeRef.current) 
    //         { const rgbaColor = hexToRgba(myRadioTextColor,"100"); 
    //             MyLikeRef.current.style.color = rgbaColor; 
    //         } 
    //     }, [myRadioTextColor]);


      const handleClick = (e) =>{
            let id = e.target.id;
            let values = true;
            setDesignChange(prev => ({...prev , [id]: values }))
            console.log(designChange);
            
      }

  return (
    <>
    <div>

    <div className='my-radio-design-default'>
                        <p>Default Cover</p>
                        <div className='my-radio-design-default-cover'>
                            <p>The default cover is the image that replaces the current track's photo if it does not have one.</p>
                        </div>
                    </div>
                    <div>
                    <div className='my-radio-design-default-cover-drag-and-drop'>
                    {!previews && <FileUploader handleChange={handleChange} name="file" types={fileTypes} />}
                    {previews && <div style={{width:"240px",height:"240px"}} className='my-radio-stream-logo-image-visible'><img className='menu-design-menu-image' src={previews} alt={urls}  style={{width:"inherit",height:"inherit"}}/> <button className='btn-mnu-chg' onClick={()=> setPreviews(null)
                        }>Change image</button></div>  }
                            <div className='my-radio-design-default-drag-text'>
                                <p>Recomended size <br /> Max Size</p>
                                <p>552 X 240px <br /> 1Cmo</p>
                            </div>
                            
                    </div>
                    <div className='My-radio-design-buttons-part2'><label htmlFor="display-default-cover">Always display the default cover</label>
                                <label className="switch">
                                    <input type="checkbox" name='display-default-cover' id='display-default-cover' />
                                    <span className="slider round"></span>
                                </label></div>
                    </div>
                    <hr style={{margin:"40px 10px"}}/>
                    <div className='My-radio-design-styles'>
                      <h5>Cover style</h5>
                    <div className='my-radio-design-styles23-border'>
                                        <p>Cover shape</p>
                                        <button id="MyRadioDesignRounded"  onClick={()=>{setMyBorderRadious("0px");
                                        {/*setMyBorderRadious in a context and myradiodesign and myradiophonescreen */}
                                        setMyRadioActive("isActiveRoundedRectangle") }} className={myRadioActive==="isActiveRoundedRectangle"?"isActive":"notActive"}><img src={MyRadioDesignPhoneRectangle} alt='Rectangle'/></button>

                                        <button id="MyRadioDesignRectangle"  onClick={(e)=>{setMyBorderRadious("50px");setMyRadioActive("isActiveRectangle") }}className={myRadioActive==="isActiveRectangle"?"isActive":"notActive"}><img src={MyRadioDesignPhoneRoundedRectangle} alt='RoundedRectangle'/></button>

                                        <button id="MyRadioDesignCircle" onClick={(e)=>{setMyBorderRadious("50%");setMyRadioActive("isActiveCircle")  }}className={myRadioActive==="isActiveCircle"?"isActive":"notActive"}><img src={MyRadioDesignPhoneRoundedCircle} alt='circle'/></button>
                                        
                                    </div>
                                    <div className='My-radio-design-buttons-part2'><label htmlFor="show-cover-border" >Show cover border</label>
                                <label className="switch">
                                    <input type="checkbox" name='showCoverBorder' id='showCoverBorder' checked={designChange.showCoverBorder} onChange={(e)=>{ let id = e.target.id; let isChecked = e.target.checked; if(designChange.showCoverBorder==false){MyBorderRef.current.style.border=`5px solid ${myRadioBorderColor}`} else if(designChange.showCoverBorder==true){MyBorderRef.current.style.border="none"}
                                     setDesignChange((prev)=> ({...prev, [id]: isChecked})) 
                                    }} />
                                    <span className="slider round"></span>
                                </label></div>

                                <div className='my-radio-design-cover-bdr-color'>
                                  <p>Cover border color</p>
                                  <div>
                                        <div className='bg-opacity25'>
                                            <label htmlFor="opacitys">Opacity</label>
                                            <input type="number" id="My-Radio-Border-color-opacitys" value={myRadioBorderOpacity} name="My-Radio-Border-color-opacitys" min="10" max="100" onChange={(e)=> {setMyRadioBorderOpacity(e.target.value);myRadioDesignBorder("rt547ru785rt3454",myRadioBorderColor)}}/>

                                            <label htmlFor="Hexa-code">Hex</label>
                                            <input type="text" maxlength="7" onKeyDown={(e)=> {const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace';if(!validKeys){e.preventDefault();} } } value={myRadioBorderColor} onChange={(e)=> {setMyRadioBorderColor(`#${e.target.value.replace('#', '')}`);setMyRadioSelectedColorId("rt547ru785rt3454");}} name="My-Radio-Border-Hexa-code" id="My-Radio-Border-Hexa-code" />

                                        </div>
                                        <div className='text-hexa-colors'>

                                        <input className={"rt547ru785rt3454" === myRadioselectedColorId? "border-for-active" :"text-colors-white"} type='color' style={{backgroundColor:"#fff",margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onChange={(e)=>{myRadioDesignBorder("rt547ru785rt3454",(e.target.value));setMyRadioSelectedColorId("rt547ru785rt3454");}}/> 

                                            {textColors.map((item) => (<div key={item.id} className={item.id === myRadioselectedColorId? "border-for-active" :item.classIs} style={{ background: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onClick={()=>{myRadioDesignBorder(item.id,item.color)}}></div>))}
                                        </div>
                                    </div>
                                </div>
                    </div>
                    <hr style={{margin:"40px 10px"}}/>
                    <div className='my-radio-design-Title-and-artist'>
                      <h5>Title and artist</h5>
                      <p>Background</p>
                      <div>
                                        <div className='bg-opacity25'>
                                            <label htmlFor="opacitys" >Opacity</label>
                                            <input type="number" id="opacitys" name="opacitys" min="10" max="100" value={myRadioBackgroundOpacity} onChange={(e)=> {setMyRadioBackgroundOpacity(e.target.value);myRadioDesignBackgroundColor("uiw8963gti738tf3i7i",myRadioBackgroundColor)}}/>
                                            <label htmlFor="Hexa-code">Hex</label>
                                            <input type="text" name="Hexa-code" id="Hexa-code" maxlength="7" onKeyDown={(e)=> {const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace';if(!validKeys){e.preventDefault();} } } value={myRadioBackgroundColor} onChange={(e)=>{setMyRadioBackgroundColor(`#${e.target.value.replace('#', '')}`);setMyRadioSelectedBGColorId("uiw8963gti738tf3i7i")}}/>
                                        </div>
                                        <div className='text-hexa-colors'>
                                        <input className={"uiw8963gti738tf3i7i" === myRadioSelectedBGColorId? "border-for-active" :"text-colors-white"} type='color' style={{backgroundColor:"#fff",margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onChange={(e)=>{myRadioDesignBackgroundColor("uiw8963gti738tf3i7i",(e.target.value));setMyRadioSelectedBGColorId("uiw8963gti738tf3i7i");}}/> 

                                            {bgColors.map((item) => (<div key={item.id} className={item.id === myRadioSelectedBGColorId? "border-for-active" :item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px",display:"flex",justifyContent:"center",alignItems:"center" }} onClick={()=>{myRadioDesignBackgroundColor(item.id,item.color)}}>{item.id==="gsdyu567yfedw56236fyipo"?<FaBan/> :""}</div>))}
                                        </div>
                                    </div>

                                    <div className='text-clr'>
                                <p>Title Color</p>
                                <div className='text-colors'>
                                    <div className='text-hexa-colors'>
                                    <input className={"fytgreyu7356777" === myRadioSelectedColorTextId? "border-for-active" :"text-colors-white"} type='color' style={{backgroundColor:"#fff",margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}  onChange={(e)=>{changeTextColor("fytgreyu7356777",(e.target.value));setMyRadioSelectedColorTextId("fytgreyu7356777");}}/> 

                                        {myTextColorss.map((item) => (<div key={item.id} className={item.id === myRadioSelectedColorTextId? "border-for-active" :item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px"  }} onClick={()=>{changeTextColor(item.id,item.color)}} ></div>))}
                                    </div>
                                    <div className='Hexa-text'>
                                        <label htmlFor="Hexa-text-here">Hex</label>
                                        <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e)=> {const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace';if(!validKeys){e.preventDefault();} } } value={myRadioTextColor} onChange={(e)=>{setMyRadiotextColor(e.target.value);setMyRadioSelectedColorId("fytgreyu7356777")}} />
                                    </div>
                                </div>
                            </div>
                                    <div className='text-clr'>
                                <p>Artist Color</p>
                                <div className='text-colors'>
                                    <div className='text-hexa-colors'>
                                        {textColors.map((item) => (<div key={item.color} className={item.classIs} style={{ backgroundColor: item.color, margin: "5px", width: "30px", height: "30px", borderRadius: "5px" }}></div>))}
                                    </div>
                                    <div className='Hexa-text'>
                                        <label htmlFor="Hexa-text-here">Hex</label>
                                        <input type="text" name="Hexa-text-here" id="Hexa-text-here" maxlength="7" onKeyDown={(e)=> {const validKeys = (e.key >= '0' && e.key <= '9') || (e.key >= 'a' && e.key <= 'f') || (e.key >= 'A' && e.key <= 'F') || e.key === 'Backspace';if(!validKeys){e.preventDefault();} } } value={myRadioArtistColor} onChange={(e)=>setMyRadioArtistColor(e.target.value)} />
                                    </div>
                                </div>
                            </div>

                    </div>

    </div>
    </>
  )
}

export default MyRadioDesign