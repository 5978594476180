import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='footer-main'>
    <div className='footer-main1'>
        <div>
            <h1 className='footer-main-heading'>Radioik</h1>
            <Link to="/signup"><button className='footer-main-button'>Try for free</button></Link>
        </div>
        <div>
            <h1>Products</h1>
            <p>Radio Mobile</p>
            <p>Application</p>
            <p>Radio Website</p>
            <p>Smart Speaker Apps</p>
            <p>Pricing</p>
        </div>
        <div>
            <h1>Resources</h1>
            <p>How to start
            your radio?</p>
            <p>Success Stories</p>
            <p>FAQ</p>
            <p>Help Center</p>
            <p>Our customer's radio</p>
        </div>
        <div>
            <h1>About</h1>
            <p>Who are we?</p>
            <p>Why choose us?</p>
            <p>Client reviews</p>
            <p>Contact</p>
            <p>Terms</p>
            <p>Protection of Personal Data</p>
        </div>
        <div>
            <h1>Follow us</h1>
            <p>Youtube</p>
            <p>Facebook</p>
            <p>Instagram</p>
            <p>Twitter</p>
            <p>LinkedIn</p>
        </div>
        </div>
        <p className='footer-end'>&copy2024 RadioIK. All rights reserved.<a href="#">Legal Notice</a></p>
    
    </div>
  )
}

export default Footer