import React, { useEffect, useState } from 'react';
import signup from '../images/signup.png';
import AuthNavbar from './AuthNavbar';
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { Link,useNavigate} from 'react-router-dom';
import axios from 'axios';


function Login() {

    const [email,setEmail]= useState(null);
    const [password,setPassword]= useState(null);
    const [eye,setEye] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
  

        let navigate = useNavigate();

      const handleForm = (e) =>{
        console.log("data");
        e.preventDefault();
        setError(null); 
        setLoading(true);

        if(email && password){
            axios.post('http://radioik.com/appadmin/api/login', { email, password })
              .then(response => {
                console.log(response.data);
                if (response.data.status == 1) {
                    setLoading(false)
                  setMessage('Login successful!');
                  navigate("/chooseappname")
                } else {
                    setLoading(false)
                    // setError(null)
                  setMessage("Invalid email or password. Please check and try again.");
                  setTimeout(Mytime,2000)
                }
              })
              .catch(error => {
                setError('Error: ' + error.message);
              });
            }else{
                    alert("Please enter email and password")
                    setLoading(false);
                 }
                 
      }

      // if (loading) return <div className='loader'></div>;
      if (error) return <div>Error: {error}</div>;

        const Mytime =()=>{
          setMessage('')
        }
          

  return (
     <>
     <AuthNavbar/>
     <div className='Auth-main'>
    <div className='inner-auth-main'>
    <div className='passrec-div1'>
            <h1 className='passrec-div1-heading'>Hello...</h1>
            <p className='passrec-div1-para1'>Launch your very own Internet Radio Station App</p>
            <p className='passrec-div1-para2'>Radioik</p>
        </div>
        <div className='signup-div2'><img style={{width:"100%",height:"100%"}}src={signup} alt="singing image" /></div>
        <div className='signup-div3'>
        <form onSubmit={handleForm}>
            <h2 className='signup-div3-heading'>Log in to your account</h2>
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" placeholder='example@example.com' onChange={(e)=>setEmail(e.target.value)}/>
    
            <label htmlFor="password">Password</label>
            <div style={{ position: "relative" }}>
            
                                            {eye?<ImEye onClick={()=> setEye(false)
                                            } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888',cursor:"pointer" }} />
                                            :<ImEyeBlocked onClick={()=> setEye(true)
                                            } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888',cursor:"pointer" }} />}
                                            <input style={{ marginBottom: "15px",paddingLeft: '40px' }} placeholder='8 characters minimum' value={password} onChange={(e) => setPassword(e.target.value)} className='passrec-div3-input' type={eye?"text":"password"} name="password" id="password" />
            
                                        </div>
            {/* <input type="password" name="password" id="password" onChange={(e)=>setPassword(e.target.value)} /> */}
    
            {!loading && <input className='login-button' type="submit" value="Login"/>}
            </form>  
            {loading && <div className='loader'></div>}
            {message &&  <div style={{marginTop:"15px",color:"red"}}>{message}</div> }
            <p className='signup-div3-para'><Link to="/forgotpassword">Forgot password</Link></p>
            <div className='login-with'>
            <div style={{float:"left",width:"30%"}}><hr/></div>
            <p className='login-with-text1'>&nbsp; or log in with &nbsp;</p>
            <div style={{float:"right",width:"30%"}}><hr/></div>
            </div>

                <div className='login-with-btns'>
                    <button>Facebook</button>
                    <button>Google</button>
                </div>

                <p className='login-with-text2'>Don’t have an account? <Link className='my-login-signup-txt' to="/signup" > Sign up.</Link></p>
        </div>
        
    </div>
    
        </div>
     </>
  )
}

export default Login