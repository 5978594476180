import React, { useContext, useEffect, useState } from 'react'
import signup from '../images/signup.png'
import AuthNavbar from './AuthNavbar'
import { use } from 'react'
import { ImEye } from "react-icons/im";
import { ImEyeBlocked } from "react-icons/im";
import { useNavigate } from 'react-router-dom'
import { MyMobileContext } from './Context/MyMobileContext';
import axios from 'axios';


function PasswordRecovery() {
    const navigate = useNavigate();

    const {token, setToken} = useContext(MyMobileContext)
    const [password, setPassword] = useState("");
    const [character, setCharacter] = useState(false);
    const [number, setNumber] = useState(false);
    const [eye,setEye] = useState(false);
        const [message, setMessage] = useState('');
        const [loading, setLoading] = useState(false);
        const [error, setError] = useState(null);
    

console.log(token)

    // if refresh then reenter your email 

    // other wise ask to your senior about it
    const passwordRecovery = (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);
        if (!password) {
            alert("please enter password");
            setLoading(false)
        } else if (!character) {
            alert("password should be at least 8 characters or longer")
            setLoading(false)
        }
        else if (!number) {
            alert("password should contains numbers")
            setLoading(false)
        }
        else if ({ character, number,token }) {

            axios.post("http://radioik.com/appadmin/api/reset-password",{token,password})
            .then((responce)=>{
                console.log(responce);
                
                if(responce.data.status == 1){
                    setLoading(false)
                    setMessage(responce.data.result[0].Message);
                    alert(responce.data.result[0].Message)
                    navigate("/orderlist")
                }else{
                    setLoading(false)
                    setMessage(responce.data.message)
                    setTimeout(Mytime, 2000)
                    alert("Failed please go back and reenter your email")
                }

            })
            .catch((error)=>{
                setError("Error : ", error)
                alert(error)
            })
        }
    }

    useEffect(() => {
        setCharacter(password.length > 7);
        setNumber(/\d/.test(password));
    }, [password])

    const Mytime = () => {
        setMessage('')
    }


    return (
        <>
            <AuthNavbar />
            <div className='Auth-main'>
                <div className='inner-auth-main'>
                    <div className='passrec-div1'>
                        <h1 className='passrec-div1-heading'>Hello...</h1>
                        <p className='passrec-div1-para1'>Launch your very own Internet Radio Station App</p>
                        <p className='passrec-div1-para2'>Radioik</p>
                    </div>
                    <div className='passrec-div2'><img style={{ width: "100%", height: "100%" }} src={signup} alt="password recovery image" /></div>
                    <div className='passrec-div3'>
                        <h2 className='passrec-div3-heading'>Reset your password</h2>
                        <p className='passrec-div3-para' htmlFor="email">Please enter your new password</p>
                        <div className='passrec-div4'>

                            <div style={{ position: "relative" }}>

                                {eye?<ImEye onClick={()=> setEye(false)
                                } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888',cursor:"pointer" }} />
                                :<ImEyeBlocked onClick={()=> setEye(true)
                                } style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)', color: '#888',cursor:"pointer" }} />}
                                <input style={{ marginBottom: "15px",paddingLeft: '40px' }} placeholder='8 characters minimum' value={password} onChange={(e) => setPassword(e.target.value)} className='passrec-div3-input' type={eye?"text":"password"} name="password" id="password" />

                            </div>

                            <div className='passrec-div4-checkbox'>
                                <div><input type="checkbox" readOnly checked={character} name="characters" id="characters" /><label htmlFor="characters">8 Characters</label></div>
                                <div><input type="checkbox" readOnly checked={number} name="numbers" id="numbers" /> <label htmlFor="numbers">contains Numbers</label></div>
                            </div>
                        </div>

                        {message && <div style={{ marginTop: "15px", color: "red" }}>{message}</div>}
                        {!loading && <button className='passrec-div3-btn' onClick={passwordRecovery}>Done</button>}
                        {loading && <div className='loader'></div>}

                    </div>
                </div>

            </div>
        </>
    )
}

export default PasswordRecovery