import React, { useState,useContext, useEffect } from 'react'
import seoMain from '../images/SEO-BG.png'
import SEO from '../images/SEO.png'
import Girl from '../images/Girl.png'
import { BiShareAlt } from "react-icons/bi";
import { FaShareAlt } from "react-icons/fa"; 
import Phone from '../images/Phone.png'
import { ImCross } from "react-icons/im";
import { MyMobileContext } from './Context/MyMobileContext'

function RSSPhoneScreen() {
    const [myId, setMyId] = useState(null)
        const [textSize, setTextSize] = useState(null)
        const [paraTextSize, setParaTextSize] = useState(null)
    const {value,buttonActive,textAlignSetting,bodySize,myRSSDesignColor,dark,BTNType,myGeneralBTNColor} = useContext(MyMobileContext)
    let [myRadious,setMyRadious] = useState(null)

       const [text,setText] = useState(null)
    useEffect(()=>{
        setText(textAlignSetting)
    },[textAlignSetting])
    useEffect(()=>{

if(buttonActive=="vector1"){
    setMyRadious("0px")

}else if(buttonActive=="vector2"){
    setMyRadious("5px"
)}
else if(buttonActive=="vector3"){
    
    setMyRadious("20px")

}else if(buttonActive=="vector4"){
    setMyRadious("50px")
}

    },[buttonActive])

     useEffect(()=>{
        if(value == 0){
            // MyBGColorChgRef.current.style.fontSize = "14px"
            setTextSize("20px")
            setParaTextSize("14px")
        } else if(value == 20){
            // MyBGColorChgRef.current.style.fontSize = "15.2206px"
            setTextSize("20.2206px")
            setParaTextSize("14.2206px")
        }else if(value == 40){
            // MyBGColorChgRef.current.style.fontSize = "18.2647px"
            setTextSize("21px")
            setParaTextSize("14px")
        }else if(value == 60){
            // MyBGColorChgRef.current.style.fontSize = "20.2941px"
            setTextSize("21.2941px")
            setParaTextSize("14.2941px")
        }else if(value == 80){
            // MyBGColorChgRef.current.style.fontSize = "22.3235px"
            setTextSize("21.3235px")
            setParaTextSize("14.3235px")
        }else if(value == 100){
            // MyBGColorChgRef.current.style.fontSize = "24.3529px"
            setTextSize("22.3529px")
            setParaTextSize("15.3529px")
        }
     },[value])
    
        const content = [
            {
                id: "f3t1",
                timing: "11 hrs ago...",
                heading: "Radio SEO- Know the 10 best ways to rank your radio app on Google.",
                imageUrl: `${seoMain}`,
                text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.`
            },
            {
                id: "tf323",
                timing: "11 hrs ago...",
                heading: "10 Tips to Improve your Radio App",
                imageUrl: `${Girl}`,
                text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.`
            },
            {
                id: "jihyh8i",
                timing: "11 hrs ago...",
                heading: "Internet Ranking in 10 Easy Steps",
                imageUrl: `${SEO}`,
                text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.`
            },
            {
                id: "uj8926",
                timing: "11 hrs ago...",
                heading: "Launch your  Website & Mobile App In a Week",
                imageUrl: `${Phone}`,
                text: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum accusamus molestiae adipisci in possimus similique explicabo hic eligendi. Voluptatibus, fugit? Eaque, ratione? Sapiente vitae dolorem maxime nulla a magnam ratione.`
            },
    
        ]
    
        let first = content.slice(0, 1);
        let rest = content.slice(1, 4);
    
        const handleClick = (id) => {
            console.log(id);
            const found = content.find((item) => item.id === id);
            console.log(found);
    
            if (found) {
                setMyId(id);
            }
    
        }
  return (
     <>
                <div className='My-radio-main-phone-div'>
                    <div style={{ overflow: "hidden", height: "530px" }}>
                        <div style={{ padding:bodySize=="with margin"?"10px 25px 10px 21px":"10px 32px 10px 10px", width:bodySize=="with margin"?"92%":"100%", height: "100%", overflowY: "scroll", boxSizing: "content-box" }}>
    
                            {first.map((item) => (
                                <div key={item.id} style={{borderRadius:`${myRadious}`,textAlign:`${text}`, backgroundColor: "#343236", color: myRSSDesignColor, padding: "25px", marginBottom: "8px",cursor:"pointer" }} onClick={() => handleClick(item.id)}>
                                    <p style={{ fontSize: "12px", lineHeight: "14px", fontWeight: "500" }}>{item.timing}</p>
                                    <h2 style={{ margin: "5px 0px", fontSize:`${textSize}`, lineHeight: "21px", fontWeight: "500",color:myRSSDesignColor }}>{item.heading} </h2>
                                    <div style={{ width: "100%", margin: "15px 0px 0px" }}><img src={item.imageUrl} alt="SEO" style={{borderRadius:`${myRadious}`, width: "inherit" }} /></div>
                                </div>
                            ))}
    
                            <div style={{ marginLeft: "25px" }}>
                                {rest.map((item) => (
                                    <div key={item.id} style={{borderRadius:`${myRadious}`,textAlign:`${text}`, backgroundColor: "#343236", color: myRSSDesignColor, padding: "5px", display: "flex", marginBottom: "8px",cursor:"pointer" }} onClick={() => handleClick(item.id)}>
                                        <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", left: "-20px" }}><img src={item.imageUrl} alt={item.imageUrl} style={{ width: "100%",borderRadius:`${myRadious}` }} /></div>
                                        <div style={{borderRadius:`${myRadious}`, width: "70%", margin: "15px 0px 0px" }}>
                                            <p style={{ fontSize: "12px", lineHeight: "14px", fontWeight: "500" }}>{item.timing}</p>
                                            <h2 style={{ margin: "5px 0px",  fontSize: `${textSize}`, lineHeight: "21px", fontWeight: "500",color:myRSSDesignColor }}>{item.heading}</h2>
                                        </div>
    
                                    </div>
                                ))}
                            </div>
                            
                                {myId && <>
                                    {content.map((item) => (
                                        item.id === myId ? <div key={item.id} style={{ position: "absolute",height: "515px",borderRadius:"10px", width: "93%", top: "66px", overflow: "hidden" }}>
                                            <div style={{textAlign:`${text}`, padding: "5px 5px 2px 10px", background: "#fff", color: "#343236", width: "100%", height: "100%", overflowY: "scroll", paddingRight: "5px", boxSizing: "content-box" }}>
                                            <div style={{padding:"16px 0px"}}>
                                                <div style={{width:"100%",height:"150px"}}><img src={item.imageUrl} alt={item.imageUrl} style={{width:"inherit",height:"inherit"}} /></div>
                                                <p style={{fontSize:"14px",lineHeight:"18px",fontWeight:"500",padding:"10px 0px"}}>{item.timing}</p>
                                                <h3 style={{fontSize:`${textSize}`,lineHeight:"21px",fontWeight:"500",padding:"10px 0px"}}>{item.heading}</h3>
                                                <p style={{fontSize:`${paraTextSize}`,lineHeight:"18px",fontWeight:"500",padding:"10px 0px"}}>{item.text}</p>
                                            </div>
                                            <ImCross style={{ position: "absolute", top: "1px", right: "1px", backgroundColor: "#343236", color: "#fff", padding: "10px", borderRadius: "20px" }} onClick={()=>setMyId(null)} />

                                           <div style={BTNType ? { position: "absolute", bottom: "5px", right: "8px", backgroundColor: `${myGeneralBTNColor}`, color: "#fff", padding: "10px", borderRadius: "100px" } : { position: "absolute", bottom: "5px", right: "8px", backgroundColor: `${myGeneralBTNColor.substring(0, 7)+"36"}`, color: `${myGeneralBTNColor}`, padding: "10px", borderRadius: "100px" }}>
                                                                                       {dark ? <BiShareAlt /> : <FaShareAlt />}
                                                                                   </div>
                                            
    
                                        </div>
                                        </div> : ""
                                    ))}
                                </>}
                            </div>
                        
    
    
                    </div>
    
                </div>
            </>
  )
}

export default RSSPhoneScreen