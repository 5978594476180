import React, { useContext } from 'react'
import AuthNavbar from './AuthNavbar'
import roses from '../images/Roses.png'
import rectangle from '../images/Rectangle.png'
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { MyMobileContext } from './Context/MyMobileContext';

function OrderList() {
    const {appIconPreviews,appName} = useContext(MyMobileContext)

    const navigate = useNavigate()
  return (
    <>
    <AuthNavbar/>
    <div className='Auth-main'>
        <div className='app-main1'>
            <div className='main-app-text'><p>Hello User !</p></div>
            <div className='order-main2'>
                <div>
                    <p className='order-sec-para1'>Radioik</p>
                    <p className='order-sec-para2'>You already have a demo app,</p>
                    <p className='order-sec-para3'>what would you like to do?</p>
                </div>
                <div className='order-sec2-main'>
                    <div className='order-sec1'>
                        <h2 className='order-sec-heading1'>{appName}</h2>
                        <div className='order-cards'  onClick={()=>navigate("/chooseappname")}>
                            <div className='order-card-upper' style={{width:"205px",height:"160px"}}><img src={appIconPreviews} alt="Roses image" style={{width:"inherit", height:"inherit",borderRadius:"10px"}} /></div>
                            <div className='order-card-div' style={{width: "365px"}}><p>Continue with this app</p></div>
                        </div>
                    </div>
                    <div className='order-sec2'>
                        <h2 className='order-sec-heading1'>Select New</h2>
                        <div className='order-cards' onClick={()=>{navigate("/chooseappname");window.location.reload();}}>
                            <div className='order-card-upper' style={{width:"205px",height:"160px"}}>
                                <img src={rectangle} alt="Rectangle" style={{width:"inherit", height:"inherit",borderRadius:"10px"}} />
                                <FaPlus className='order-card-plus-logo'/>
                            </div>
                            <div className='order-card-div' style={{width: "365px"}}><p>Start with a New App</p></div>
                        </div>
                    </div>
                </div>
              
            </div>
        </div>

    </div>
    </>
  )
}

export default OrderList