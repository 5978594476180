import React, { useState,useContext} from 'react';
import { SidebarMenuContext } from './Context/SidebarMenuContext';

function Demo() {
  const { myPagies, setMyPagies } = useContext(SidebarMenuContext)
  const handleDragStart = (e, index) => { e.dataTransfer.setData('dragIndex', index); console.log("drag start",index);
   }; 
  const handleDragOver = (e) => { e.preventDefault(); }; 
  const handleDrop = (e, dropIndex) => { 
    const dragIndex = e.dataTransfer.getData('dragIndex'); 
    const reorderedItems = [...myPagies]; 
    const [draggedItem] = reorderedItems.splice(dragIndex, 1); 
    reorderedItems.splice(dropIndex, 0, draggedItem); setMyPagies(reorderedItems); 
  }; 
  return (
  <div> 
    {myPagies.map((item, index) => (
      <div key={index} draggable onDragStart={(e) => handleDragStart(e, index)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, index)} style={{ padding: '8px', margin: '4px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', cursor: 'move', }} > 
      {item.text} 
      </div>))
      } 
      </div>
      
    );
}

export default Demo;