import React from 'react'
import { TbBellPlusFilled } from "react-icons/tb";
import { FaQuestion } from "react-icons/fa6";
import { NavLink } from 'react-router-dom';

function AuthNavbar() {
  return (
    <div className='auth-nav'>
<div><p className='auth-nav-main'><NavLink to="/">Radioik</NavLink></p></div>
<div className='auth-icons'>
<FaQuestion/>
<TbBellPlusFilled />
</div>
    </div>
  )
}

export default AuthNavbar