import React, { useState } from 'react'
import MyRadioMain from '../MyRadioMain'
import { Outlet } from 'react-router-dom'


function DedicationLayout() {



    return (
        <>
            <div style={{ width:"35%"}}>
                <div className='my-radio-content-starts-here1'>
                <div className='setting-45'>
                    <MyRadioMain RadioLink1={"/radio/dedication"} RadioLink2={"/radio/dedication/design"} />
                    <Outlet />
                </div>
                </div>
                
            </div>
        </>
    )
}

export default DedicationLayout